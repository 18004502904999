import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RootThemeProvider } from './RootThemeProvider';
import { CssBaseline } from '@mui/material';
import store from './store';
import Root from './root';
import './i18n';
import './index.scss';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import CustomSuccess from './modules/_core/components/custom-notification/success-notification.component';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	// <StrictMode>
	<HelmetProvider>
		<Provider store={store}>
			<RootThemeProvider>
				<CssBaseline />
				<SnackbarProvider
					Components={{
						success: CustomSuccess,
					}}
					maxSnack={3}
					autoHideDuration={3000}
					preventDuplicate
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transitionDuration={{ enter: 300, exit: 300 }}
				>
					<CssBaseline />
					<Root />
				</SnackbarProvider>
			</RootThemeProvider>
		</Provider>
	</HelmetProvider>
	// </StrictMode>
);
