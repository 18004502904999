import { SxProps, Theme } from '@mui/material';

export const chipDeleteIconStyles: SxProps<Theme> = [
	{
		'.MuiChip-deleteIcon': {
			fontSize: '12px',
			color: 'white',
			'&:hover': {
				color: 'white',
			},
		},
	},
];
