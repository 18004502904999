import { ChartColorsArray, BarChartDto } from '@families-link/shared';
import { Box, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Legend, Bar, CartesianGrid } from 'recharts';

interface HorizontalBarChartProps {
	chartData: BarChartDto[];
}

const renderCustomLegend = (props: any) => {
	const { payload } = props;

	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
			{payload.map((entry: any, index: number) => (
				<Box key={`item-${index}`} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					<Box
						sx={{
							width: 12,
							height: 12,
							borderRadius: '50%',
							backgroundColor: entry.color,
						}}
					/>
					<Typography sx={{ color: (theme) => theme.colors.grayLightBlue, fontSize: '14px', fontWeight: 400 }}>
						{entry.value}
					</Typography>
				</Box>
			))}
		</Box>
	);
};
export const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({ chartData }) => {
	const scrollRef = useRef<HTMLDivElement>(null);
	const xAxisRef = useRef<SVGElement>(null);

	if (!chartData) {
		return <p>No data available</p>;
	}

	const languageCounts = chartData
		.flatMap((dataItem) => dataItem.languages)
		.reduce<Record<string, number>>((acc, lang) => {
			acc[lang.language] = (acc[lang.language] || 0) + lang.count;
			return acc;
		}, {});

	const sortedLanguages = Object.entries(languageCounts)
		.sort((a, b) => b[1] - a[1])
		.map(([language]) => language);

	const visibleLanguages = sortedLanguages.slice(0, 5);
	const isOthersNeeded = sortedLanguages.length > 5;

	const formattedData = chartData.map((dataItem) => {
		const entry: Record<string, number | string> = {
			program: dataItem.program,
			TotalEngagements: dataItem.totalEngagements,
		};

		let othersCount = 0;

		dataItem.languages.forEach((lang) => {
			if (visibleLanguages.includes(lang.language)) {
				entry[lang.language] = lang.count;
			} else {
				othersCount += lang.count;
			}
		});

		if (isOthersNeeded) {
			entry['Others'] = othersCount;
		}

		return entry;
	});

	const languageKeys = isOthersNeeded ? [...visibleLanguages, 'Others'] : visibleLanguages;
	const legendData = languageKeys.map((language, index) => ({
		value: language,
		color: ChartColorsArray[index % ChartColorsArray.length],
	}));

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Typography sx={{ color: (theme) => theme.colors.grayLightBlue, fontSize: '16px', fontWeight: 500 }}>
				Event Attendance by Language
			</Typography>

			<Box
				sx={{
					position: 'relative',
					zIndex: 1,
					backgroundColor: 'white',
					px: 2,
					pt: 1,
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				{renderCustomLegend({ payload: legendData })}
			</Box>

			<Box
				sx={{
					height: '235px',
					width: '420px',
					overflowY: formattedData.length > 4 ? 'auto' : 'hidden',
					overflowX: 'hidden',
				}}
			>
				<BarChart
					width={420}
					height={Math.max(250, formattedData.length * 50)}
					layout="vertical"
					data={formattedData}
					barSize={30}
					barGap={10}
					margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
				>
					<XAxis type="number" dataKey="TotalEngagements" ref={xAxisRef} style={{ position: 'absolute', bottom: 0 }} />

					<YAxis
						dataKey="program"
						type="category"
						width={100}
						tick={({ x, y, payload }) => {
							const text = payload.value;
							const maxLength = 12;

							return (
								<text x={x - 10} y={y + 5} fontSize={14} fontWeight={400} textAnchor="end" fill="#797F92">
									{text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
								</text>
							);
						}}
					/>
					<Tooltip />
					{languageKeys.map((language, index) => (
						<Bar
							key={language}
							dataKey={language}
							stackId="a"
							fill={ChartColorsArray[index % ChartColorsArray.length]}
						/>
					))}
				</BarChart>
			</Box>
		</Box>
	);
};
