import React, { forwardRef } from 'react';

import { InputAdornment, OutlinedInputProps } from '@mui/material';
import { CoreInput, CoreInputProps } from '../../core-input';
import ClearIcon from '@mui/icons-material/Clear';
import { CoreIconButton } from '../../core-button';
import { CoreFormControl, CoreFormControlProps } from '../../core-form-control';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { CalendarIcon } from '../../../icons';

export interface CalendarInputProps extends Omit<OutlinedInputProps, 'label'>, CoreFormControlProps {
	onClear?: () => void;
	showFilterIcon?: boolean;
}

const CalendarInput = forwardRef<HTMLInputElement, CoreInputProps & CalendarInputProps>((props, ref) => {
	const {
		value,
		onClear,
		id,
		fullWidth,
		controlSx,
		label,
		helperText,
		error,
		disabled,
		requiredMark,
		showFilterIcon = true,
	} = props;

	return (
		<CoreFormControl
			disabled={disabled}
			error={error}
			helperText={helperText}
			label={label}
			id={id}
			requiredMark={requiredMark}
			fullWidth={fullWidth}
			controlSx={controlSx}
		>
			<CoreInput
				{...props}
				sx={{
					paddingRight: '16px',
					margin: 0,
					'& .MuiOutlinedInput-input': {
						minWidth: '175px',
						paddingRight: 0,
					},
					'& .MuiInputAdornment-root': {
						color: (theme) => theme.colors.gray,
					},
					'&.Mui-focused .MuiInputAdornment-root': {
						color: (theme) => theme.colors.lightBlue,
					},
					'&.Mui-disabled .MuiInputAdornment-root': {
						color: (theme) => theme.colors.blueTransparent,
					},
					'&.MuiOutlinedInput-root': {
						paddingRight: showFilterIcon ? '16px' : 0,
					},				
					...props.sx,
				}}
				inputRef={ref}
				placeholder="Choose Date Range"
				value={value || ''}
				endAdornment={
					<InputAdornment position="end" >
						<>
							{value && (
								<CoreIconButton
									onClick={onClear}
									sx={{
										p: `${props?.small ? 3 : 6}px`,
										marginRight: '4px',
										color: ({ colors }) => colors.gray,
									}}
								>
									<ClearIcon />
								</CoreIconButton>
							)}
							{showFilterIcon && (
								<CalendarIcon/>
							)}
						</>
					</InputAdornment>
				}
			/>
		</CoreFormControl>
	);
});

export default CalendarInput;
