import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { FamilyDto, PermissionDto } from '@families-link/shared';
import { FamiliesTableHandlers } from '../../components/families-table/families-table.component';
import { FamiliesTableActions } from '../../components/families-table/families-table-actions.component';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import { Avatar, Box, Typography } from '@mui/material';
import { tableCellTypographyOverflowStyles } from '../../../_core/styles';
import { mergeSx } from 'merge-sx';
const helper = createColumnHelper<FamilyDto & { createdAt: string; updatedAt: string }>();

export function useFamiliesTableColumns(
	handlers: FamiliesTableHandlers,
	permission?: PermissionDto
): ColumnDef<FamilyDto, string>[] {
	const { onDelete, onEdit, onView } = handlers;

	const [t] = useTranslation();

	const result = useMemo(
		() => [
			helper.accessor('fullName', {
				header: t('families.columns.full-name'),
				meta: {
					flex: 1,
					useCustomOverflowContainer: true,
				},
				cell: ({ getValue, row }) => {
					const imageUrl = row.original.imageUrl;
					const fullName = getValue();

					return (
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
							<Avatar src={imageUrl} alt={fullName} style={{ width: 24, height: 24, borderRadius: '6px' }} />
							<Typography data-overflow="true" sx={mergeSx({ fontWeight: 500 }, tableCellTypographyOverflowStyles)}>
								{fullName}
							</Typography>
						</Box>
					);
				},
			}) as ColumnDef<FamilyDto, string>,
			helper.accessor('email', {
				header: t('families.columns.email'),
				meta: {
					flex: 1,
				},
			}) as ColumnDef<FamilyDto, string>,
			helper.accessor('phoneNumber', {
				header: t('families.columns.phone-number'),
				meta: {
					flex: 1,
				},
				cell: ({ getValue, row }) => {
					const phoneNumber = getValue();

					return `${phoneNumber.slice(0, 2)} (${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(
						5,
						8
					)}-${phoneNumber.slice(8, 12)}`;
				},
			}) as ColumnDef<FamilyDto, string>,
			helper.accessor('location', {
				header: t('families.columns.location'),
				meta: {
					flex: 1,
				},
			}) as ColumnDef<FamilyDto, string>,
			helper.accessor('preferedLanguage', {
				header: t('families.columns.prefered-language'),
				meta: {
					flex: 1,
				},
				enableSorting: true,
				cell: (info) => {
					const languages = info.getValue();
					const options = languages.map(getObjectLanguage);

					return options.map((option) => (option?.name ? option.name : '')).join(', ');
				},
			}) as ColumnDef<FamilyDto, string>,
			helper.accessor('totalEngagements', {
				header: 'Total Engagements',
				meta: {
					flex: 1,
				},
				enableSorting: true,
			}) as ColumnDef<FamilyDto>,
			...(permission?.read
				? [
						helper.accessor('district', {
							header: 'District',
							meta: {
								flex: 1,
							},
							cell: ({ getValue }) => getValue()?.name,
						}) as ColumnDef<FamilyDto, string>,
				  ]
				: []),

			helper.display({
				id: 'actions',
				cell: (props) => (
					<FamiliesTableActions family={props.row.original} onDelete={onDelete} onEdit={onEdit} onView={onView} />
				),
			}) as ColumnDef<FamilyDto>,
		],
		[t, onDelete, onEdit]
	);

	return result;
}
