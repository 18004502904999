import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { WorkStatusEnum, ServiceTypeStatusEnum, ProviderInfoDto } from '@families-link/shared';

import { Avatar, Typography, Box } from '@mui/material';
import { ProvidersDistrictTableHandlers } from '../components/provider-district-table/provider-district-table.component';
import { getObjectLanguage } from '../../_core/components/languages-component';
import { InHouse, Outsource, Verified } from '../../_core/constants/icons.constants';
import { ProvidersDistrictTableActions } from '../components/provider-district-table/provider-district-table-actions.component';
import { mergeSx } from 'merge-sx';
import { tableCellTypographyOverflowStyles } from '../../_core/styles';
import { InHouseIcon, OutsourceIcon } from '../../_core/components/icons';

const helper = createColumnHelper<ProviderInfoDto & { createdAt: string; updatedAt: string }>();

export function useProvidersDistrictTableColumns(
	handlers: ProvidersDistrictTableHandlers
): ColumnDef<ProviderInfoDto, string>[] {
	const { onView, onDelete, onEdit } = handlers;

	const [t] = useTranslation();

	const result = useMemo(
		() => [
			helper.accessor('fullName', {
				header: t('providers.full-name'),
				meta: {
					flex: 1.5,
				},
				enableSorting: true,
				cell: ({ getValue, row }) => {
					const imageUrl = row.original.imageUrl;
					const fullName = getValue();

					return (
						<Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: '6px' }}>
							<Avatar
								src={imageUrl}
								alt={fullName}
								sx={{ width: 24, height: 24, borderRadius: '6px' }}
							/>
							<Typography data-overflow="true" sx={mergeSx({ fontWeight: 500 }, tableCellTypographyOverflowStyles)}>
								{fullName}
							</Typography>
						</Box>
					);
				},
			}) as ColumnDef<ProviderInfoDto, string>,
			helper.accessor('targetLanguage', {
				header: t('providers.target-lang'),
				meta: {
					flex: 1,
				},
				enableSorting: true,
				cell: (info) => {
					const languages = info.getValue() as unknown as string[];
					const options = languages.map(getObjectLanguage);

					return options.map((option) => (option && option.name ? option.name : '')).join(', ');
				},
			}) as ColumnDef<ProviderInfoDto, string>,
			helper.accessor('verificationType', {
				header: t('providers.type'),
				meta: {
					flex: 0.8,
					useCustomOverflowContainer: true,
				},
				enableSorting: true,
				cell: (info) =>
					info.getValue() ? (
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
							<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
								{t('providers.verified')}{' '}
							</Typography>
							<Verified />
						</Box>
					) : (
						t('providers.no-verified')
					),
			}) as ColumnDef<ProviderInfoDto, string>,
			helper.accessor('workStatus', {
				header: t('providers.status'),
				meta: {
					flex: 0.8,
					useCustomOverflowContainer: true,
				},
				enableSorting: true,
				cell: (info) => {
					const value = info.getValue();
					switch (value) {
						case WorkStatusEnum.InHouse:
							return (
								<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
									<InHouseIcon sx={{fontSize: '14px'}} />
									<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
										{t('providers.work-in-house')}
									</Typography>
								</Box>
							);
						case WorkStatusEnum.Outsource:
							return (
								<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
									<OutsourceIcon  sx={{fontSize: '14px'}} />
									<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
										{t('providers.work-outsource')}
									</Typography>
								</Box>
							);
						default:
							return null;
					}
				},
			}) as ColumnDef<ProviderInfoDto, string>,
			helper.accessor('serviceType', {
				header: t('providers.service-type'),
				meta: {
					flex: 1.2,
					useCustomOverflowContainer: true,
				},
				enableSorting: true,
				cell: ({getValue}) => {
					switch (getValue()) {
						case ServiceTypeStatusEnum.Appointment:
							return (
								<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
									{t('providers.appointment')}
								</Typography>
							);
						case ServiceTypeStatusEnum.Project:
							return (
								<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
									{t('providers.project')}
								</Typography>
							);
						case ServiceTypeStatusEnum.Both:
							return (
								<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
									{t('providers.appointment')}, {t('providers.project')}
								</Typography>
							);
						default:
							return null;
					}
				},
			}) as ColumnDef<ProviderInfoDto, string>,
			helper.accessor('totalEngagements', {
				header: 'Total Engagements',
				meta: {
					flex: 1,
				},
				enableSorting: true,
			}) as ColumnDef<ProviderInfoDto>,
			helper.display({
				id: 'actions',
				cell: (props) => (
					<ProvidersDistrictTableActions
						provider={props.row.original}
						onView={onView}
						onDelete={onDelete}
						onEdit={onEdit}
					/>
				),
			}) as ColumnDef<ProviderInfoDto>,
		],
		[t, onView, onDelete, onEdit]
	);

	return result;
}
