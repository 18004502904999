import React, { FC, useCallback, useState } from 'react';
import { Controller, FieldError, FieldValues, ResolverOptions, useFieldArray, useForm } from 'react-hook-form';
import { Box, Button, Chip, IconButton, MenuItem, Typography } from '@mui/material';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { CreateFamilyDto, LanguageEnum, SendMethodArray, UpdateFamilyDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { errorTextStyles, containerFormStyles } from './modal-form.styles';
import { AutocompleteTextField, CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import UploadPhoto from '../../../_core/components/upload-photo/upload-photo.component';
import { PhoneInput } from '../../../_core/components/_ui/phone-input';
import { buttonModalWindowStyles, chipDeleteIconStyles, mainTitleModalWindowStyles } from '../../../_core/styles';
import { mergeSx } from 'merge-sx';
import { CoreSelect } from '../../../_core/components/_ui/core-select';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useGenerateFamilyPhotoUrlMutation } from '../../../../store/api/families.api';
import { ClearIcon } from '@mui/x-date-pickers';
import { CoreCheckbox } from '../../../_core/components/_ui/core-checkbox';
import { ChipCloseIcon } from '../../../_core/components/icons';

export type FamilyFormModalProps = CoreModalProps & {
	formTitle: string;
	defaultValues?: any;
	formError?: any;
	submitButtonName?: string;
	resolver<TFieldValues extends FieldValues, TContext>(
		values: TFieldValues,
		context: TContext | undefined,
		options: ResolverOptions<TFieldValues>
	): any;
	submitAction(values: CreateFamilyDto | UpdateFamilyDto): Promise<void>;
	closeModal(): void;
};

export const FamilyFormModal: FC<FamilyFormModalProps> = (props) => {
	const [t] = useTranslation();
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [isPhotoDeleted, setIsPhotoDeleted] = useState(false);
	const [generateFamilyPhotoUrl] = useGenerateFamilyPhotoUrlMutation();
	const { formTitle, resolver, submitAction, formError, closeModal, defaultValues, submitButtonName, ...modalProps } =
		props;

	const {
		register,
		handleSubmit,
		control,
		watch,
		trigger,
		getValues,
		formState: { errors, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = useForm({
		defaultValues,
		resolver,
		mode: 'all',
	});

	const emailInForm = watch('email');

	//useEffect(() => {
	//	trigger('preferedSendMethod');
	//}, [emailInForm]);

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const handleFormSubmit = useCallback(
		async (values: CreateFamilyDto | UpdateFamilyDto) => {
			try {
				const newValues: CreateFamilyDto | UpdateFamilyDto = { ...values };
				const formData = new FormData();

				if (selectedFile) {
					formData.append('file', selectedFile);
					try {
						const { url: imageUrl } = await generateFamilyPhotoUrl(formData).unwrap();

						if (imageUrl) {
							newValues.imageUrl = imageUrl;
						}
					} catch (error) {
						newValues.imageUrl = '';
						console.log(error);
					}
				}

				if (selectedFile === null && !!isPhotoDeleted) {
					newValues.imageUrl = '';
				}
				await submitAction(newValues);
			} catch (error) {
				console.error(error);
			}
		},
		[submitAction, selectedFile, isPhotoDeleted]
	);

	const handleFileChange = (file: File | null) => {
		if (file) {
			setSelectedFile(file);
		} else {
			setSelectedFile(null);
			setIsPhotoDeleted(true);
		}
	};

	const { fields, append, remove } = useFieldArray({
		control,
		name: `children`,
	});

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0, px: 5, pb: 5 }} paperSx={{ width: '100%' }}>
			<Typography variant="h5" sx={mergeSx(mainTitleModalWindowStyles, { mb: 5 })}>
				{formTitle}
			</Typography>
			<Box>
				<Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
					<Box sx={{ display: 'flex', flexDirection: 'column', mb: 5 }}>
						<Box sx={containerFormStyles}>
							<Box sx={{ maxHeight: 364 }}>
								<UploadPhoto onFileChange={handleFileChange} editable file={defaultValues.imageUrl ?? null} />
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
								<CoreTextField
									label={t('families.forms.labels.full-name')}
									requiredMark
									fullWidth
									placeholder={t('families.forms.placeholders.full-name') ?? ''}
									{...register('fullName')}
									error={!!errors['fullName']}
									helperText={(errors['fullName'] as FieldError)?.message}
								/>
								<CoreTextField
									label={t('families.forms.labels.email')}
									fullWidth
									placeholder={t('families.forms.placeholders.email') ?? ''}
									{...register('email')}
									error={!!errors['email']}
									helperText={(errors['email'] as FieldError)?.message}
								/>

								<PhoneInput
									fullWidth
									id="phone-number"
									requiredMark
									label={t('families.forms.labels.phone-number')}
									control={control}
									name="phoneNumber"
									error={!!errors['phoneNumber']}
									helperText={(errors['phoneNumber'] as FieldError)?.message}
								/>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', mt: 3, gap: 3 }}>
							<CoreTextField
								label={t('families.forms.labels.location')}
								requiredMark
								fullWidth
								placeholder={t('families.forms.placeholders.location') ?? ''}
								{...register('location')}
								error={!!errors['location']}
								helperText={(errors['location'] as FieldError)?.message}
							/>
							<Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, alignItems: 'center' }}>
								<Controller
									name={'preferedLanguage'}
									control={control}
									render={({ field }) => (
										<CoreAutoComplete
											{...field}
											label={t('families.forms.labels.prefered-language')}
											multiple={true}
											requiredMark
											fullWidth
											{...register('preferedLanguage')}
											error={!!errors['preferedLanguage']}
											helperText={
												errors['preferedLanguage'] ? (errors['preferedLanguage'] as FieldError).message : undefined
											}
											options={Object.values(LanguageEnum)}
											control={control}
											getOptionLabel={(option) => {
												const language = getObjectLanguage(option);

												return language ? language.name : '';
											}}
											selectOnFocus={true}
											isOptionEqualToValue={(option, value) => option === value}
											renderInput={(params) => (
												<AutocompleteTextField
													{...params}
													error={!!errors['preferedLanguage']}
													placeholder={
														getValues('preferedLanguage')?.length
															? ''
															: t('families.forms.placeholders.prefered-language')
													}
													sx={{
														'& .MuiOutlinedInput-root': {
															'&.Mui-focused fieldset': {
																borderColor: (theme) => theme.colors.blue,
															},
														},
													}}
												/>
											)}
											renderOption={(props, option, { selected }) => {
												const language = getObjectLanguage(option);

												return (
													<MenuItem {...props} key={language?.value} value={language?.value}>
														<CoreCheckbox checked={selected} sx={{ mr: 2, p: 0, ml: 0 }} />
														{language?.name}
													</MenuItem>
												);
											}}
											renderTags={(value, getTagProps) => {
												const count = value?.length - 1;

												return [
													value[0] && (
														<Chip
															sx={chipDeleteIconStyles}
															{...getTagProps({ index: 0 })}
															size="small"
															label={value[0]}
															deleteIcon={<ChipCloseIcon />}
														/>
													),
													count > 0 && (
														<Chip
															sx={chipDeleteIconStyles}
															{...getTagProps({ index: count })}
															size="small"
															label={`+${count}`}
															deleteIcon={<ChipCloseIcon />}
														/>
													),
												];
											}}
											clearIcon={
												<ClearIcon sx={{ fontSize: '16px', '& .MuiAutocomplete-clearIndicator': { padding: '2px' } }} />
											}
										/>
									)}
								/>
								<Controller
									name={'preferedSendMethod'}
									control={control}
									render={({ field }) => (
										<CoreSelect
											{...field}
											label={t('families.forms.labels.preferred-send-method')}
											requiredMark
											fullWidth
											placeholder={'Select preferred send method'}
											error={!!errors['preferedSendMethod']}
											helperText={(errors['preferedSendMethod'] as FieldError)?.message}
										>
											{SendMethodArray?.map((el: any) => (
												<MenuItem key={el} value={el}>
													{el}
												</MenuItem>
											))}
										</CoreSelect>
									)}
								/>
							</Box>
							{fields.map((field, index) => (
								<Box key={field.id} sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 3 }}>
									<Box sx={{ width: '50%' }}>
										<Controller
											name={`children.${index}.studentId`}
											control={control}
											render={({ field }) => (
												<CoreTextField
													{...field}
													requiredMark
													label={t('families.forms.labels.student-id')}
													fullWidth
													placeholder={t('families.forms.placeholders.student-id')}
													error={!!(errors['children'] as any)?.[index]?.studentId}
													helperText={(errors['children'] as any)?.[index]?.studentId?.message}
												/>
											)}
										/>
									</Box>
									<Box sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
										<Controller
											name={`children.${index}.name`}
											control={control}
											render={({ field }) => (
												<CoreTextField
													{...field}
													requiredMark
													label={t('families.forms.labels.student-name')}
													fullWidth
													placeholder={t('families.forms.placeholders.student-name')}
													error={!!(errors['children'] as any)?.[index]?.name}
													helperText={(errors['children'] as any)?.[index]?.name?.message}
												/>
											)}
										/>
										{index !== 0 && (
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<IconButton
													edge="end"
													onClick={() => {
														remove(index);
													}}
												>
													<CloseIcon />
												</IconButton>
											</Box>
										)}
									</Box>
								</Box>
							))}
						</Box>
						<Button
							onClick={() => {
								append({ name: '', studentId: '' });
							}}
							variant="text"
							sx={{ justifyContent: 'flex-start', textAlign: 'start', width: '100%', alignItems: 'center', mt: 0 }}
						>
							<AddIcon />
							{t('families.forms.buttons.add-child')}
						</Button>
					</Box>
					{formError && (
						<Typography sx={errorTextStyles}>
							{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
						</Typography>
					)}
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-end',
							alignItems: 'center',
							gap: 3,
						}}
					>
						<CoreButton onClick={closeModal} variant="secondary" sx={buttonModalWindowStyles}>
							{t('families.forms.buttons.close-form')}
						</CoreButton>
						<CoreButton
							type="submit"
							contraModeStyleButton
							disabled={disableSubmit}
							loading={loadingSubmit}
							sx={buttonModalWindowStyles}
						>
							{submitButtonName ?? t('families.forms.buttons.save')}
						</CoreButton>
					</Box>
				</Box>
			</Box>
		</CoreModal>
	);
};
