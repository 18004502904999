import React, { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { DistrictDto, SurveyTemplateDto, UpdateSurveyTemplateDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { containerButtonStyles, errorTextStyles, leftButtonStyles, modalBoxStyles } from './modal-form.styles';
import { buttonModalWindowStyles, mainTitleModalWindowStyles } from '../../../_core/styles';
import { mergeSx } from 'merge-sx';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

export type AttachDistrictsToSurveyTemplateFormModalProps = CoreModalProps & {
	defaultValues: SurveyTemplateDto;
	districtList?: Array<DistrictDto>;
	formError?: any;
	submitAction(values: UpdateSurveyTemplateDto): Promise<void>;
	closeModal(): void;
};

export const AttachDistrictsToSurveyTemplateFormModal: FC<AttachDistrictsToSurveyTemplateFormModalProps> = (props) => {
	const [t] = useTranslation();
	const { submitAction, closeModal, defaultValues, districtList, formError, ...modalProps } = props;

	const resolver = classValidatorResolver(UpdateSurveyTemplateDto);
	const {
		handleSubmit,
		getValues,
		control,
		formState: { isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = useForm({
		defaultValues,
		resolver,
		mode: 'all',
	});

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const handleFormSubmit = useCallback(
		async (values: UpdateSurveyTemplateDto) => {
			try {
				await submitAction(values);
			} catch (error) {
				console.error(error);
			}
		},
		[submitAction]
	);

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0 }} paperSx={{ width: '100%' }}>
			<Typography variant="h5" sx={mainTitleModalWindowStyles}>
				{t('survey-templates.forms.attach-districts')}
			</Typography>
			<Box sx={modalBoxStyles}>
				<Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
					<Controller
						name="districtIds"
						control={control}
						defaultValue={[]}
						render={({ field }) => (
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								{districtList?.map((district) => (
									<FormControlLabel
										key={district._id}
										control={
											<Checkbox
												{...field}
												value={district._id}
												checked={Array.isArray(field.value) && field.value.includes(district._id)}
												onChange={(e) => {
													if (Array.isArray(field.value)) {
														const newValue = e.target.checked
															? [...field.value, district._id]
															: field.value?.filter((val: string) => val !== district._id);

														field.onChange(newValue);
													} else {
														const newValue = e.target.checked ? [district._id] : [];
														field.onChange(newValue);
													}
												}}
											/>
										}
										label={district.name}
										sx={{ fontSize: '14px' }}
									/>
								))}
							</Box>
						)}
					/>
					{formError && (
						<Typography sx={errorTextStyles}>
							{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
						</Typography>
					)}
					<Box sx={containerButtonStyles}>
						<CoreButton
							onClick={closeModal}
							variant="secondary"
							contraModeStyleButton
							sx={mergeSx(buttonModalWindowStyles, leftButtonStyles)}
						>
							{t('survey-templates.forms.buttons.close-form')}
						</CoreButton>
						<CoreButton
							type="submit"
							contraModeStyleButton
							disabled={disableSubmit}
							loading={loadingSubmit}
							sx={buttonModalWindowStyles}
						>
							{t('survey-templates.forms.buttons.save')}
						</CoreButton>
					</Box>
				</Box>
			</Box>
		</CoreModal>
	);
};
