import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { FamilyWithSurveyDto, SurveyStatusColorEnum } from '@families-link/shared';
import { FamiliesDetailsTableHandlers } from '../../components/family-details/families-details-table/families-details-table.component';
import { FamiliesDetailsTableActions } from '../../components/family-details/families-details-table/families-details-table-actions.component';
import { Avatar, Box, Typography } from '@mui/material';
import { mergeSx } from 'merge-sx';
import { tableCellTypographyOverflowStyles } from '../../../_core/styles';
const helper = createColumnHelper<FamilyWithSurveyDto & { createdAt: string; updatedAt: string }>();

export function useFamiliesDetailsTableColumns(
	handlers: FamiliesDetailsTableHandlers
): ColumnDef<FamilyWithSurveyDto, string>[] {
	const { onSendEmail, onSendSms, onEdit, onView } = handlers;

	const [t] = useTranslation();

	const result = useMemo(
		() => [
			helper.accessor('family', {
				header: t('families.columns.full-name'),
				meta: {
					flex: 1,
					useCustomOverflowContainer: true,
				},
				cell: ({ getValue, row }) => {
					const imageUrl = getValue().imageUrl;
					const fullName = getValue().fullName;

					return (
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
							<Avatar
								src={imageUrl}
								alt={fullName}
								style={{ width: 24, height: 24, borderRadius: '6px' }}
							/>
							<Typography data-overflow="true" sx={mergeSx({ fontWeight: 500 }, tableCellTypographyOverflowStyles)}>{fullName}</Typography>
						</Box>
					);
				},
			}) as ColumnDef<FamilyWithSurveyDto, string>,
			helper.accessor('surveyStatus', {
				header: t('engagements.columns.survey-status'),
				meta: {
					flex: 1,
					useCustomOverflowContainer: true,
				},
				cell: ({ getValue }) => (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
						<Box
							sx={{
								height: 10,
								width: 10,
								borderRadius: '50%',
								backgroundColor: SurveyStatusColorEnum[getValue()],
								flexShrink: 0,
							}}
						></Box>
						<Typography data-overflow="true" variant="body2" sx={tableCellTypographyOverflowStyles}>{getValue()}</Typography>
					</Box>
				),
			}) as ColumnDef<FamilyWithSurveyDto, string>,
			helper.display({
				id: 'actions',
				cell: (props) => (
					<FamiliesDetailsTableActions
						familyWithSurvey={props.row.original}
						onSendEmail={onSendEmail}
						onSendSms={onSendSms}
						onEdit={onEdit}
						onView={onView}
					/>
				),
			}) as ColumnDef<FamilyWithSurveyDto>,
		],
		[t, onSendEmail, onSendSms, onEdit, onView]
	);

	return result;
}
