import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMuiModal } from '../../_core/hooks';
import {
	AddProviderForm,
	AddProviderFormProps,
} from '../components/create-provider-form/create-provider-form.component';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { CreateProviderFrontDto, ProviderDto, ProviderInfoDto } from '@families-link/shared';
import { useCallback } from 'react';

export type useOpenModalFormHookProps = {
	isCreate: boolean;
	districtId?: string | null;
	defaultValues?: any;
	submitButtonName?: string;
	formTitle: string;
	toastText?: string;
	featchAction(values: ProviderInfoDto | ProviderDto): Promise<void>;
	handlePagination?(): void;
};

export function useOpenModalForm(props: useOpenModalFormHookProps) {
	const { districtId } = props;

	const initialDefaultValues = {
		fullName: '',
		targetLanguage: [],
		verificationType: '',
		workStatus: '',
		districtId,
		serviceType: '',
		email: '',
	};

	const {
		featchAction,
		handlePagination,
		isCreate = true,
		defaultValues = initialDefaultValues,
		submitButtonName,
		toastText,
	} = props;

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
		});
	};

	const { openModal, closeModal, updateCurrentModal } = useMuiModal<AddProviderFormProps>(AddProviderForm);

	const handleSubmit = async (values: ProviderInfoDto | ProviderDto): Promise<void> => {
		try {
			await featchAction(values);
			showToast(toastText || 'Successfully performed', 'success');

			if (handlePagination) {
				handlePagination();
			}

			closeModal();
		} catch (error) {
			updateCurrentModal({ formError: error });
		}
	};

	const resolver = classValidatorResolver(CreateProviderFrontDto);

	const handleOpenModal = useCallback(
		(provider?: ProviderInfoDto) => {
			openModal({
				formTitle: isCreate ? t('providers.create-provider') : t('providers.edit-provider'),
				resolver,
				defaultValues: provider ? provider : defaultValues,
				submitAction: handleSubmit,
				closeModal,
				submitButtonName,
			});
		},
		[openModal, handleSubmit]
	);

	return handleOpenModal;
}
