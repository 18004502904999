import { clearSessionStorageDistrictId } from '../../../store/sesionStorage.slice';
import { useAppDispatch } from '../../../store';
import { clearCurrentDistrict } from '../../district/store/district.slice';
import { clearCurrentFamily } from '../../family/store/family.slice';
import { clearCurrentProvider } from '../../providers/store/provider.slice';
import { removeLocalStorageDistrictId, removeLocalStorageFamilyId, removeLocalStorageProviderId } from '../utils';

export const useClearStore = () => {
	const dispatch = useAppDispatch();

	return () => {
		dispatch(clearCurrentDistrict());
		dispatch(clearSessionStorageDistrictId());
		removeLocalStorageDistrictId();

		dispatch(clearCurrentFamily());
		removeLocalStorageFamilyId();

		dispatch(clearCurrentProvider());
		removeLocalStorageProviderId();
	};
};
