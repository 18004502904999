import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { SurveyTemplateDto } from '@families-link/shared';
import { SurveyTemplatesTableHandlers } from '../../components/survey-templates-table/survey-templates-table.component';
import { SurveyTemplatesTableActions } from '../../components/survey-templates-table/survey-templates-table-actions.component';
const helper = createColumnHelper<SurveyTemplateDto>();

export function useSurveyTemplatesTableColumns(
	handlers: SurveyTemplatesTableHandlers
): ColumnDef<SurveyTemplateDto, string>[] {
	const { onEdit, onView, onDelete, onAttachDistrict } = handlers;

	const [t] = useTranslation();

	const result = useMemo(
		() => [
			helper.accessor('title', {
				header: t('survey-templates.columns.name'),
				meta: {
					flex: 1,
				},
			}) as ColumnDef<SurveyTemplateDto, string>,
			helper.accessor('language', {
				header: t('survey-templates.columns.default-language'),
				meta: {
					flex: 1,
				},
				sortDescFirst: false,
				cell: ({ getValue }) => (getValue() ? getValue() : '-'),
			}) as ColumnDef<SurveyTemplateDto, string>,
			helper.accessor('questionsCount', {
				header: t('survey-templates.columns.questions'),
				meta: {
					flex: 1,
				},
			}) as ColumnDef<SurveyTemplateDto, string>,
			helper.display({
				id: 'actions',
				cell: (props) => (
					<SurveyTemplatesTableActions
						surveyTemplate={props.row.original}
						adminTemplate={props.row.original.adminTemplate}
						onEdit={onEdit}
						onDelete={onDelete}
						onView={onView}
						onAttachDistrict={onAttachDistrict}
					/>
				),
			}) as ColumnDef<SurveyTemplateDto>,
		],
		[t, onEdit, onDelete, onView, onAttachDistrict]
	);

	return result;
}
