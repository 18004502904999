import { createSlice, Slice } from '@reduxjs/toolkit';
import { useAppSelector } from '.';
import { ProviderDto } from '@families-link/shared';
import { CURRENT_DISTRICT_ID_SESSION_STORAGE_KEY } from '../modules/_core/constants';

export type SessionStorageState = { districtId: string | null };

export const initialState = {
	districtId: sessionStorage.getItem(CURRENT_DISTRICT_ID_SESSION_STORAGE_KEY),
};

export const sessionStorageSlice = createSlice({
	name: 'sessionStorage',
	initialState,
	reducers: {
		setSessionStorageDistrictId: (state: SessionStorageState, action) => {
			state.districtId = action.payload;
			sessionStorage.setItem(CURRENT_DISTRICT_ID_SESSION_STORAGE_KEY, action.payload);
		},
		clearSessionStorageDistrictId: (state: SessionStorageState) => {
			state.districtId = null;
			sessionStorage.removeItem(CURRENT_DISTRICT_ID_SESSION_STORAGE_KEY);
		},
	},
});

export const { setSessionStorageDistrictId, clearSessionStorageDistrictId } = sessionStorageSlice.actions;

export const useCurrentSessionStorageDistrictIdSelector = () =>
	useAppSelector((state) => state.sessionStorage.districtId);
