import { Box, Chip, InputAdornment, MenuItem, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDistrictFilter, useDurationFilter } from '../../_core/hooks';
import { Controller } from 'react-hook-form';
import CoreCalendar from '../../_core/components/_ui/core-date-picker/core-calendar/core-calendar.component';
import { AutocompleteTextField, CoreAutoComplete } from '../../_core/components/_ui/core-autocomplete';
import { CustomFieldDto } from '@families-link/shared';
import { useSchoolFilter } from '../../_core/hooks/use-school-filter';
import { CoreCheckbox } from '../../_core/components/_ui/core-checkbox';
import { LanguagePieChart } from '../components/language-pie-chart/language-pie-chart.component';
import {
	useGetAttendanceLanguageQuery,
	useGetLanguageDistributionQuery,
	useGetProvidersPerLanguageQuery,
} from '../../../store/api/engagements.api';
import { HorizontalBarChart } from '../components/horizontal-bar-chart/horizontal-bar-chart.component';
import { CoreOverflowTypographyTooltip } from '../../_core/components/_ui/core-overflow-tooltip';
import { useGetDistrictListQuery } from '../../../store/api/district.api';
import { ProvidersPerLaguageBarChart } from '../components/providers-per-language-bar-chart/providers-per-language-bar-chart.component';
import { ChipCloseIcon } from '../../_core/components/icons';
import { chipDeleteIconStyles } from '../../_core/styles';

export type DashboardPageProps = {
	isAdminRoute: boolean;
	currentDistrictId?: string | null;
	fieldsListFromDistrict?: CustomFieldDto[];
	fieldsList?: CustomFieldDto[];
};

export const DashboardPage: FC<DashboardPageProps> = (props) => {
	const { isAdminRoute, fieldsListFromDistrict, currentDistrictId, fieldsList } = props;

	const [t] = useTranslation();
	const { name: nameDuration, control: controlDuration, transformDuration } = useDurationFilter();

	const today = new Date();
	const last30Days = new Date();
	last30Days.setDate(today.getDate() - 30);

	const [startDate, setStartDate] = useState<Date | null>(
		transformDuration?.start ? new Date(transformDuration.start) : last30Days
	);
	const [endDate, setEndDate] = useState<Date | null>(transformDuration?.end ? new Date(transformDuration.end) : today);

	const schoolField = isAdminRoute
		? fieldsList?.filter((field) => field.name === 'School')
		: fieldsListFromDistrict?.find((field) => field.name === 'School');

	const schoolOptions = Array.isArray(schoolField)
		? schoolField.flatMap((field) => field.options || [])
		: schoolField?.options || [];
	const { register: registerSchool, getValues: getValuesSchool, control: controlSchool, schools } = useSchoolFilter();

	const { data: districtList } = useGetDistrictListQuery();
	const {
		register: registerDistrict,
		getValues: getValuesDistrict,
		control: controlDistrict,
		districts,
	} = useDistrictFilter();

	const { data: languageData, isLoading } = useGetLanguageDistributionQuery(
		{
			start: startDate?.toISOString(),
			end: endDate?.toISOString(),
			schools: schools.join(','),
			district: currentDistrictId,
			districts: districts?.join(','),
		},
		{ refetchOnMountOrArgChange: true }
	);

	const { data: barChartData } = useGetAttendanceLanguageQuery(
		{
			start: startDate?.toISOString(),
			end: endDate?.toISOString(),
			schools: schools.join(','),
			district: currentDistrictId,
			districts: districts.join(','),
		},
		{ refetchOnMountOrArgChange: true }
	);

	const { data: providersPerLanguageChartData } = useGetProvidersPerLanguageQuery(
		{
			start: startDate?.toISOString(),
			end: endDate?.toISOString(),
			schools: schools.join(','),
			district: currentDistrictId,
			districts: districts.join(','),
		},
		{ refetchOnMountOrArgChange: true }
	);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', mb: 1, px: 3, gap: 2 }}>
			<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', gap: 2 }}>
				<Box>
					<Controller
						name={nameDuration}
						control={controlDuration}
						render={({ field }) => (
							<CoreCalendar
								{...field}
								selectsRange
								inline={false}
								startDate={startDate}
								endDate={endDate}
								onChange={(dates: [Date | null, Date | null]) => {
									const [start, end] = dates;
									setStartDate(start);
									setEndDate(end);
									field.onChange(dates);
								}}
								onKeyDown={(e) => {
									e.preventDefault();
								}}
								onClear={() => {
									setStartDate(null);
									setEndDate(null);
									field.onChange([null, null]);
								}}
							/>
						)}
					/>
				</Box>
				{isAdminRoute ? (
					<CoreAutoComplete
						multiple={true}
						{...registerDistrict('districts')}
						control={controlDistrict}
						controlSx={{ minWidth: '190px' }}
						options={districtList || []}
						isFilter
						getOptionLabel={(option) => option.name || ''}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option._id === value._id}
						renderInput={(params) => (
							<AutocompleteTextField
								{...params}
								placeholder={!getValuesDistrict('districts')?.length ? t('common.filter-district') : ''}
								sx={{
									'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
										padding: '4.5px 4px 5.5px 5px',
										minWidth: 0,
									},
									'& .MuiInputBase-input': {
										height: '100%',
										padding: '0',
									},
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: (theme) => theme.colors.blue,
										},
									},
								}}
							/>
						)}
						renderOption={(props, option) => (
							<MenuItem {...props} key={option._id}>
								<CoreOverflowTypographyTooltip>{option.name}</CoreOverflowTypographyTooltip>
							</MenuItem>
						)}
						renderTags={(value, getTagProps) => {
							const count = value.length - 1;

							return [
								value[0] && (
									<Chip
										sx={chipDeleteIconStyles}
										{...getTagProps({ index: 0 })}
										label={value[0]?.name}
										deleteIcon={<ChipCloseIcon />}
									/>
								),
								count > 0 && (
									<Chip
										sx={chipDeleteIconStyles}
										{...getTagProps({ index: count })}
										label={`+${count}`}
										deleteIcon={<ChipCloseIcon />}
									/>
								),
							];
						}}
					/>
				) : null}
				<Box sx={{ marginRight: '16px' }}>
					<CoreAutoComplete
						multiple={true}
						{...registerSchool('schools')}
						control={controlSchool}
						controlSx={{
							minWidth: '220px',
							maxWidth: '240px',
						}}
						isFilter
						options={schoolOptions.map((s) => s._id)}
						getOptionLabel={(option) => {
							const school = schoolOptions.find((s) => s._id === option);

							return school ? school.text : '';
						}}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option === value}
						renderInput={(params) => (
							<AutocompleteTextField
								{...params}
								placeholder={!getValuesSchool('schools')?.length ? t('common.filter-school') : ''}
								sx={{
									'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
										padding: '4.5px 4px 5.5px 5px',
										minWidth: 0,
									},
								}}
							/>
						)}
						renderOption={(props, option, { selected }) => {
							const school = schoolOptions.find((s) => s._id === option);

							return (
								<MenuItem {...props} key={school?._id}>
									<CoreCheckbox checked={selected} sx={{ mr: 2, p: 0, ml: 0 }} />

									{school?.text}
								</MenuItem>
							);
						}}
						renderTags={(value, getTagProps) => {
							const count = value.length - 1;

							return [
								value[0] && (
									<Chip
										sx={chipDeleteIconStyles}
										{...getTagProps({ index: 0 })}
										label={schoolOptions.find((s) => s._id === value[0])?.text}
										deleteIcon={<ChipCloseIcon />}
									/>
								),
								count > 0 && (
									<Chip
										sx={chipDeleteIconStyles}
										{...getTagProps({ index: count })}
										label={`+${count}`}
										deleteIcon={<ChipCloseIcon />}
									/>
								),
							];
						}}
					/>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', /*alignItems: 'start',*/ gap: 2 }}>
				<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
					{languageData && languageData.length > 0 ? (
						<LanguagePieChart data={languageData} />
					) : (
						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<Typography sx={{ color: (theme) => theme.colors.grayLightBlue, fontSize: '16px', fontWeight: 500 }}>
								No data found for the chart
							</Typography>
						</Box>
					)}
				</Box>
				<Box sx={{ flex: 1 }}>
					{barChartData && barChartData.length > 0 ? (
						<HorizontalBarChart chartData={barChartData} />
					) : (
						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<Typography sx={{ color: (theme) => theme.colors.grayLightBlue, fontSize: '16px', fontWeight: 500 }}>
								No data found for the chart
							</Typography>
						</Box>
					)}
				</Box>
				<Box sx={{ flex: 1 }}>
					{providersPerLanguageChartData && providersPerLanguageChartData.length > 0 ? (
						<ProvidersPerLaguageBarChart chartData={providersPerLanguageChartData} />
					) : (
						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<Typography sx={{ color: (theme) => theme.colors.grayLightBlue, fontSize: '16px', fontWeight: 500 }}>
								No data found for the chart
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
			<Box></Box>
		</Box>
	);
};
