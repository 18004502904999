import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ChipCloseIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon {...props}>
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" y="0.5" width="11" height="11" rx="5.5" stroke="currentColor" />
			<path
				d="M3.16467 3.16467C3.27015 3.05932 3.41314 3.00014 3.56222 3.00014C3.7113 3.00014 3.85428 3.05932 3.95976 3.16467L6 5.20416L8.04024 3.16467C8.09244 3.11246 8.15442 3.07105 8.22263 3.0428C8.29084 3.01454 8.36395 3 8.43778 3C8.51162 3 8.58472 3.01454 8.65293 3.0428C8.72115 3.07105 8.78312 3.11246 8.83533 3.16467C8.88754 3.21688 8.92895 3.27885 8.9572 3.34706C8.98546 3.41528 9 3.48838 9 3.56222C9 3.63605 8.98546 3.70915 8.9572 3.77737C8.92895 3.84558 8.88754 3.90756 8.83533 3.95976L6.79584 6L8.83533 8.04024C8.94077 8.14567 9 8.28868 9 8.43778C9 8.58689 8.94077 8.72989 8.83533 8.83533C8.72989 8.94077 8.58689 9 8.43778 9C8.28868 9 8.14567 8.94077 8.04024 8.83533L6 6.79584L3.95976 8.83533C3.85433 8.94077 3.71132 9 3.56222 9C3.41311 9 3.2701 8.94077 3.16467 8.83533C3.05923 8.72989 3 8.58689 3 8.43778C3 8.28868 3.05923 8.14567 3.16467 8.04024L5.20416 6L3.16467 3.95976C3.05932 3.85428 3.00014 3.7113 3.00014 3.56222C3.00014 3.41314 3.05932 3.27015 3.16467 3.16467Z"
				fill="currentColor"
			/>
		</svg>
	</SvgIcon>
);
