import { IsEmail, IsEnum, IsOptional, IsPhoneNumber, Length, IsBoolean, IsNotEmpty } from 'class-validator';
import { UserRolesEnum } from '../../enums';

export class CreateUserDto {
	@IsNotEmpty({ message: 'Email is required' })
	@IsEmail({}, { message: 'Incorrect Email' })
	email: string;

	@IsOptional()
	@Length(8, 36)
	password?: string;

	@IsOptional()
	@IsPhoneNumber()
	phoneNumber?: string;

	@Length(1, 100)
	name: string;

	@IsOptional()
	@IsEmail()
	googleEmail?: string;

	@IsEnum(UserRolesEnum, { message: 'Role is required' })
	role: string;

	@IsBoolean()
	active: boolean;

	@IsOptional()
	districtId?: string;
}
