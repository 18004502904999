import { Length, IsOptional, IsNotEmpty } from 'class-validator';

export class UpdateChildDto {
	@IsOptional()
	_id: string;

	@IsOptional()
	familyId?: string;

	@Length(1, 100)
	@IsNotEmpty({ message: 'Student Name is required' })
	name: string;

	@Length(1, 100)
	@IsNotEmpty({ message: 'Student ID is required' })
	studentId: string;

	createdById: string | null;
}
