export const ProfileRoutes = {
	Engagements: '/admin/engagements',
	EngagementDetails: '/admin/engagements/:engagementId',
	Root: '/admin/dashboard',
	Profile: '/profile',
	SecuritySettings: '/profile/security',
	Providers: '/admin/providers',
	ProviderDetails: '/admin/providers/:providerId',
	Districts: '/admin/districts',
	Families: '/admin/families',
	FamilyDetails: '/admin/families/:familyId',
	Distribute: '/distribute',
	SurveyTemplates: {
		Root: '/admin/survey-templates',
		Creating: '/admin/surveys-templates/creating',
		Editing: '/admin/survey-templates/editing/:surveyTemplateId',
	},
};
