import { FC } from 'react';
import { TableListQueryResponse } from '../../../_core/interfaces/table-list-query.interface';
import { ProviderInfoDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { CoreTable, CoreTableEmptyBody } from '../../../_core/components/_ui/core-table';
import { useProvidersDistrictTableColumns } from '../../hooks/providers-dictrict-table-columns.hook';

export interface ProvidersDistrictTableHandlers {
	onView(provider: ProviderInfoDto): void;
	onDelete(provider: ProviderInfoDto): void;
	onEdit(provider: ProviderInfoDto): void;
}

type ProvidersDistrictTableProps = TableListQueryResponse<ProviderInfoDto> & ProvidersDistrictTableHandlers;

export const ProvidersDistrictTable: FC<ProvidersDistrictTableProps> = (props) => {
	const { data, sorting, onSortingChange, loading, hasMore, fetchMore, scrollRef, onView, onEdit, onDelete } = props;

	const [t] = useTranslation();

	const columns = useProvidersDistrictTableColumns({
		onView,
		onEdit,
		onDelete,
	});

	const table = useReactTable<ProviderInfoDto>({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<CoreTable
			px={0}
			ref={scrollRef}
			loading={loading}
			hasMore={hasMore}
			threshold={200}
			emptyListComponent={<CoreTableEmptyBody description={t('providers.no-providers')} />}
			fetchMore={fetchMore}
			rowModel={table.getRowModel()}
			headerGroups={table.getHeaderGroups()}
		/>
	);
};
