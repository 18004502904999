import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { DefaultValues, useForm } from 'react-hook-form';
import { DistrictDto } from '@families-link/shared';

type DistrictFilterFormValues = {
	districts: [];
};

export const useDistrictFilter = () => {
	const [districts, setDistricts] = useState<DistrictDto[]>([]);

	const defaultValues: DefaultValues<DistrictFilterFormValues> = {
		districts: [],
	};
	const { register, getValues, control, watch } = useForm({
		defaultValues,
	});
	const filter = watch('districts');
	const debouncedUpdate = useCallback(
		debounce((list?: DistrictDto[]) => {
			setDistricts(list || []);
		}, 300),
		[]
	);

	useEffect(() => {
		debouncedUpdate(filter);
	}, [filter, debouncedUpdate]);

	return {
		register,
		getValues,
		control,
		districts,
		districtIds: districts.map((el) => el._id),
	};
};
