export const DistrictRoutes = {
	Root: '/district-level',
	Dashboard: '/district-level/dashboard',
	Engagements: {
		Root: '/district-level/engagements',
		Details: '/engagements/:engagementId',
	},
	Families: {
		Root: '/district-level/families',
		Details: '/families/:familyId',
	},
	Providers: {
		Root: '/district-level/providers',
		Details: '/providers/:providerId',
	},
	CustomRequirements: '/district-level/custom-requirements',
	SurveyTemplates: {
		Root: '/district-level/survey-templates',
		Creating: '/district-level/survey-template/creating',
		Editing: '/district-level/survey-template/editing/:surveyTemplateId',
		Preview: '/survey-templates/preview',
		Details: '/survey-templates/preview/:surveyTemplateId',
	},
	UserManagment: '/district-level/user-managment',
};
