import { FC, useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import { usePaginatedData, useSortingParams } from '../../../_core/hooks';
import { FamilyDetails } from '../../components/family-details/family-details.component';
import { EngagementsTable } from '../../../engagement/components/engagements-table';
import {
	useArchiveEngagementMutation,
	useCreateEngagementMutation,
	useDeleteEngagementMutation,
	useGenerateEngagementsCustomFieldFileUrlMutation,
	useGetEngagementsByFamilyIdQuery,
	useUpdateEngagementMutation,
} from '../../../../store/api/engagements.api';
import { EngagementDto } from '@families-link/shared';
import { setLocalStorageEngagementId, setLocalStorageFamilyId } from '../../../_core/utils';

import { useTranslation } from 'react-i18next';
import { useGetAllFieldsQuery } from '../../../../store/api/customRequirements.api';
import {
	useGetAllFamiliesByDistrictIdQuery,
	useGetChildrenByDistrictIdQuery,
	useGetChildrenByFamilyIdsQuery,
	useGetFamilyQuery,
} from '../../../../store/api/families.api';
import { useGetAllProvidersByDistrictIdQuery, useGetAllProvidersQuery } from '../../../../store/api/providers.api';
import { useOpenModalForm } from '../../../engagement/hooks/modal-forms/use-open-modal-form.hook';
import { useOpenConfirmModal } from '../../../engagement/hooks/modal-forms/use-open-confirm-modal-form.hook';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import AddIcon from '@mui/icons-material/Add';
import { DeleteIcon } from '../../../_core/components/icons';
import { DistrictRoutes } from '../../../district/constants';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useSessionStorage } from '../../../../hooks';
import { NotFoundComponent, ForbiddenComponent } from '../../../_core/components/exceptions';
import { ArchiveRedIcon } from '../../../_core/constants/icons.constants';

export const FamilyDetailsPage: FC = () => {
	const { familyId } = useParams();
	setLocalStorageFamilyId(familyId ?? '');
	const { data: currentFamily, isError, error } = useGetFamilyQuery({ id: familyId }, { skip: !familyId });
	const { data: currentChildrenList } = useGetChildrenByFamilyIdsQuery(
		{ families: currentFamily?._id },
		{ skip: !currentFamily?._id }
	);
	const [sessionStorageDistrictId, setSessionStorageDistrictId] = useSessionStorage('districtId');

	useEffect(() => {
		if (currentFamily?.districtId) {
			setSessionStorageDistrictId(currentFamily?.districtId);
		}
	}, [currentFamily?.districtId, setSessionStorageDistrictId]);

	const navigate = useNavigate();

	const [t] = useTranslation();
	const { data: fieldsListFromDistrict } = useGetAllFieldsQuery(
		{ districtId: currentFamily?.districtId },
		{ skip: !currentFamily?.districtId }
	);
	const { data: providersList } = useGetAllProvidersByDistrictIdQuery(
		{ districtId: currentFamily?.districtId },
		{ skip: !currentFamily?.districtId }
	);
	const { data: familiesList } = useGetAllFamiliesByDistrictIdQuery(
		{ district: currentFamily?.districtId },
		{ skip: !currentFamily?.districtId }
	);
	const { data: childrenList } = useGetChildrenByDistrictIdQuery(
		{ districtId: currentFamily?.districtId },
		{ skip: !currentFamily?.districtId }
	);

	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const { requestObject, meta, page, handleResetPage, itemsList, fetchMore } = usePaginatedData<EngagementDto>(
		useGetEngagementsByFamilyIdQuery,
		{ familyId, ...sortingParams },
		{ skip: !familyId, refetchOnMountOrArgChange: true }
	);
	const { refetch, isFetching } = requestObject;

	useEffect(() => {
		handleResetPage();
	}, [sortingArray, handleResetPage]);

	const handlePagination = () => {
		handleResetPage();

		if (page === 1) {
			refetch();
		}
	};

	const [generateEngagementsCustomFieldFileUrl] = useGenerateEngagementsCustomFieldFileUrlMutation();

	const [createEngagement] = useCreateEngagementMutation();
	const featchActionCreate = async (data: any) => {
		await createEngagement(data).unwrap();
	};
	const handleOpenModalCreate = useOpenModalForm({
		featchAction: featchActionCreate,
		handlePagination,
		isCreate: true,
		districtId: currentFamily?.districtId,
		submitButtonName: t('engagements.forms.buttons.create'),
		toastText: 'Engagement record was successfully created',
		fieldsListFromDistrict,
		providersList,
		familiesList: currentFamily ? [currentFamily] : [],
		childrenList: currentChildrenList ?? [],
		defaultValues: { family: currentFamily },
		generateFileUrlFunction: generateEngagementsCustomFieldFileUrl,
	});

	const [updateEngagement] = useUpdateEngagementMutation();
	const featchActionEdit = async (data: any) => {
		await updateEngagement({ id: data?._id?.toString(), data }).unwrap();
	};
	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		handlePagination,
		isCreate: false,
		districtId: currentFamily?.districtId,
		submitButtonName: t('engagements.forms.buttons.save'),
		toastText: 'Engagement record was successfully updated',
		fieldsListFromDistrict,
		providersList,
		familiesList,
		childrenList,
		generateFileUrlFunction: generateEngagementsCustomFieldFileUrl,
	});

	const [deleteEngagement] = useDeleteEngagementMutation();
	const featchActionDelete = async (data: EngagementDto) => {
		await deleteEngagement({ id: data._id }).unwrap();
	};
	const handleOpenDeleteConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDelete,
		handlePagination,
		title: t('engagements.forms.delete-title'),
		description: t('engagements.forms.delete-description'),
		icon: <DeleteIcon sx={{ fontSize: 40 }} />,
		danger: true,
		submitButtonName: t('engagements.forms.buttons.delete'),
		toastText: 'Engagement record was successfully deleted',
	});

	const handleOnView = (engagement: EngagementDto) => {
		setLocalStorageEngagementId(engagement._id);
		navigate(generatePath(DistrictRoutes.Engagements.Details, { engagementId: engagement._id }));
	};

	const [archiveEngagement] = useArchiveEngagementMutation();

	const fetchActionArchive = async (data: EngagementDto) => {
		await archiveEngagement({ id: data._id }).unwrap();
	};
	const handleArchiveConfirmModal = useOpenConfirmModal({
		featchAction: fetchActionArchive,
		handlePagination,
		title: t('engagements.forms.archive-title'),
		description: t('engagements.forms.archive-description'),
		icon: <ArchiveRedIcon />,
		danger: true,
		submitButtonName: t('engagements.forms.buttons.archive'),
		toastText: 'Engagement record was successfully archived.',
	});

	if (isError) {
		switch ((error as any).data.statusCode) {
			case 404:
			case 400:
				return <NotFoundComponent componentName="family" />;
			case 403:
				return <ForbiddenComponent componentName="family" />;
			default:
				break;
		}
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
			<Paper elevation={0} sx={{ width: '100%', mr: 1, p: 1, height: '32%' }}>
				<FamilyDetails familyId={familyId} />
			</Paper>
			<Paper
				elevation={0}
				sx={{
					flex: 1,
					p: 3,
					display: 'flex',
					height: '70%',
					mt: 1,
					borderBottomRightRadius: 0,
					borderBottomLeftRadius: 0,
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'flex-end',
						width: '100%',
					}}
				>
					<CoreButton
						onClick={() => {
							handleOpenModalCreate();
						}}
						sx={{
							display: 'flex',
							padding: '12px 24px',
							gap: '12px',
						}}
					>
						<AddIcon /> {t('engagements.add-engagements')}
					</CoreButton>
				</Box>
				<EngagementsTable
					data={itemsList}
					loading={isFetching}
					hasMore={meta?.hasNextPage}
					onDelete={handleOpenDeleteConfirmModal}
					onEdit={handleOpenModalEdit}
					onView={handleOnView}
					onArchive={handleArchiveConfirmModal}
					sorting={sortingArray}
					onSortingChange={handleSortingChange}
					fetchMore={fetchMore}
					permission={{ isDetailsPage: true }}
				/>
			</Paper>
		</Box>
	);
};
