import { PageMetaDto } from '@families-link/shared';
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';

export const usePaginatedData = <T>(useGetQuery: any, params: any, options?: any) => {
	const [page, setPage] = useState(1);
	const [itemsList, setItemsList] = useState<T[]>([]);
	const [metaInformation, setMetaInformation] = useState<PageMetaDto>();
	const isFirstRender = useRef(true);

	const requestObject = useGetQuery(
		{
			page,
			...params,
		},
		options
	);

	const { data, refetch, isFetching } = requestObject;

	const handleResetPage = useCallback(() => {
		setItemsList([]);
		setPage(1);
		refetch();
	}, [refetch]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}

		if (!isFetching && data) {
			if (page === 1) {
				setItemsList(data.data);
			} else if (metaInformation?.page !== page) {
				setItemsList((prevItems) => [...prevItems, ...data.data]);
			}
			setMetaInformation(data.meta);
		}
	}, [data, isFetching]);

	const fetchMore = useCallback(() => {
		setPage((prevPage) => prevPage + 1);
	}, []);

	return {
		itemsList: !itemsList?.length && data?.data ? data?.data : itemsList,
		fetchMore,
		page,
		handleResetPage,
		requestObject,
		meta: data?.meta,
	};
};
