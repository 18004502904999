import { IsNotEmpty, IsOptional, IsString, Length, IsEmail, ArrayNotEmpty } from 'class-validator';

export class CreateProviderFrontDto {
	@Length(1, 100)
	@IsNotEmpty({ message: 'Full Name is required' })
	fullName: string;

	@ArrayNotEmpty({ message: 'Target Language is required' })
	targetLanguage: string[];

	@IsNotEmpty({ message: 'Verification Type is required' })
	verificationType: boolean;

	@IsNotEmpty({ message: 'Work Status is required' })
	workStatus: string;

	@IsNotEmpty({ message: 'Service Type is required' })
	serviceType: string;

	@IsNotEmpty({ message: 'Email is required' })
	@IsEmail({}, { message: 'Incorrect Email' })
	email: string;

	districtId: string;

	@IsOptional()
	imageUrl?: string;
}
