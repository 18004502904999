import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const VerifiedIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon {...props}>
		<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				id="Vector"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.25 16C9.30058 16 10.3409 15.7931 11.3115 15.391C12.2821 14.989 13.164 14.3997 13.9069 13.6569C14.6497 12.914 15.239 12.0321 15.641 11.0615C16.0431 10.0909 16.25 9.05058 16.25 8C16.25 6.94943 16.0431 5.90914 15.641 4.93853C15.239 3.96793 14.6497 3.08601 13.9069 2.34315C13.164 1.60028 12.2821 1.011 11.3115 0.608964C10.3409 0.206926 9.30058 -1.56548e-08 8.25 0C6.12827 3.16163e-08 4.09344 0.842855 2.59315 2.34315C1.09285 3.84344 0.25 5.87827 0.25 8C0.25 10.1217 1.09285 12.1566 2.59315 13.6569C4.09344 15.1571 6.12827 16 8.25 16ZM8.04378 11.2356L12.4882 5.90222L11.1229 4.76444L7.30067 9.35022L5.32289 7.37156L4.066 8.62844L6.73267 11.2951L7.42067 11.9831L8.04378 11.2356Z"
				fill="#19AF00"
			/>
		</svg>
	</SvgIcon>
);
