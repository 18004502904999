import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Chip, MenuItem, Typography } from '@mui/material';
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import {
	ChildDto,
	CustomFieldDto,
	DefaultFieldTypeStatusArray,
	DefaultFieldTypeStatusEnum,
	EngagementStatusArray,
	EngagementStatusEnum,
	FamilyDto,
	FieldDataTypeEnum,
	FieldValueInterface,
	LanguageEnum,
	ProviderDto,
	ServiceTypeStatusEnum,
} from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import {
	containerButtonStyles,
	leftButtonStyles,
	inputBoxStyles,
	modalBoxStyles,
	errorTextStyles,
	containerFormStyles,
	autocompleteBoxStyles,
} from './modal-form.styles';
import { AutocompleteTextField, CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { CoreSelect } from '../../../_core/components/_ui/core-select';
import { CoreDurationDropdown } from '../../../_core/components/_ui/core-duration-dropdown';
import {
	buttonModalWindowStyles,
	chipDeleteIconStyles,
	mainTitleModalWindowStyles,
	selectListTypographyOverflowStyles,
} from '../../../_core/styles';
import { mergeSx } from 'merge-sx';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import { ClearIcon } from '@mui/x-date-pickers';
import { transformValuesObject } from '../../utils/transformSubmitValuesObject.util';
import { getDefaultFormFields } from '../../utils/getDefaultFormFields.util';
import { InputComponentByType } from './input-component-by-type.component';
import { useMuiModal } from '../../../_core/hooks';
import { SaveDraftModalForm, SaveDraftModalFormProps } from './modal-save-draft-fom.component';
import { CoreCheckbox } from '../../../_core/components/_ui/core-checkbox';
import { ConfirmationModal, ConfirmationModalProps } from '../../../_core/components/confirmation-modal';
import { ChipCloseIcon } from '../../../_core/components/icons';

export type EngagementFormModalProps = CoreModalProps & {
	isCreate: boolean;
	formTitle: string;
	defaultValues?: any;
	formError?: any;
	submitButtonName?: string;
	fieldsListFromDistrict?: CustomFieldDto[];
	providersList?: ProviderDto[];
	familiesList?: FamilyDto[];
	childrenList?: ChildDto[];
	generateFileUrlFunction: any;
	submitAction(values: any): Promise<void>;
	closeModal(): void;
};

export const EngagementFormModal: FC<EngagementFormModalProps> = (props) => {
	const [t] = useTranslation();
	const {
		isCreate,
		formTitle,
		submitAction,
		formError,
		closeModal,
		defaultValues,
		fieldsListFromDistrict,
		providersList,
		familiesList,
		childrenList,
		submitButtonName,
		generateFileUrlFunction,
		onClose,
		...modalProps
	} = props;

	const methods = useForm({
		defaultValues,
		mode: 'all',
	});

	const {
		register,
		handleSubmit,
		reset,
		control,
		watch,
		getValues,
		setValue,
		clearErrors,
		formState: { errors, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = methods;

	const childrenInForm = watch('children');
	const providerInForm = watch('provider');
	const languageInForm = watch('language');

	const fieldsList: CustomFieldDto[] = !fieldsListFromDistrict?.length
		? defaultValues.customFields
		: fieldsListFromDistrict;

	const fileFormFields = fieldsList
		?.filter((el: CustomFieldDto) => el.dataType === FieldDataTypeEnum.File)
		?.map((el) => el._id);

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const defaultFormFields = useMemo(() => getDefaultFormFields(fieldsList), [fieldsList]);

	const typeInForm = watch(`field_${defaultFormFields.type?._id}`);

	const [filteredProviders, setFilteredProviders] = useState(
		defaultValues?.districtId
			? providersList?.filter((provider) => provider.districtId === defaultValues.districtId)
			: providersList
	);
	const [filteredChildren, setFilteredChildren] = useState(
		defaultValues?.districtId
			? childrenList?.filter((child) => child.family?.districtId === defaultValues.districtId)
			: childrenList
	);

	const appointmentFields = fieldsList?.filter(
		(field) => !field.defaultFieldId && field.engagementType !== DefaultFieldTypeStatusEnum.Project
	);

	const projectFields = fieldsList?.filter(
		(field) => !field.defaultFieldId && field.engagementType !== DefaultFieldTypeStatusEnum.Appointment
	);

	useEffect(() => {
		if (!providerInForm) {
			setValue(`field_${defaultFormFields.service?._id}`, '');
		}
	}, [providerInForm]);

	useEffect(() => {
		if (
			providerInForm &&
			providerInForm.serviceType !== typeInForm &&
			providerInForm.serviceType !== ServiceTypeStatusEnum.Both
		) {
			setValue('provider', '');
		}

		if (languageInForm) {
			setFilteredProviders(
				providersList?.filter(
					(provider) =>
						provider.targetLanguage === languageInForm &&
						(provider.serviceType === typeInForm || provider.serviceType === ServiceTypeStatusEnum.Both)
				)
			);
		} else {
			setFilteredProviders(
				providersList?.filter(
					(provider) => provider.serviceType === typeInForm || provider.serviceType === ServiceTypeStatusEnum.Both
				)
			);
		}
	}, [typeInForm]);

	useEffect(() => {
		if (providerInForm && !providerInForm.targetLanguage?.includes(languageInForm)) {
			setValue('provider', '');
		}

		if (childrenInForm?.length && !childrenInForm[0].family.preferedLanguage.includes(languageInForm)) {
			setValue('children', []);
		}

		if (languageInForm) {
			setFilteredProviders(
				providersList?.filter(
					(provider) =>
						provider.targetLanguage.includes(languageInForm) &&
						(provider.serviceType === typeInForm || provider.serviceType === ServiceTypeStatusEnum.Both)
				)
			);
			setFilteredChildren(childrenList?.filter((child) => child.family?.preferedLanguage.includes(languageInForm)));
		} else {
			setFilteredProviders(
				providersList?.filter(
					(provider) => provider.serviceType === typeInForm || provider.serviceType === ServiceTypeStatusEnum.Both
				)
			);
			setFilteredChildren(childrenList);
		}
	}, [languageInForm]);

	useEffect(() => {
		if (typeInForm === DefaultFieldTypeStatusEnum.Project) {
			reset((formValues: any) => ({
				...formValues,
				...appointmentFields.reduce((res: any, el) => {
					res[`field_${el._id}`] = defaultValues[`field_${el._id}`];

					return res;
				}, {}),
			}));
		} else if (typeInForm === DefaultFieldTypeStatusEnum.Appointment) {
			reset((formValues: any) => ({
				...formValues,
				...projectFields.reduce((res: any, el) => {
					res[`field_${el._id}`] = defaultValues[`field_${el._id}`];

					return res;
				}, {}),
			}));
		}
	}, [typeInForm, reset]);

	const handleFormSubmit = async (values: any, isDraft = false) => {
		const transformedValues = transformValuesObject(values, typeInForm, defaultFormFields);

		if (isDraft) {
			clearErrors();
			transformedValues['status'] = EngagementStatusEnum.Incomplete;
		} else {
			if (transformedValues['status'] === EngagementStatusEnum.Incomplete) {
				transformedValues['status'] = EngagementStatusEnum.New;
			}
		}

		try {
			transformedValues['customFieldsValues'] = await Promise.all(
				transformedValues['customFieldsValues']?.map(async (el: FieldValueInterface) => {
					if (fileFormFields?.includes(el.fieldId.toString()) && el.value) {
						el.value = await Promise.all(
							el.value?.map(async (valueEl: any) => {
								if (!valueEl?.url) {
									const formData = new FormData();
									formData.append('file', valueEl);
									try {
										const result = await generateFileUrlFunction(formData).unwrap();

										if (result) {
											return {
												fileId: valueEl.fileId,
												name: valueEl.name,
												type: valueEl.type,
												url: result.url,
											};
										}
									} catch (error) {
										return null;
									}
								}

								return valueEl;
							})
						);

						return {
							fieldId: el.fieldId,
							value: el.value,
						};
					} else {
						return { fieldId: el.fieldId, value: el.value };
					}
				})
			);
			await submitAction(transformedValues);
		} catch (error) {
			console.error(error);
		}
	};

	const handleFormSubmitEngagement = async (values: any) => {
		await handleFormSubmit(values);
	};

	const handleFormSubmitDraft = async () => {
		clearErrors();
		const values = getValues();
		await handleFormSubmit(values, true);
	};

	const { openModal: openModalDraft, closeModal: closeDraftModal } =
		useMuiModal<SaveDraftModalFormProps>(SaveDraftModalForm);

	const handleOpenDraftModal = useCallback(() => {
		openModalDraft({
			submitAction: handleFormSubmitDraft,
			closeParentModal: closeModal,
			closeModal: closeDraftModal,
		});
	}, [openModalDraft, handleSubmit]);

	const { openModal: openModalConfirm, closeModal: closeModalConfirm } =
		useMuiModal<ConfirmationModalProps>(ConfirmationModal);

	const handleOpenConfirmModal = useCallback(() => {
		openModalConfirm({
			title: 'There are unsaved changes',
			description: 'If you close the form, all unsaved changes will be lost',
			onSubmit: () => {
				closeModalConfirm();
				closeModal();
			},
			onClose: closeModalConfirm,
			modalWidth: 600,
			minButtonWidth: 100,
			spacingHeight: 4,
			leftButtonProps: {
				children: t('engagements.forms.buttons.cancel'),
				variant: 'secondary',
				sx: buttonModalWindowStyles,
			},
			rightButtonProps: {
				children: t('engagements.forms.buttons.confirm'),
				contraModeStyleButton: true,
				sx: buttonModalWindowStyles,
			},
		});
	}, [openModalConfirm, handleSubmit]);

	const customOnClose = isDirty ? (isCreate ? handleOpenDraftModal : handleOpenConfirmModal) : onClose;
	const customCloseFormButton = isDirty ? (isCreate ? handleOpenDraftModal : handleOpenConfirmModal) : closeModal;

	return (
		<CoreModal {...modalProps} onClose={customOnClose} bodySx={{ pt: 0 }} paperSx={{ width: '100%' }}>
			<Box sx={modalBoxStyles}>
				<Typography variant="h5" sx={mainTitleModalWindowStyles}>
					{formTitle}
				</Typography>
				<FormProvider {...methods}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Box component="form" onSubmit={handleSubmit(handleFormSubmitEngagement)}>
							<Box sx={inputBoxStyles}>
								<Box sx={containerFormStyles}>
									<InputComponentByType
										typeInForm={typeInForm}
										customField={defaultFormFields.title}
										label={t('engagements.forms.labels.title')}
									/>
									<InputComponentByType
										typeInForm={typeInForm}
										customField={defaultFormFields.type}
										label={t('engagements.forms.labels.type')}
										statusEnum={DefaultFieldTypeStatusArray}
									/>
								</Box>
								<Box sx={containerFormStyles}>
									<InputComponentByType
										typeInForm={typeInForm}
										customField={defaultFormFields.school}
										label={t('engagements.forms.labels.school')}
									/>
									<InputComponentByType
										typeInForm={typeInForm}
										customField={defaultFormFields.program}
										label={t('engagements.forms.labels.program')}
									/>
								</Box>
								{typeInForm === DefaultFieldTypeStatusEnum.Appointment ? (
									<InputComponentByType
										typeInForm={typeInForm}
										customField={defaultFormFields.duration}
										label={t('engagements.forms.labels.duration')}
									/>
								) : null}
								<Box sx={containerFormStyles}>
									{typeInForm === DefaultFieldTypeStatusEnum.Appointment ? (
										<Controller
											key={'appointmentDuration'}
											name={'appointmentDuration'}
											control={control}
											rules={{
												validate: (value) => {
													if (!value) return 'Duration is required';
													return true;
												},
											}}
											render={({ field }) => (
												<CoreDurationDropdown
													label={t('engagements.forms.labels.duration')}
													requiredMark
													fullWidth
													placeholder={t('engagements.forms.placeholders.options')}
													{...register('appointmentDuration', {
														required: `${t('engagements.forms.labels.duration')} is required`,
													})}
													value={watch('appointmentDuration') || ''}
													error={!!errors['appointmentDuration']}
													helperText={(errors['appointmentDuration'] as FieldError)?.message}
													controlSx={inputBoxStyles}
												/>
											)}
										/>
									) : (
										<InputComponentByType
											typeInForm={typeInForm}
											customField={defaultFormFields.duration}
											label={t('engagements.forms.labels.duration')}
										/>
									)}

									<CoreSelect
										label={t('engagements.forms.labels.status')}
										requiredMark
										fullWidth
										placeholder={t('engagements.forms.placeholders.options')}
										{...register('status', { required: `${t('engagements.forms.labels.status')} is required` })}
										value={watch('status') || ''}
										error={!!errors['status']}
										helperText={(errors['status'] as FieldError)?.message}
										controlSx={inputBoxStyles}
									>
										{EngagementStatusArray?.map((value) => (
											<MenuItem key={value} value={value}>
												{value}
											</MenuItem>
										))}
									</CoreSelect>
								</Box>

								<Box sx={containerFormStyles}>
									<CoreAutoComplete
										label={t('engagements.forms.labels.language')}
										requiredMark
										fullWidth
										{...register('language')}
										control={control}
										ruleIsRequired
										ruleName={t('engagements.forms.labels.language')}
										error={!!errors['language']}
										helperText={(errors['language'] as FieldError)?.message}
										options={Object.values(LanguageEnum)}
										getOptionLabel={(option) => {
											const language = getObjectLanguage(option);

											return language ? language.name : '';
										}}
										selectOnFocus={true}
										isOptionEqualToValue={(option, value) => option === value}
										renderInput={(params) => (
											<AutocompleteTextField
												{...params}
												error={!!errors['language']}
												placeholder={t('engagements.forms.placeholders.options')}
											/>
										)}
										renderOption={(props, option) => {
											const language = getObjectLanguage(option);

											return (
												<MenuItem {...props} key={language?.value}>
													{language?.label}
												</MenuItem>
											);
										}}
										controlSx={autocompleteBoxStyles}
									/>

									<CoreAutoComplete
										multiple={true}
										label={t('engagements.forms.labels.students')}
										fullWidth
										disabled={!getValues('language')}
										{...register('children')}
										control={control}
										error={!!errors['children']}
										helperText={(errors['children'] as FieldError)?.message}
										options={filteredChildren || []}
										groupBy={(option) => option.familyId}
										getOptionLabel={(option) => option.name || ''}
										selectOnFocus={true}
										isOptionEqualToValue={(option, value) => option._id === value._id}
										renderInput={(params) => (
											<AutocompleteTextField
												{...params}
												disabled={!getValues('language')}
												error={!!errors['children']}
												placeholder={getValues('children')?.length ? '' : t('engagements.forms.placeholders.options')}
											/>
										)}
										renderGroup={(params) => {
											const currentFamily = filteredChildren?.find((child) => child.familyId === params.group)?.family;

											return (
												<Box
													component="li"
													key={params.key}
													sx={{
														px: 1,
													}}
												>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															padding: '4px 10px',
															borderBottomWidth: '1px',
															borderBottomStyle: 'solid',
															borderBottomColor: (theme) => theme.colors.blueTransparent,
														}}
													>
														<Avatar
															src={currentFamily?.imageUrl}
															alt={currentFamily?.fullName}
															style={{ width: 24, height: 24, marginRight: 5, borderRadius: '6px' }}
														/>
														<Typography sx={selectListTypographyOverflowStyles}>{currentFamily?.fullName}</Typography>
													</Box>
													<Box component="ul" sx={{ my: 1, px: 0 }}>
														{params.children}
													</Box>
												</Box>
											);
										}}
										renderOption={(props, option, { selected }) => (
											<MenuItem {...props} key={option._id} sx={{ display: 'flex', alignItems: 'center' }}>
												<CoreCheckbox checked={selected} sx={{ mr: 2, p: 0, ml: 0 }} />
												<Typography sx={selectListTypographyOverflowStyles}>{option.name}</Typography>
											</MenuItem>
										)}
										renderTags={(value, getTagProps) => {
											const count = value?.length - 1;

											return [
												value[0] && (
													<Chip
														sx={chipDeleteIconStyles}
														{...getTagProps({ index: 0 })}
														size="small"
														label={value[0]?.name}
														deleteIcon={<ChipCloseIcon />}
													/>
												),
												count > 0 && (
													<Chip
														sx={chipDeleteIconStyles}
														{...getTagProps({ index: count })}
														size="small"
														label={`+${count}`}
														deleteIcon={<ChipCloseIcon />}
													/>
												),
											];
										}}
										clearIcon={
											<ClearIcon sx={{ fontSize: '16px', '& .MuiAutocomplete-clearIndicator': { padding: '2px' } }} />
										}
										controlSx={autocompleteBoxStyles}
									/>
								</Box>

								<Box sx={containerFormStyles}>
									<CoreAutoComplete
										label={t('engagements.forms.labels.provider')}
										defaultValue={defaultValues.provider}
										fullWidth
										disabled={!getValues('language')}
										{...register('provider')}
										control={control}
										error={!!errors['provider']}
										helperText={(errors['provider'] as FieldError)?.message}
										options={filteredProviders || []}
										getOptionLabel={(option) => option.fullName || ''}
										selectOnFocus={true}
										isOptionEqualToValue={(option, value) => option._id === value._id}
										renderInput={(params) => (
											<AutocompleteTextField
												{...params}
												disabled={!getValues('language')}
												error={!!errors['provider']}
												placeholder={t('engagements.forms.placeholders.options') ?? ''}
											/>
										)}
										renderOption={(props, option) => (
											<MenuItem {...props} key={option._id} sx={{ display: 'flex', alignItems: 'center' }}>
												<Avatar
													src={option.imageUrl}
													alt={option.fullName}
													style={{ width: 24, height: 24, marginRight: 18, borderRadius: '6px' }}
												/>
												<Typography sx={selectListTypographyOverflowStyles}>{option.fullName}</Typography>
											</MenuItem>
										)}
										controlSx={autocompleteBoxStyles}
									/>
									<InputComponentByType
										typeInForm={typeInForm}
										customField={defaultFormFields.service}
										label={t('engagements.forms.labels.service')}
										disabled={!providerInForm}
									/>
								</Box>

								<InputComponentByType
									typeInForm={typeInForm}
									customField={defaultFormFields.description}
									label={t('engagements.forms.labels.description')}
								/>

								{typeInForm === DefaultFieldTypeStatusEnum.Project
									? projectFields?.map((el: any) => <InputComponentByType typeInForm={typeInForm} customField={el} />)
									: appointmentFields?.map((el: any) => (
											<InputComponentByType typeInForm={typeInForm} customField={el} />
									  ))}
							</Box>

							{formError && (
								<Typography sx={errorTextStyles}>
									{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
								</Typography>
							)}
							<Box sx={containerButtonStyles}>
								{isCreate ? (
									<CoreButton
										type="button"
										variant="secondary"
										onClick={handleFormSubmitDraft}
										loading={loadingSubmit}
										sx={buttonModalWindowStyles}
									>
										{t('engagements.forms.buttons.save-draft')}
									</CoreButton>
								) : defaultValues?.status === EngagementStatusEnum.Incomplete ? (
									<CoreButton
										type="submit"
										disabled={disableSubmit}
										loading={loadingSubmit}
										sx={buttonModalWindowStyles}
									>
										{t('engagements.forms.buttons.publish')}
									</CoreButton>
								) : null}

								<CoreButton
									onClick={customCloseFormButton}
									variant="secondary"
									sx={mergeSx(buttonModalWindowStyles, leftButtonStyles, { marginLeft: 'auto' })}
								>
									{t('engagements.forms.buttons.close-form')}
								</CoreButton>
								{isCreate ? (
									<CoreButton
										type="submit"
										contraModeStyleButton
										disabled={disableSubmit}
										loading={loadingSubmit}
										sx={buttonModalWindowStyles}
									>
										{submitButtonName || t('engagements.forms.buttons.save')}
									</CoreButton>
								) : defaultValues?.status === EngagementStatusEnum.Incomplete ? (
									<CoreButton
										type="button"
										loading={loadingSubmit}
										onClick={handleFormSubmitDraft}
										sx={buttonModalWindowStyles}
									>
										{submitButtonName || t('engagements.forms.buttons.publish')}
									</CoreButton>
								) : (
									<CoreButton
										type="submit"
										contraModeStyleButton
										disabled={disableSubmit}
										loading={loadingSubmit}
										sx={buttonModalWindowStyles}
									>
										{submitButtonName || t('engagements.forms.buttons.save')}
									</CoreButton>
								)}
							</Box>
						</Box>
					</LocalizationProvider>
				</FormProvider>
			</Box>
		</CoreModal>
	);
};
