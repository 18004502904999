import { FC } from 'react';
import { Container } from '@mui/material';
import { CreateSurveyTemplateDto, UpdateSurveyTemplateDto } from '@families-link/shared';
import { SurveyTemplateForm } from '../../components/modal-forms';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	useCreateSurveyTemplateMutation,
	useLazyGetSurveyTemplateByLanguageQuery,
	useUpdateSurveyTemplateMutation,
} from '../../../../store/api/survey.api';
import { useSnackbar } from 'notistack';
import { getLocalStorageDistrictId } from '../../../_core/utils';
import { DistrictRoutes } from '../../../district/constants';
import { ProfileRoutes } from '../../../profile/constants';
import { useSessionStorage } from '../../../../hooks';
import { useTranslation } from 'react-i18next';

export const SurveyTemplateActionPage: FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const location = useLocation();
	const { data } = location.state || {};
	const isAdminRoute = location.pathname.startsWith('/admin');
	const [sessionStorageDistrictId] = useSessionStorage('districtId');
	const currentDistrictId = sessionStorageDistrictId ?? getLocalStorageDistrictId();

	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
		});
	};

	const [createSurveyTemplate] = useCreateSurveyTemplateMutation();
	const [updateSurveyTemplate] = useUpdateSurveyTemplateMutation();
	const [triggerGetSurveyTemplateByLanguageQuery] = useLazyGetSurveyTemplateByLanguageQuery();

	let handleSubmit;

	const { surveyTemplateId } = useParams();
	const isEditing = !!surveyTemplateId;

	if (surveyTemplateId) {
		handleSubmit = async (values: UpdateSurveyTemplateDto): Promise<void> => {
			const updateSurveyTemplateData: UpdateSurveyTemplateDto = { ...values };
			try {
				if (!isAdminRoute && updateSurveyTemplateData.language && currentDistrictId) {
					try {
						const result = await triggerGetSurveyTemplateByLanguageQuery({
							language: updateSurveyTemplateData.language,
							districtId: currentDistrictId,
						}).unwrap();

						if (result) {
							showToast(
								`Default survey template for language ${updateSurveyTemplateData.language} already exists`,
								'error'
							);

							return;
						}
					} catch (error) {
						// continue
					}
				}
				await updateSurveyTemplate({ id: surveyTemplateId, data: updateSurveyTemplateData }).unwrap();
				showToast('Survey template was successfully updated', 'success');

				if (isAdminRoute) {
					navigate(ProfileRoutes.SurveyTemplates.Root);
				} else {
					navigate(DistrictRoutes.SurveyTemplates.Root);
				}
			} catch (error: any) {
				console.log(error);
				showToast('Survey template updating is failed', 'error');
			}
		};
	} else {
		handleSubmit = async (values: CreateSurveyTemplateDto): Promise<void> => {
			const createSurveyTemplateData: CreateSurveyTemplateDto = { ...values };
			try {
				if (!isAdminRoute && createSurveyTemplateData.language && currentDistrictId) {
					try {
						const result = await triggerGetSurveyTemplateByLanguageQuery({
							language: createSurveyTemplateData.language,
							districtId: currentDistrictId,
						}).unwrap();

						if (result) {
							showToast(
								`Default survey template for language ${createSurveyTemplateData.language} already exists`,
								'error'
							);

							return;
						}
					} catch (error) {
						// continue
					}
				}

				await createSurveyTemplate(createSurveyTemplateData).unwrap();
				showToast('Survey template was successfully created', 'success');

				if (isAdminRoute) {
					navigate(ProfileRoutes.SurveyTemplates.Root);
				} else {
					navigate(DistrictRoutes.SurveyTemplates.Root);
				}
			} catch (error: any) {
				console.log(error);
				showToast('Survey template creating is failed', 'error');
			}
		};
	}

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<SurveyTemplateForm
				adminTemplate={isAdminRoute}
				currentDistrictId={currentDistrictId}
				submitAction={handleSubmit}
				defaultValues={isEditing ? data : undefined}
				isEditing={isEditing}
			/>
		</Container>
	);
};
