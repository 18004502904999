import { useSessionStorage } from '../../../hooks';
import { useGetAllFieldsQuery } from '../../../store/api/customRequirements.api';
import { FC } from 'react';
import { getLocalStorageDistrictId } from '../../_core/utils';
import { DashboardPage } from './dashboard.page';

export const DashboardDistrictLevelPage: FC = () => {
	const [sessionStorageDistrictId] = useSessionStorage('districtId');
	const districtId = sessionStorageDistrictId ?? getLocalStorageDistrictId();

	const { data: fieldsListFromDistrict } = useGetAllFieldsQuery({ districtId }, { skip: !districtId });

	return (
		<DashboardPage
			isAdminRoute={false}
			currentDistrictId={districtId}
			fieldsListFromDistrict={fieldsListFromDistrict}
		/>
	);
};
