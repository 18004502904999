import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { DistrictDto } from '@families-link/shared';
import { DistrictsTableHandlers } from '../../components/districts-table/districts-table.component';
import { DistrictsTableActions } from '../../components/districts-table/districts-table-actions.component';
import { Typography } from '@mui/material';
import { tableCellTypographyOverflowStyles } from '../../../_core/styles';

const helper = createColumnHelper<DistrictDto & { createdAt: string; updatedAt: string }>();

export function useDistrictsTableColumns(handlers: DistrictsTableHandlers): ColumnDef<DistrictDto, string>[] {
	const { onDelete, onEdit, onView } = handlers;

	const [t] = useTranslation();

	const result = useMemo(
		() => [
			helper.accessor('name', {
				header: t('districts.columns.name'),
				meta: {
					flex: 3,
					useCustomOverflowContainer: true,
				},
				cell: (info) => <Typography data-overflow="true" fontWeight={500} sx={tableCellTypographyOverflowStyles}>{info.getValue()}</Typography>,
			}) as ColumnDef<DistrictDto, string>,
			helper.accessor('engagementCount', {
				header: t('districts.columns.total-engagments'),
				meta: {
					flex: 1,
				},
			}) as ColumnDef<DistrictDto, string>,
			helper.display({
				id: 'actions',
				cell: (props) => (
					<DistrictsTableActions district={props.row.original} onDelete={onDelete} onEdit={onEdit} onView={onView} />
				),
			}) as ColumnDef<DistrictDto>,
		],
		[t, onDelete, onEdit]
	);

	return result;
}
