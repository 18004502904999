import { IsNotEmpty, IsEnum, ArrayNotEmpty, ValidationArguments, IsEmail } from 'class-validator';
import { LanguageEnum } from '../../enums';

export class ProviderImportDto {
	@IsNotEmpty({ message: 'Full name is required' })
	fullName: string;

	@IsEnum(LanguageEnum, {
		each: true,
		message: 'Target language is required or incorrect',
	})
	targetLanguage: string[];

	@IsNotEmpty({ message: 'Verification type is required' })
	verificationType: boolean;

	@IsNotEmpty({ message: 'Work Status is required' })
	workStatus: string;

	@IsNotEmpty({ message: 'Service type is required' })
	serviceType: string;

	@IsNotEmpty({ message: 'Email is required' })
	@IsEmail()
	email: string;
}
