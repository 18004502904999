import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { Avatar, Box, Chip, Container, Divider, InputAdornment, MenuItem, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import {
	ChildDto,
	CustomFieldDto,
	EngagementDto,
	FamilyDto,
	LanguageEnum,
	PermissionDto,
	ProviderDto,
} from '@families-link/shared';

import {
	useArchiveEngagementMutation,
	useCreateEngagementMutation,
	useDeleteEngagementMutation,
	useExportEngagementsToCSVMutation,
	useGenerateEngagementsCustomFieldFileUrlMutation,
	useGetEngagementsListQuery,
	useImportEngagementsMutation,
	useUnarchiveEngagementMutation,
	useUpdateEngagementMutation,
} from '../../../../store/api/engagements.api';

import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { EngagementsTable } from '../../components/engagements-table';
import { DistrictRoutes } from '../../../district/constants';
import {
	useSearch,
	useSortingParams,
	usePaginatedData,
	useLanguageFilter,
	useProviderFilter,
	useDurationFilter,
	useMuiModal,
} from '../../../_core/hooks';
import { AutocompleteTextField, CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import { useOpenModalForm } from '../../hooks/modal-forms/use-open-modal-form.hook';
import { useOpenConfirmModal } from '../../hooks/modal-forms/use-open-confirm-modal-form.hook';
import {
	ArchiveIcon,
	Download,
	ArchiveWhiteIcon,
	ArchiveRedIcon,
	Search,
} from '../../../_core/constants/icons.constants';
import { boxHeaderStyles, chipDeleteIconStyles } from '../../../_core/styles';
import CoreCalendar from '../../../_core/components/_ui/core-date-picker/core-calendar/core-calendar.component';
import { ChipCloseIcon, DeleteIcon } from '../../../_core/components/icons';
import { CoreModal } from '../../../_core/components/_ui/core-modal';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { EngagementImportModal } from '../../components/modal-import-form/modal-import-form.component';
import { containerStyles } from '../../../_core/components/_ui/core-modal/core-modal.styles';
import { ProfileRoutes } from '../../../profile/constants';
import { getLocalStorageDistrictId } from '../../../_core/utils';
import { useSchoolFilter } from '../../../_core/hooks/use-school-filter';
import { CoreCheckbox } from '../../../_core/components/_ui/core-checkbox';
import { CoreOverflowTypographyTooltip } from '../../../_core/components/_ui/core-overflow-tooltip';

export type EngagementsListPageProps = {
	isAdminRoute: boolean;
	providersList?: ProviderDto[];
	familiesList?: FamilyDto[];
	childrenList?: ChildDto[];
	currentDistrictId?: string | null;
	fieldsListFromDistrict?: CustomFieldDto[];
	permission?: PermissionDto;
	fieldsList?: CustomFieldDto[];
};

export const EngagementsListPage: FC<EngagementsListPageProps> = (props) => {
	const {
		currentDistrictId,
		isAdminRoute,
		permission,
		providersList,
		familiesList,
		fieldsListFromDistrict,
		childrenList,
		fieldsList,
	} = props;

	const { enqueueSnackbar } = useSnackbar();

	const [t] = useTranslation();
	const navigate = useNavigate();
	const isFirstRender = useRef(true);

	const { register, search } = useSearch();
	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const { register: registerLang, getValues: getValuesLang, control: controlLang, languages } = useLanguageFilter();
	const { name: nameDuration, control: controlDuration, transformDuration } = useDurationFilter();
	const [startDate, setStartDate] = useState<Date | null>(
		transformDuration?.start ? new Date(transformDuration.start) : null
	);
	const [endDate, setEndDate] = useState<Date | null>(transformDuration?.end ? new Date(transformDuration.end) : null);

	const {
		register: registerProvider,
		getValues: getValuesProvider,
		control: controlProvider,
		providerIds,
		providers
	} = useProviderFilter();

	const schoolField = isAdminRoute
		? fieldsList?.filter((field) => field.name === 'School')
		: fieldsListFromDistrict?.find((field) => field.name === 'School');

	const schoolOptions = Array.isArray(schoolField)
		? schoolField.flatMap((field) => field.options || [])
		: schoolField?.options || [];
	const { register: registerSchool, getValues: getValuesSchool, control: controlSchool, schools } = useSchoolFilter();

	const [showArchived, setShowArchived] = useState(false);

	const { requestObject, meta, page, handleResetPage, itemsList, fetchMore } = usePaginatedData<EngagementDto>(
		useGetEngagementsListQuery,
		{
			search,
			...sortingParams,
			languages: languages.join(','),
			providers: providerIds.join(','),
			district: currentDistrictId,
			archived: showArchived,
			start: startDate?.toISOString(),
			end: endDate?.toISOString(),
			schools: schools.join(','),
		},
		{ refetchOnMountOrArgChange: true, keepPreviousData: true }
	);
	const { refetch, isFetching, isLoading } = requestObject;

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}
		handleResetPage();
	}, [sortingArray, search, languages, providers, schools, transformDuration, handleResetPage]);

	const handlePagination = () => {
		handleResetPage();

		if (page === 1) {
			refetch();
		}
	};

	const [generateEngagementsCustomFieldFileUrl] = useGenerateEngagementsCustomFieldFileUrlMutation();

	const [createEngagement] = useCreateEngagementMutation();
	const featchActionCreate = async (data: any) => {
		await createEngagement(data).unwrap();
	};
	const handleOpenModalCreate = useOpenModalForm({
		featchAction: featchActionCreate,
		handlePagination,
		isCreate: true,
		districtId: currentDistrictId,
		submitButtonName: t('engagements.forms.buttons.create'),
		toastText: 'Engagement record was successfully created',
		fieldsListFromDistrict,
		providersList,
		familiesList,
		childrenList,
		generateFileUrlFunction: generateEngagementsCustomFieldFileUrl,
	});

	const [updateEngagement] = useUpdateEngagementMutation();
	const featchActionEdit = async (data: any) => {
		await updateEngagement({ id: data?._id?.toString(), data }).unwrap();
	};
	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		handlePagination,
		isCreate: false,
		districtId: currentDistrictId,
		submitButtonName: t('engagements.forms.buttons.save'),
		toastText: 'Engagement record was successfully updated',
		fieldsListFromDistrict,
		providersList,
		familiesList,
		childrenList,
		generateFileUrlFunction: generateEngagementsCustomFieldFileUrl,
	});

	const [deleteEngagement] = useDeleteEngagementMutation();
	const featchActionDelete = async (data: EngagementDto) => {
		await deleteEngagement({ id: data._id }).unwrap();
	};
	const handleOpenDeleteConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDelete,
		handlePagination,
		title: t('engagements.forms.delete-title'),
		description: t('engagements.forms.delete-description'),
		icon: <DeleteIcon sx={{ fontSize: 40 }} />,
		danger: true,
		submitButtonName: t('engagements.forms.buttons.delete'),
		toastText: 'Engagement record was successfully deleted',
	});

	const handleOnView = (engagement: EngagementDto) => {
		if (isAdminRoute) {
			navigate(generatePath(ProfileRoutes.EngagementDetails, { engagementId: engagement._id }));
		} else {
			navigate(generatePath(DistrictRoutes.Engagements.Details, { engagementId: engagement._id }));
		}
	};

	const [archiveEngagement] = useArchiveEngagementMutation();

	const fetchActionArchive = async (data: EngagementDto) => {
		await archiveEngagement({ id: data._id }).unwrap();
	};

	const [unarchiveEngagement] = useUnarchiveEngagementMutation();

	const fetchActionUnarchive = async (data: EngagementDto) => {
		await unarchiveEngagement({ id: data._id }).unwrap();
	};

	const handleArchiveConfirmModal = useOpenConfirmModal({
		featchAction: fetchActionArchive,
		handlePagination,
		title: t('engagements.forms.archive-title'),
		description: t('engagements.forms.archive-description'),
		icon: <ArchiveRedIcon />,
		danger: true,
		submitButtonName: t('engagements.forms.buttons.archive'),
		toastText: 'Engagement record was successfully archived.',
	});

	const handleUnarchiveConfirmModal = useOpenConfirmModal({
		featchAction: fetchActionUnarchive,
		handlePagination,
		title: t('engagements.forms.unarchive-title'),
		description: t('engagements.forms.unarchive-description'),
		icon: <ArchiveRedIcon />,
		danger: true,
		submitButtonName: t('engagements.forms.buttons.unarchive'),
		toastText: 'Engagement record was successfully unarchived.',
	});

	const handleOnSendEmail = () => {
		console.log('onSendEmail');
	};
	const handleOnSendSMS = () => {
		console.log('onSendSMS');
	};

	const [exportEngagementsToCSV] = useExportEngagementsToCSVMutation();

	const handleExport = async () => {
		try {
			const response = await exportEngagementsToCSV({
				search,
				...sortingParams,
				languages: languages.join(','),
				providers: providerIds.join(','),
				district: currentDistrictId,
				...transformDuration,
				archived: showArchived,
			}).unwrap();
			const fileUrl = response.fileUrl;

			enqueueSnackbar(t('engagements.export'), {
				variant: 'info',
				autoHideDuration: 3000,
			});

			const link = document.createElement('a');
			link.href = fileUrl;

			document.body.appendChild(link);

			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.error('Failed to export CSV:', error);
		}
	};

	const handleToggleArchived = () => {
		setShowArchived((prev) => !prev);
		handleResetPage();
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);

	const [importEngagements] = useImportEngagementsMutation();

	const { openModal, closeModal } = useMuiModal(EngagementImportModal);

	const handleOpenModal = () => {
		openModal({
			submitAction: async (formData: FormData) => {
				enqueueSnackbar(t('engagements.import-start'), {
					variant: 'info',
					autoHideDuration: 3000,
				});
				closeModal();
				try {
					const districtId = getLocalStorageDistrictId() || undefined;

					const result = await importEngagements({ formData, districtId }).unwrap();

					const { success, failed } = result;

					const successCount = success.length;
					const failureCount = failed.length;
					const total = successCount + failureCount;

					if (successCount > 0) {
						handlePagination();
					}

					const message = (
						<>
							<Typography variant="body1" fontWeight="bold">
								{successCount} of {total} engagements were successfully imported.
							</Typography>
							{failureCount > 0 && (
								<Box mt={2}>
									<Typography variant="body1" fontWeight="bold">
										{t('engagements.import-engagements')}
									</Typography>
									{failed.map((f: any, index: number) => (
										<Box key={index} mt={1}>
											<Typography variant="body2">- Engagement {f.row['Title']}</Typography>
											{f.error && (
												<Box ml={2}>
													<Typography variant="body2" color="error">
														Reasons:
													</Typography>

													{f.error.split(';').map((reason: string, idx: number) => (
														<MenuItem key={idx}>
															<Typography variant="body2" color="error">
																{reason.trim()}
															</Typography>
														</MenuItem>
													))}
												</Box>
											)}
										</Box>
									))}
								</Box>
							)}
						</>
					);

					setModalContent(message);
					setModalOpen(true);
				} catch (error) {
					console.error('File Import Error:', error);
					setModalContent(
						<Typography variant="body1" color="error">
							{t('engagements.import-error')}
						</Typography>
					);
					setModalOpen(true);
				}
			},
			closeModal,
			formTitle: 'Import Engagements',
			submitButtonName: 'Import',
		});
	};

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box sx={boxHeaderStyles}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap',
						gap: 1,
					}}
				>
					<CoreTextField
						{...register('search')}
						placeholder={t('common.search')}
						hasEndAdornment
						endAdornment={
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						}
						sx={{ minWidth: '140px', maxWidth: '190px' }}
					/>
						<CoreAutoComplete
							multiple={true}
							{...registerLang('languages')}
							control={controlLang}
							controlSx={{
								minWidth: '180px',
								maxWidth: '240px',
							}}
							isFilter
							options={Object.values(LanguageEnum)}
							getOptionLabel={(option) => {
								const language = getObjectLanguage(option);

								return language ? language.name : '';
							}}
							selectOnFocus={true}
							isOptionEqualToValue={(option, value) => option === value}
							renderInput={(params) => (
								<AutocompleteTextField
									{...params}
									placeholder={!getValuesLang('languages')?.length ? t('common.filter-lang') : ''}
									sx={{
										'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
											padding: '4.5px 4px 5.5px 5px',
											minWidth: 0,
										},
										'& .MuiInputBase-input': {
											height: '100%',
											padding: '0',
										},
									}}
								/>
							)}
							renderOption={(props, option, { selected }) => {
								const language = getObjectLanguage(option);

								return (
									<MenuItem
										{...props}
										key={language?.value}
										sx={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<CoreCheckbox checked={selected} sx={{ mr: 1, p: 0, ml: 0 }} />
										<CoreOverflowTypographyTooltip>{language?.name}</CoreOverflowTypographyTooltip>
									</MenuItem>
								);
							}}
							renderTags={(value, getTagProps) => {
								const count = value.length - 1;

								return [
									value[0] && (
										<Chip
											sx={chipDeleteIconStyles}
											{...getTagProps({ index: 0 })}
											label={getObjectLanguage(value[0])?.name}
											deleteIcon={<ChipCloseIcon />}
										/>
									),
									count > 0 && (
										<Chip
											sx={chipDeleteIconStyles}
											{...getTagProps({ index: count })}
											label={`+${count}`}
											deleteIcon={<ChipCloseIcon />}
										/>
									),
								];
							}}
						/>
						<CoreAutoComplete
							multiple={true}
							{...registerProvider('providers')}
							control={controlProvider}
							controlSx={{ minWidth: '170px', maxWidth: '240px' }}
							isFilter
							options={providersList ?? []}
							getOptionLabel={(option) => option.fullName || ''}
							selectOnFocus={true}
							isOptionEqualToValue={(option, value) => option._id === value._id}
							renderInput={(params) => (
								<AutocompleteTextField
									{...params}
									placeholder={!getValuesProvider('providers')?.length ? t('common.filter-provider') : ''}
									sx={{
										'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
											padding: '4.5px 4px 5.5px 5px',
											minWidth: 0,
										},
										'& .MuiInputBase-input': {
											height: '100%',
											padding: '0',
										},
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: (theme) => theme.colors.blue,
											},
										},
									}}
								/>
							)}
							renderOption={(props, option, { selected }) => (
								<MenuItem
									{...props}
									key={option._id}
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<CoreCheckbox checked={selected} sx={{ mr: 2, p: 0, ml: 0 }} />

									<Avatar
										src={option.imageUrl}
										alt={option.fullName}
										style={{ width: 24, height: 24, marginRight: 18, borderRadius: '6px' }}
									/>
									<CoreOverflowTypographyTooltip>{option.fullName}</CoreOverflowTypographyTooltip>
								</MenuItem>
							)}
							renderTags={(value, getTagProps) => {
								const count = value.length - 1;

								return [
									value[0] && (
										<Chip
											sx={chipDeleteIconStyles}
											{...getTagProps({ index: 0 })}
											label={value[0]?.fullName}
											deleteIcon={<ChipCloseIcon />}
										/>
									),
									count > 0 && (
										<Chip
											sx={chipDeleteIconStyles}
											{...getTagProps({ index: count })}
											label={`+${count}`}
											deleteIcon={<ChipCloseIcon />}
										/>
									),
								];
							}}
						/>
						<CoreAutoComplete
							multiple={true}
							{...registerSchool('schools')}
							control={controlSchool}
							controlSx={{
								minWidth: '160px',
								maxWidth: '240px',
							}}
							isFilter
							options={schoolOptions.map((s) => s._id)}
							getOptionLabel={(option) => {
								const school = schoolOptions.find((s) => s._id === option);

								return school ? school.text : '';
							}}
							selectOnFocus={true}
							isOptionEqualToValue={(option, value) => option === value}
							renderInput={(params) => (
								<AutocompleteTextField
									{...params}
									placeholder={!getValuesSchool('schools')?.length ? t('common.filter-school') : ''}
									sx={{
										'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
											padding: '4.5px 4px 5.5px 5px',
											minWidth: 0,
										},
									}}
								/>
							)}
							renderOption={(props, option, { selected }) => {
								const school = schoolOptions.find((s) => s._id === option);

								return (
									<MenuItem {...props} key={school?._id}>
										<CoreCheckbox checked={selected} sx={{ mr: 2, p: 0, ml: 0 }} />
										<CoreOverflowTypographyTooltip>{school?.text}</CoreOverflowTypographyTooltip>
									</MenuItem>
								);
							}}
							renderTags={(value, getTagProps) => {
								const count = value.length - 1;

								return [
									value[0] && (
										<Chip
											sx={chipDeleteIconStyles}
											{...getTagProps({ index: 0 })}
											label={schoolOptions.find((s) => s._id === value[0])?.text}
											deleteIcon={<ChipCloseIcon />}
										/>
									),
									count > 0 && (
										<Chip
											sx={chipDeleteIconStyles}
											{...getTagProps({ index: count })}
											label={`+${count}`}
											deleteIcon={<ChipCloseIcon />}
										/>
									),
								];
							}}
						/>
					<Box sx={{ minWidth: '200px', maxWidth: '270px', maxHeight: '48px' }}>
						<Controller
							name={nameDuration}
							control={controlDuration}
							render={({ field }) => (
								<CoreCalendar
									{...field}
									selectsRange
									inline={false}
									startDate={startDate}
									endDate={endDate}
									onChange={(dates: [Date | null, Date | null]) => {
										const [start, end] = dates;
										setStartDate(start);
										setEndDate(end);
										field.onChange(dates);
									}}
									onKeyDown={(e) => {
										e.preventDefault();
									}}
									onClear={() => {
										setStartDate(null);
										setEndDate(null);
										field.onChange([null, null]);
									}}
								/>
							)}
						/>
					</Box>
				</Box>
				<Box sx={{ marginLeft: 'auto', display: 'flex', gap: 1 }}>
					<CoreButton variant="base" circle size="large" onClick={handleExport}>
						<Download />
					</CoreButton>

					{permission?.create ? (
						<>
							<>
								<CoreButton
									sx={{ marginLeft: 'auto', display: 'flex', gap: 1 }}
									variant="base"
									circle
									size="large"
									disabled={showArchived}
									onClick={handleOpenModal}
								>
									<FileUploadOutlinedIcon color="primary" />
								</CoreButton>
								<CoreModal
									open={modalOpen}
									containerSx={containerStyles}
									bodySx={{ maxHeight: 400, overflowY: 'auto' }}
									onClose={() => {
										setModalOpen(false);
									}}
									hideCloseIcon={false}
								>
									{modalContent}
									<Box mt={3} display="flex" justifyContent="flex-end">
										<CoreButton
											onClick={() => {
												setModalOpen(false);
											}}
										>
											OK
										</CoreButton>
									</Box>
								</CoreModal>
							</>
							<CoreButton
								circle
								size="large"
								onClick={() => {
									handleOpenModalCreate();
								}}
								sx={{
									marginLeft: 'auto',
									bgcolor: (theme) => theme.colors.orangeDeep,
									color: 'white',
								}}
								disabled={showArchived}
							>
								<Add />
							</CoreButton>
						</>
					) : null}
					<Divider orientation="vertical" flexItem />
					{showArchived ? (
						<CoreButton
							sx={{
								bgcolor: (theme) => theme.colors.lightBlue,
								'&:hover': {
									bgcolor: (theme) => theme.colors.lightBlue,
								},
							}}
							variant="base"
							circle
							size="large"
							onClick={handleToggleArchived}
						>
							<ArchiveWhiteIcon />
						</CoreButton>
					) : (
						<CoreButton variant="base" circle size="large" onClick={handleToggleArchived}>
							<ArchiveIcon />
						</CoreButton>
					)}
				</Box>
			</Box>
			<EngagementsTable
				data={itemsList}
				loading={isFetching || isLoading}
				hasMore={meta?.hasNextPage}
				permission={permission}
				onDelete={handleOpenDeleteConfirmModal}
				onEdit={handleOpenModalEdit}
				onView={handleOnView}
				onArchive={handleArchiveConfirmModal}
				onUnarchive={handleUnarchiveConfirmModal}
				onSendEmail={handleOnSendEmail}
				onSendSMS={handleOnSendSMS}
				sorting={sortingArray}
				onSortingChange={handleSortingChange}
				fetchMore={fetchMore}
			/>
		</Container>
	);
};
