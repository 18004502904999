import { Box, Typography } from '@mui/material';

export const ErrorBoundaryComponent = () => (
	<Box
		sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			paddingTop: '15%',
		}}
	>
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Typography variant="h1" sx={{ fontSize: '24px', pb: 2 }}>
				Oops! Something went wrong.
			</Typography>
			<Typography variant="h2" sx={{ fontSize: '16px' }}>
				Please report this issue to admin.
			</Typography>
		</Box>
	</Box>
);
