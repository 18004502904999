import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const OutsourceIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon {...props}>
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				id="Vector"
				d="M1.5764 5.6C1.45891 6.05738 1.39964 6.52777 1.4 7C1.4 7.483 1.4616 7.952 1.5764 8.4H3.57C3.47563 7.46905 3.47563 6.53095 3.57 5.6H1.5764ZM2.149 4.2H3.7912C3.9774 3.3481 4.2462 2.5851 4.5759 1.9502C3.55963 2.43979 2.71405 3.22366 2.149 4.2ZM12.4236 5.6H10.43C10.5244 6.53095 10.5244 7.46905 10.43 8.4H12.4236C12.6593 7.48154 12.6593 6.51846 12.4236 5.6ZM11.851 4.2C11.2859 3.22366 10.4404 2.43979 9.4241 1.9502C9.7545 2.5851 10.0226 3.3481 10.2088 4.2H11.851ZM4.9784 5.6C4.92595 6.06482 4.89978 6.53223 4.9 7C4.9 7.4795 4.9266 7.9485 4.9784 8.4H9.0216C9.12702 7.46964 9.12702 6.53036 9.0216 5.6H4.9784ZM5.229 4.2H8.771C8.64361 3.67381 8.45985 3.16289 8.2229 2.6761C7.7833 1.7976 7.3129 1.4 7 1.4C6.6864 1.4 6.2167 1.7976 5.7771 2.6761C5.5566 3.1185 5.3711 3.633 5.229 4.2ZM2.149 9.8C2.71405 10.7763 3.55963 11.5602 4.5759 12.0498C4.2455 11.4149 3.9774 10.6519 3.7912 9.8H2.149ZM11.851 9.8H10.2088C10.0226 10.6519 9.7538 11.4149 9.4241 12.0498C10.4404 11.5602 11.2859 10.7763 11.851 9.8ZM5.229 9.8C5.3711 10.367 5.5566 10.8815 5.7771 11.3239C6.2167 12.2024 6.6871 12.6 7 12.6C7.3136 12.6 7.7833 12.2024 8.2229 11.3239C8.4434 10.8815 8.6289 10.367 8.771 9.8H5.229ZM7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14Z"
				fill="#F1572D"
			/>
		</svg>
	</SvgIcon>
);
