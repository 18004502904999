import { CustomFieldDto } from '@families-link/shared';

export interface DefaultFormFieldsInterface {
	title?: CustomFieldDto;
	type?: CustomFieldDto;
	school?: CustomFieldDto;
	program?: CustomFieldDto;
	duration?: CustomFieldDto;
	description?: CustomFieldDto;
	service?: CustomFieldDto;
}

export const getDefaultFormFields = (fieldsList: CustomFieldDto[]) => {
	const defaultFormFields = {
		title: fieldsList?.find((el: CustomFieldDto) => el.name === 'Title'),
		type: fieldsList?.find((el: CustomFieldDto) => el.name === 'Type'),
		school: fieldsList?.find((el: CustomFieldDto) => el.name === 'School'),
		program: fieldsList?.find((el: CustomFieldDto) => el.name === 'Program'),
		duration: fieldsList?.find((el: CustomFieldDto) => el.name === 'Duration'),
		description: fieldsList?.find((el: CustomFieldDto) => el.name === 'Description'),
		service: fieldsList?.find((el: CustomFieldDto) => el.name === 'Service'),
	};

	return defaultFormFields;
};
