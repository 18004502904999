import { ChartColorsArray, LanguageDistributionDto } from '@families-link/shared';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';

interface LabelProps {
	cx: number;
	cy: number;
	midAngle: number;
	innerRadius: number;
	outerRadius: number;
	percent: number;
	index: number;
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: LabelProps) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

const renderCustomLegend = (props: any) => {
	const { payload } = props;

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
			{payload.map((entry: any, index: number) => (
				<Box key={`item-${index}`} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					<Box
						sx={{
							width: 12,
							height: 12,
							borderRadius: '50%',
							backgroundColor: entry.color,
						}}
					/>
					<Typography sx={{ color: (theme) => theme.colors.grayLightBlue, fontSize: '14px', fontWeight: 400 }}>
						{entry.value}
					</Typography>
				</Box>
			))}
		</Box>
	);
};

export const LanguagePieChart = ({ data }: { data: LanguageDistributionDto[] }) => {
	const [t] = useTranslation();

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Typography sx={{ color: (theme) => theme.colors.grayLightBlue, fontSize: '16px', fontWeight: 500 }}>
				Languages Spoken at Home
			</Typography>
			<ResponsiveContainer width="100%" height={280}>
				<PieChart>
					<Pie
						data={data}
						dataKey="count"
						nameKey="language"
						cx="50%"
						cy="50%"
						outerRadius={100}
						labelLine={false}
						label={renderCustomizedLabel}
					>
						{data.map((_, index) => (
							<Cell key={`cell-${index}`} fill={ChartColorsArray[index % ChartColorsArray.length]} />
						))}
					</Pie>
					<Tooltip />
					<Legend align="right" verticalAlign="middle" layout="vertical" content={renderCustomLegend} />
				</PieChart>
			</ResponsiveContainer>
		</Box>
	);
};
