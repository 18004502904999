import { RouteObject } from 'react-router-dom';
import { DistrictRoutes } from './constants';
import { FamiliesListDistrictLevelPage } from '../family/pages/family-list/family-list-district-level.page';
import { FamilyDetailsPage } from '../family/pages/family-details/family-details.page';
import { ProvidersListPage } from '../providers/pages/providers-level-district/providers-list.page';
import { CustomRequirementsListPage } from '../custom-requirements/pages/custom-requirements-list.page';
import { UsersListPage } from '../user-managment/pages/users-list/users-list.page';
import { ProvidersDetailsPage } from '../providers/pages/providers-details/providers-details.page';
import { EngagementsListDistrictLevelPage } from '../engagement/pages/engagements-list/engagements-list-district-level.page';
import { EngagementDetailsPage } from '../engagement/pages/engagements-details/engagements-details.page';
import { SurveyPreviewPage } from '../survey/pages/survey-view/survey-preview.page';
import { SurveyTemplateActionPage } from '../survey/pages/survey-template-actions/survey-template-action.page';
import { SurveyTemplateListDistrictLevelPage } from '../survey/pages/survey-template-list/survey-list-district-level.page';
import { DashboardDistrictLevelPage } from '../dashboard/pages/dasboard-district-level.page';

export const DistrictAdminModuleRouter: RouteObject[] = [
	{
		path: DistrictRoutes.CustomRequirements,
		element: <CustomRequirementsListPage />,
		handle: { title: 'headers.custom-requirements' },
	},
	{ path: DistrictRoutes.UserManagment, element: <UsersListPage />, handle: { title: 'headers.users' } },
];

export const DistrictModuleRouter: RouteObject[] = [
	{
		path: DistrictRoutes.Dashboard,
		element: <DashboardDistrictLevelPage />,
		handle: { title: 'nav.dashboard' },
	},
	{
		path: DistrictRoutes.Families.Root,
		element: <FamiliesListDistrictLevelPage />,
		handle: { title: 'headers.families' },
	},
	{
		path: DistrictRoutes.Families.Details,
		element: <FamilyDetailsPage />,
		handle: {
			title: 'headers.family-details',
			needsPaper: false,
			to: { path: DistrictRoutes.Families.Root },
		},
	},
	{
		path: DistrictRoutes.Engagements.Root,
		element: <EngagementsListDistrictLevelPage />,
		handle: { title: 'headers.engagements' },
	},

	{ path: DistrictRoutes.Providers.Root, element: <ProvidersListPage />, handle: { title: 'headers.providers' } },
	{
		path: DistrictRoutes.Providers.Details,
		element: <ProvidersDetailsPage />,
		handle: {
			title: 'headers.providers-details',
			needsPaper: false,
			to: { path: DistrictRoutes.Providers.Root },
		},
	},

	{
		path: DistrictRoutes.Engagements.Details,
		element: <EngagementDetailsPage />,
		handle: {
			title: 'headers.engagement-details',
			needsPaper: false,
			to: { path: DistrictRoutes.Engagements.Root },
		},
	},
	{
		path: DistrictRoutes.SurveyTemplates.Root,
		element: <SurveyTemplateListDistrictLevelPage />,
		handle: { title: 'headers.survey-templates' },
	},
	{
		path: DistrictRoutes.SurveyTemplates.Creating,
		element: <SurveyTemplateActionPage />,
		handle: {
			title: 'headers.survey-creating',
			to: { path: DistrictRoutes.SurveyTemplates.Root },
		},
	},
	{
		path: DistrictRoutes.SurveyTemplates.Editing,
		element: <SurveyTemplateActionPage />,
		handle: {
			title: 'headers.survey-editing',
			to: { path: DistrictRoutes.SurveyTemplates.Root },
		},
	},
	{
		path: DistrictRoutes.SurveyTemplates.Details,
		element: <SurveyPreviewPage />,
		handle: {
			title: 'headers.survey',
			needsDrawer: false,
			to: { path: DistrictRoutes.SurveyTemplates.Root },
		},
	},
];
