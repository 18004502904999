export const otpResendTimeout = '30sec';
export const ALLOWED_EXTENSIONS = ['jpg', 'jpeg', 'png'];
export const MAX_IMAGE_FILE_SIZE = 10 * 1024 * 1024; // 10MB
export const MAX_FILE_NUMBER = 50;
export const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB
export const ALLOWED_IMAGE_MIME_TYPES = ['image/jpeg', 'image/png'];
export const ALLOWED_FILE_MIME_TYPES = [
	'text/plain',
	'application/pdf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'text/csv',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'image/jpeg',
	'image/png',
	'image/gif',
	'image/webp',
	'image/svg',
	'video/mp4',
	'video/mkv',
	'video/webm',
	'video/quicktime',
];
export * from './sms-message-body-template.constants';
export * from './socket.constants';
export * from './email-subject.constants';
