import { ProviderDto, OrderEnum, ProviderInfoDto, DistrictFilter } from '@families-link/shared';
import api from '.';

const providersApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getAllProviders: builder.query<
			ProviderDto[],
			{
				languages?: string;
				sortBy?: string;
				sortOrder?: OrderEnum.ASC | OrderEnum.DESC;
				searchQuery?: string;
				districts?: string;
			}
		>({
			query: ({ languages, sortBy, sortOrder, searchQuery, districts }) => ({
				url: 'providers',
				params: { languages, sortBy, sortOrder, searchQuery, districts },
			}),
			providesTags: ['Provider', 'Engagement'],
		}),

		getAllProvidersByDistrictId: builder.query<
			ProviderDto[],
			{
				languages?: string;
				districtId?: string | null;
				sortBy?: string;
				sortOrder?: OrderEnum.ASC | OrderEnum.DESC;
				searchQuery?: string;
			}
		>({
			query: ({ languages, districtId, sortBy, sortOrder, searchQuery }) => ({
				url: 'providers/by/district',
				params: { languages, districtId, sortBy, sortOrder, searchQuery },
			}),
			providesTags: ['Provider', 'Engagement'],
		}),

		generateProviderPhotoUrl: builder.mutation<{ url: string }, FormData>({
			query: (data) => ({
				url: `providers/photo-url`,
				method: 'POST',
				body: data,
			}),
		}),

		createProvider: builder.mutation<ProviderDto, ProviderInfoDto>({
			query: (formData) => ({
				url: 'providers',
				method: 'POST',
				body: formData,
			}),
			invalidatesTags: ['Provider'],
		}),

		updateProvider: builder.mutation<ProviderInfoDto, { id: string; updateData: ProviderDto }>({
			query: ({ id, updateData }) => ({
				url: `providers/${id}`,
				method: 'PUT',
				body: updateData,
			}),
			invalidatesTags: ['Provider'],
		}),

		deleteProvider: builder.mutation<void, string>({
			query: (id) => ({
				url: `providers/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Provider'],
		}),

		getProviderById: builder.query<ProviderDto, string | null | undefined>({
			query: (id) => ({
				url: `providers/${id}`,
				method: 'GET',
			}),
			providesTags: ['Provider'],
		}),
		importProviders: builder.mutation<{ success: []; failed: [] }, { formData: FormData; districtId?: string }>({
			query: ({ formData, districtId }) => ({
				url: `import/providers${districtId ? `?districtId=${districtId}` : ''}`,
				method: 'POST',
				body: formData,
			}),
			invalidatesTags: ['Provider'],
		}),
		getProviderTemplateUrl: builder.query<{ url: string }, void>({
			query: () => ({
				url: 'import/providers/template-url',
				method: 'GET',
			}),
		}),
	}),
	overrideExisting: false,
});

export default providersApi;

export const {
	useGetAllProvidersQuery,
	useGetAllProvidersByDistrictIdQuery,
	useCreateProviderMutation,
	useUpdateProviderMutation,
	useDeleteProviderMutation,
	useGetProviderByIdQuery,
	useImportProvidersMutation,
	useGetProviderTemplateUrlQuery,
	useGenerateProviderPhotoUrlMutation,
} = providersApi;
