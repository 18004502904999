import React, { memo, FC, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { containerStyles, wrapperStyle } from './core-file-item.styles';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import Replay from '@mui/icons-material/Replay';
import InsertPhotoOutlined from '@mui/icons-material/InsertPhotoOutlined';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import VideoFileOutlined from '@mui/icons-material/VideoFileOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import { getFileType } from '../../../utils';
import { CoreIconButton } from '../core-button/core-icon-button.component';
import { ErrorCode, FileError } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

interface CoreFileItemProps {
	id: string;
	index: number;
	error?: boolean;
	uploading?: boolean;
	completed?: boolean;
	name: string;
	type: string;
	errors?: FileError[];
	rejected?: boolean;
	progress?: number | null;
	onReload?(id: string): void;
	onCancel?(): void;
}

export const FileIcon = {
	image: InsertPhotoOutlined,
	document: DescriptionOutlined,
	video: VideoFileOutlined,
	unknown: FilePresentOutlinedIcon,
};

const CoreFileItem: FC<CoreFileItemProps> = (props) => {
	const { onCancel, name, type, id, index, rejected, error, errors, onReload, uploading, completed, progress } = props;
	const { t } = useTranslation();

	const Icon = FileIcon[getFileType(type)];

	const handleCancel = useCallback(() => {
		onCancel?.();
	}, [onCancel, id]);

	const handleReload = useCallback(() => {
		onReload?.(id);
	}, [onReload, id]);

	const errorCode = errors?.[0]?.code;

	const reason = useMemo(() => {
		switch (errorCode) {
			case ErrorCode.FileTooLarge:
				return t('dropzone.error.too-large');
			case ErrorCode.FileInvalidType:
				return t('dropzone.error.invalid-type');
			default:
				return t('dropzone.error.unknown');
		}
	}, [errorCode, t]);

	return (
		<Box sx={containerStyles}>
			<Box sx={wrapperStyle}>
				<Box
					component={Icon}
					sx={{
						minWidth: 16,
						mr: 1,
					}}
				/>
				{(error || completed) && (
					<Typography
						variant="label"
						noWrap
						sx={{
							color: ({ colors }) => (error ? colors.red : colors.black),
						}}
					>
						{rejected && t('dropzone.upload-error', { reason })}
						{name}
					</Typography>
				)}
				{/* {uploading && (
					<CoreLinearProgress
						variant={typeof progress === 'number'
							? 'determinate'
							: 'indeterminate'}
						sx={{ width: '100%', ml: '8px' }}
						value={progress ?? undefined}
					/>
				)} */}
			</Box>
			{error && !rejected && (
				<CoreIconButton onClick={handleReload} sx={{ color: (theme) => theme.colors.blue }}>
					<Replay />
				</CoreIconButton>
			)}
			<CoreIconButton onClick={handleCancel}>
				<CloseOutlined />
			</CoreIconButton>
		</Box>
	);
};

export default memo(CoreFileItem);
