import { RouteObject } from 'react-router-dom';
import { ProfilePage } from './pages/profile/profile.page';
import { ProfileSecurityPage } from './pages/security/security.page';

import { ProfileRoutes } from './constants';
import { ProvidersPage } from '../providers/pages/providers.page';
import { DistrictListPage } from '../district/pages/district-list/district-list.page';
import { FamiliesListAdminLevelPage } from '../family/pages/family-list/family-list-admin-level.page';
import { DistributionPage } from '../_core/pages/distribution/distribution.page';
import { EngagementsListAdminLevelPage } from '../engagement/pages/engagements-list/engagement-list-admin-level.page';
import { FamilyDetailsPage } from '../family/pages/family-details/family-details.page';
import { ProvidersDetailsPage } from '../providers/pages/providers-details/providers-details.page';
import { EngagementDetailsPage } from '../engagement/pages/engagements-details/engagements-details.page';
import { SurveyTemplateListAdminLevelPage } from '../survey/pages/survey-template-list/survey-list-admin-level.page';
import { SurveyTemplateActionPage } from '../survey/pages/survey-template-actions/survey-template-action.page';
import { DashboardAdminLevelPage } from '../dashboard/pages/dashboard-admin-level.page';

export const ProfileSuperAdminModuleRouter: RouteObject[] = [
	{ path: ProfileRoutes.Root, element: <DashboardAdminLevelPage />, handle: { title: 'nav.dashboard' } },
	{ path: ProfileRoutes.Districts, element: <DistrictListPage />, handle: { title: 'nav.districts' } },
	{ path: ProfileRoutes.Engagements, element: <EngagementsListAdminLevelPage />, handle: { title: 'nav.engagements' } },
	{
		path: ProfileRoutes.EngagementDetails,
		element: <EngagementDetailsPage />,
		handle: {
			title: 'headers.engagement-details',
			needsPaper: false,
			to: { path: ProfileRoutes.Engagements },
		},
	},

	{ path: ProfileRoutes.Providers, element: <ProvidersPage />, handle: { title: 'nav.providers' } },
	{
		path: ProfileRoutes.ProviderDetails,
		element: <ProvidersDetailsPage />,
		handle: {
			title: 'headers.providers-details',
			needsPaper: false,
			to: { path: ProfileRoutes.Providers },
		},
	},

	{ path: ProfileRoutes.Families, element: <FamiliesListAdminLevelPage />, handle: { title: 'nav.families' } },
	{
		path: ProfileRoutes.FamilyDetails,
		element: <FamilyDetailsPage />,
		handle: { title: 'headers.family-details', needsPaper: false, to: { path: ProfileRoutes.Families } },
	},
	{
		path: ProfileRoutes.SurveyTemplates.Root,
		element: <SurveyTemplateListAdminLevelPage />,
		handle: { title: 'nav.survey' },
	},
	{
		path: ProfileRoutes.SurveyTemplates.Creating,
		element: <SurveyTemplateActionPage />,
		handle: { title: 'headers.survey-creating', to: { path: ProfileRoutes.SurveyTemplates.Root } },
	},
	{
		path: ProfileRoutes.SurveyTemplates.Editing,
		element: <SurveyTemplateActionPage />,
		handle: { title: 'headers.survey-editing', to: { path: ProfileRoutes.SurveyTemplates.Root } },
	},
];

export const ProfileModuleRouter: RouteObject[] = [
	{ path: ProfileRoutes.Distribute, element: <DistributionPage /> },
	{
		path: ProfileRoutes.Profile,
		element: <ProfilePage />,
		handle: { title: 'nav.profile', to: -1 },
	},
	{
		path: ProfileRoutes.SecuritySettings,
		element: <ProfileSecurityPage />,
		handle: { title: 'nav.profile', to: -1 },
	},
];
