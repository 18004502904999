import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const FillEditIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon {...props}>
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="32" height="32" rx="8" fill="currentColor" />
			<g clip-path="url(#clip0_455_13560)">
				<path
					d="M15.3333 10.6665H10.6666C10.313 10.6665 9.97382 10.807 9.72378 11.057C9.47373 11.3071 9.33325 11.6462 9.33325 11.9998V21.3332C9.33325 21.6868 9.47373 22.0259 9.72378 22.276C9.97382 22.526 10.313 22.6665 10.6666 22.6665H19.9999C20.3535 22.6665 20.6927 22.526 20.9427 22.276C21.1928 22.0259 21.3333 21.6868 21.3333 21.3332V16.6665"
					stroke="white"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M20.3333 9.66665C20.5985 9.40144 20.9582 9.25244 21.3333 9.25244C21.7083 9.25244 22.068 9.40144 22.3333 9.66665C22.5985 9.93187 22.7475 10.2916 22.7475 10.6667C22.7475 11.0417 22.5985 11.4014 22.3333 11.6667L15.9999 18L13.3333 18.6667L13.9999 16L20.3333 9.66665Z"
					stroke="white"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_455_13560">
					<rect width="16" height="16" fill="white" transform="translate(8 8)" />
				</clipPath>
			</defs>
		</svg>
		{/* <svg fill="none" strokeWidth={1.5} stroke="currentColor">
			<g transform="translate(3, 3) scale(1.1)">
				<path d="M3.79999 5.5H12.2V13.3C12.2 13.6183 12.0735 13.9235 11.8484 14.1485C11.6234 14.3735 11.3182 14.5 11 14.5H4.99999C4.68173 14.5 4.37645 14.3735 4.1514 14.1485C3.92636 13.9235 3.79999 13.6183 3.79999 13.3V5.5Z" />
				<path d="M2 5.5H4.18994C4.42856 5.49951 4.66165 5.42793 4.85937 5.29433C5.05709 5.16074 5.21051 4.97121 5.3 4.75L5.9 3.25601C5.98854 3.03368 6.14151 2.84287 6.3393 2.70815C6.5371 2.57344 6.7706 2.50097 7.00991 2.5H8.98994C9.22925 2.50097 9.46275 2.57344 9.66054 2.70815C9.85834 2.84287 10.0114 3.03368 10.1 3.25601L10.7 4.75C10.7899 4.97215 10.9441 5.16235 11.143 5.29602C11.3419 5.42969 11.5763 5.50071 11.8159 5.5H14" />
			</g>
		</svg> */}
	</SvgIcon>
);
