import {
	EngagementDto,
	PageDto,
	SearchFilter,
	PageOptionsDto,
	LanguageFilter,
	ProvidersFilter,
	ON_SURVEY_TRACKING_EVENT,
	ON_SURVEY_EVENT,
	ON_SURVEY_ANSWER_EVENT,
	JOIN_SURVEY_EVENTS_ROOM,
	EngagementPopulatedField,
	LanguageDistributionDto,
	BarChartDto,
	DistrictFilter,
	LanguagePerProvidersDto,
} from '@families-link/shared';
import api from '.';
import { configService } from '../../modules/_core';
import { io } from 'socket.io-client';
import { RootState } from '..';
import { getLocalStorageDistrictId } from '../../modules/_core/utils';

const engagementsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getEngagementsList: builder.query<
			PageDto<EngagementDto>,
			PageOptionsDto &
				SearchFilter &
				LanguageFilter &
				ProvidersFilter & {
					district?: string | null;
					start?: string | null;
					end?: string | null;
					archived?: boolean;
					schools?: string;
				}
		>({
			query: ({
				page,
				take,
				orderField,
				order,
				search,
				languages,
				providers,
				district,
				start,
				end,
				archived,
				schools,
			}) => ({
				url: `engagements`,
				params: {
					page,
					take,
					orderField,
					order,
					search,
					languages,
					providers,
					district,
					start,
					end,
					archived,
					schools,
				},
			}),
			providesTags: ['Engagement', 'Survey', 'SurveyAnswer'],
			async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState, dispatch }) {
				const state = getState() as RootState;
				const token = state.session.access?.token;

				if (!token) {
					console.error('No access token found');

					return;
				}

				const socket = io(configService.get('NX_PUBLIC_APP_API_URL'), {
					auth: { token },
					transports: ['websocket', 'polling'],
				});

				socket.on('connect', () => {
					console.log('WebSocket connected!');
				});

				const districtId = getLocalStorageDistrictId();

				socket.emit(JOIN_SURVEY_EVENTS_ROOM, { districtId });

				const eventListner = (data: any) => {
					dispatch(engagementsApi.util.invalidateTags([{ type: 'Survey' }]));
				};

				socket.on(ON_SURVEY_EVENT, eventListner);
				socket.on(ON_SURVEY_TRACKING_EVENT, eventListner);
				socket.on(ON_SURVEY_ANSWER_EVENT, eventListner);

				try {
					await cacheEntryRemoved;
				} finally {
					socket.off(ON_SURVEY_EVENT, eventListner);
					socket.off(ON_SURVEY_TRACKING_EVENT, eventListner);
					socket.off(ON_SURVEY_ANSWER_EVENT, eventListner);
					socket.disconnect();
				}
			},
		}),
		getEngagement: builder.query<
			EngagementDto,
			{ id?: string | null; populatedFields?: Array<EngagementPopulatedField> }
		>({
			query: ({ id, populatedFields }) => ({
				url: `engagements/${id}`,
				params: { populatedFields },
			}),
			providesTags: ['Engagement'],
		}),
		generateEngagementsCustomFieldFileUrl: builder.mutation<{ url: string }, FormData>({
			query: (data) => ({
				url: `engagements/file-url`,
				method: 'POST',
				body: data,
			}),
		}),
		createEngagement: builder.mutation<EngagementDto, any>({
			query: (data) => ({
				url: `engagements`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['Engagement'],
		}),
		updateEngagement: builder.mutation<EngagementDto, { id?: string; data: any }>({
			query: ({ id, data }) => ({
				url: `engagements/${id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Engagement', 'Survey', 'SurveyAnswer'],
		}),
		deleteEngagement: builder.mutation<EngagementDto, { id: string }>({
			query: ({ id }) => ({
				url: `engagements/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Engagement'],
		}),
		archiveEngagement: builder.mutation<EngagementDto, { id: string }>({
			query: ({ id }) => ({
				url: `engagements/${id}/archive`,
				method: 'PUT',
			}),
			invalidatesTags: ['Engagement'],
		}),
		unarchiveEngagement: builder.mutation<EngagementDto, { id: string }>({
			query: ({ id }) => ({
				url: `engagements/${id}/unarchive`,
				method: 'PUT',
			}),
			invalidatesTags: ['Engagement'],
		}),
		getEngagementsByFamilyId: builder.query<
			PageDto<EngagementDto>,
			PageOptionsDto & {
				familyId?: string;
			}
		>({
			query: ({ familyId, page, take, orderField, order }) => ({
				url: `engagements/family/${familyId}`,
				params: {
					page,
					take,
					orderField,
					order,
				},
			}),
			providesTags: ['Engagement'],
		}),
		getEngagementsByProviderId: builder.query<
			PageDto<EngagementDto>,
			PageOptionsDto & {
				providerId?: string;
			}
		>({
			query: ({ providerId, page, take, orderField, order }) => ({
				url: `engagements/provider/${providerId}`,
				params: {
					page,
					take,
					orderField,
					order,
				},
			}),
			providesTags: ['Engagement'],
		}),
		exportEngagementsToCSV: builder.mutation<
			{ fileUrl: string },
			SearchFilter &
				LanguageFilter &
				ProvidersFilter & {
					district?: string | null;
					start?: string | null;
					end?: string | null;
					archived?: boolean;
					fields?: string;
				}
		>({
			query: ({ search, languages, providers, district, start, end, archived, fields }) => ({
				url: `engagements/export/csv`,
				params: { search, languages, providers, district, start, end, archived, fields },
				responseType: 'json',
			}),
		}),
		importEngagements: builder.mutation<{ success: []; failed: [] }, { formData: FormData; districtId?: string }>({
			query: ({ formData, districtId }) => ({
				url: `import/engagements${districtId ? `?districtId=${districtId}` : ''}`,
				method: 'POST',
				body: formData,
			}),
			invalidatesTags: ['Engagement'],
		}),
		getTemplateEngagementUrl: builder.query<{ url: string }, void>({
			query: () => ({
				url: 'import/engagements/template-url',
				method: 'GET',
			}),
		}),
		downloadFileBlob: builder.query<any, { url: string }>({
			query: (data) => {
				const encodedUrl = encodeURIComponent(data.url);

				return {
					url: `engagements/download-file/by-url`,
					params: { url: encodedUrl },
					responseHandler: async (response) => response.blob(),
				};
			},
		}),
		getLanguageDistribution: builder.query<
			LanguageDistributionDto[],
			{ start?: string | null; end?: string | null; schools?: string | null; district?: string | null } & DistrictFilter
		>({
			query: ({ start, end, schools, district, districts }) => ({
				url: `engagements/families/languages`,
				params: { start, end, schools, district, districts },
			}),
			providesTags: ['Engagement'],
		}),
		getAttendanceLanguage: builder.query<
			BarChartDto[],
			{ start?: string | null; end?: string | null; schools?: string | null; district?: string | null } & DistrictFilter
		>({
			query: ({ start, end, schools, district, districts }) => ({
				url: `engagements/attendance/language`,
				params: { start, end, schools, district, districts },
			}),
			providesTags: ['Engagement'],
		}),
		getProvidersPerLanguage: builder.query<
			LanguagePerProvidersDto[],
			{ start?: string | null; end?: string | null; schools?: string | null; district?: string | null } & DistrictFilter
		>({
			query: ({ start, end, schools, district, districts }) => ({
				url: `engagements/providers/languages`,
				params: { start, end, schools, district, districts },
			}),
			providesTags: ['Engagement'],
		}),
	}),
});

export default engagementsApi;

export const {
	useGetEngagementsListQuery,
	useGetEngagementQuery,
	useCreateEngagementMutation,
	useUpdateEngagementMutation,
	useDeleteEngagementMutation,
	useGetEngagementsByFamilyIdQuery,
	useGetEngagementsByProviderIdQuery,
	useExportEngagementsToCSVMutation,
	useArchiveEngagementMutation,
	useUnarchiveEngagementMutation,
	useLazyGetEngagementsListQuery,
	useImportEngagementsMutation,
	useGetTemplateEngagementUrlQuery,
	useGenerateEngagementsCustomFieldFileUrlMutation,
	useLazyDownloadFileBlobQuery,
	useGetLanguageDistributionQuery,
	useGetAttendanceLanguageQuery,
	useGetProvidersPerLanguageQuery,
} = engagementsApi;
