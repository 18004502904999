export enum SurveyStatusEnum {
	NoSurvey = 'No survey',
	NotSent = 'Not sent',
	NotShared = 'Not shared',
	NeedAttention = 'Need attention',
	Delivered = 'Delivered',
	DeliveredEmail = 'Delivered via Email',
	DeliveredSms = 'Delivered via SMS',
	DeliveredEmailAndSms = 'Delivered via Email & SMS',
	Completed = 'Completed',
}

export const SurveyStatusArray = [
	SurveyStatusEnum.NoSurvey,
	SurveyStatusEnum.NotSent,
	SurveyStatusEnum.NotShared,
	SurveyStatusEnum.NeedAttention,
	SurveyStatusEnum.Delivered,
	SurveyStatusEnum.DeliveredEmail,
	SurveyStatusEnum.DeliveredSms,
	SurveyStatusEnum.DeliveredEmailAndSms,
	SurveyStatusEnum.Completed,
];

export const SurveyStatusColorEnum = {
	[SurveyStatusEnum.NoSurvey]: '#9D9D9D',
	[SurveyStatusEnum.NotSent]: '#EEE764',
	[SurveyStatusEnum.NotShared]: '#EEE764',
	[SurveyStatusEnum.NeedAttention]: '#F89377',
	[SurveyStatusEnum.Completed]: '#F89377',
	[SurveyStatusEnum.Delivered]: '#78BBE2',
	[SurveyStatusEnum.DeliveredEmail]: '#78BBE2',
	[SurveyStatusEnum.DeliveredSms]: '#78BBE2',
	[SurveyStatusEnum.DeliveredEmailAndSms]: '#78BBE2',
};
