import {
	CreateSurveyAnswerDto,
	CreateSurveyTemplateDto,
	EmailDto,
	EngagementSurveyDto,
	FamilyWithSurveyDto,
	PageDto,
	PageOptionsDto,
	SurveyAnswerDto,
	SurveyTemplateDto,
	UpdateSurveyTemplateDto,
	SortingOptionsDto,
	PhoneDto,
	IdDto,
	FamilySurveyTrackingDto,
	JOIN_SURVEY_EVENTS_ROOM,
	ON_SURVEY_TRACKING_EVENT,
	ON_SURVEY_ANSWER_EVENT,
} from '@families-link/shared';
import api from '.';
import { io } from 'socket.io-client';
import { configService } from '../../modules/_core';
import { RootState } from '..';
import { getLocalStorageDistrictId } from '../../modules/_core/utils';

const surveysApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getSurveyTemplateById: builder.query<SurveyTemplateDto, string>({
			query: (id) => ({
				url: `surveys/templates/${id}`,
			}),
			extraOptions: {
				skipAuth: true,
			},
			providesTags: ['SurveyTemplate'],
		}),
		getSurveyTemplateByLanguage: builder.query<SurveyTemplateDto, { language: string; districtId: string }>({
			query: ({ language, districtId }) => ({
				url: `surveys/templates/language/${language}`,
				params: { districtId },
			}),
			providesTags: ['SurveyTemplate'],
		}),
		getAllSurveyTemplatesList: builder.query<SurveyTemplateDto[], string | undefined | null>({
			query: (districtId) => ({
				url: `surveys/templates/by-district`,
				params: { district: districtId },
			}),
			providesTags: ['SurveyTemplate'],
		}),
		getSurveyTemplatesList: builder.query<
			PageDto<SurveyTemplateDto>,
			PageOptionsDto & { adminTemplate: boolean; district?: string | null }
		>({
			query: ({ page, take, orderField, order, district, adminTemplate }) => ({
				url: `surveys/templates`,
				params: { page, take, orderField, order, district, adminTemplate },
			}),
			providesTags: ['SurveyTemplate'],
		}),
		createSurveyTemplate: builder.mutation<SurveyTemplateDto, CreateSurveyTemplateDto>({
			query: (data) => ({
				url: `surveys/templates`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['SurveyTemplate'],
		}),
		updateSurveyTemplate: builder.mutation<SurveyTemplateDto, { id: string; data: UpdateSurveyTemplateDto }>({
			query: ({ id, data }) => ({
				url: `surveys/templates/${id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['SurveyTemplate'],
		}),
		deleteSurveyTemplate: builder.mutation<SurveyTemplateDto, { id: string }>({
			query: ({ id }) => ({
				url: `surveys/templates/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['SurveyTemplate'],
		}),
		getEngagementSurveyById: builder.query<EngagementSurveyDto, string>({
			query: (id) => ({
				url: `surveys/${id}`,
			}),
		}),
		getEngagementInformationBySurvey: builder.query<any, string>({
			query: (id) => ({
				url: `surveys/engagement-information/${id}`,
			}),
		}),
		checkExistEngagementSurvey: builder.query<EngagementSurveyDto, string>({
			query: (id) => ({
				url: `surveys/engagement/${id}`,
			}),
			providesTags: ['Survey'],
		}),
		createEngagementSurvey: builder.mutation<EngagementSurveyDto, EngagementSurveyDto>({
			query: ({ surveyTemplateId, engagementId }) => ({
				url: `surveys/connect-survey/${surveyTemplateId}/${engagementId}`,
				method: 'POST',
			}),
			invalidatesTags: ['Survey'],
		}),
		deleteEngagementSurvey: builder.mutation<EngagementSurveyDto, { id: string }>({
			query: ({ id }) => ({
				url: `surveys/disconnect-survey/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Survey'],
		}),
		sendSurveyToFamilies: builder.mutation<void, { engagementId: string }>({
			query: ({ engagementId }) => ({
				url: `surveys/send-surveys/${engagementId}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Survey'],
		}),
		writeSurveyAnswers: builder.mutation<void, CreateSurveyAnswerDto>({
			query: (data) => ({
				url: `surveys/answers`,
				method: 'POST',
				body: data,
			}),
			extraOptions: {
				skipAuth: true,
			},

			invalidatesTags: ['SurveyAnswer'],
		}),
		getSurveyAnswers: builder.query<SurveyAnswerDto, { surveyId: string; familyId: string }>({
			query: ({ surveyId, familyId }) => ({
				url: `surveys/answers/${surveyId}/${familyId}`,
			}),
			providesTags: ['SurveyAnswer'],
		}),
		getSurveyTemplateBySurveyId: builder.query<SurveyTemplateDto, string>({
			query: (surveyId) => ({
				url: `surveys/survey-template/${surveyId}`,
			}),
			extraOptions: {
				skipAuth: true,
			},
		}),
		checkExistSurveyAnswer: builder.query<IdDto, { surveyId: string; familyId: string }>({
			query: ({ surveyId, familyId }) => ({
				url: `surveys/check-answer/${surveyId}/${familyId}`,
			}),
			extraOptions: {
				skipAuth: true,
			},
			providesTags: ['SurveyAnswer'],
		}),
		getListEngagementFamiliesWithSurvey: builder.query<
			FamilyWithSurveyDto[],
			SortingOptionsDto & { id?: string | null }
		>({
			query: ({ id, orderField, order }) => ({
				url: `surveys/family-list/${id}`,
				params: { orderField, order },
			}),
			providesTags: ['Survey', 'SurveyAnswer'],
			async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState, dispatch }) {
				const state = getState() as RootState;
				const token = state.session.access?.token;

				if (!token) {
					console.error('No access token found');

					return;
				}

				const socket = io(configService.get('NX_PUBLIC_APP_API_URL'), {
					auth: { token },
					transports: ['websocket', 'polling'],
				});

				socket.on('connect', () => {
					console.log('WebSocket connected!');
				});

				const districtId = getLocalStorageDistrictId();

				socket.emit(JOIN_SURVEY_EVENTS_ROOM, { districtId });

				const eventListner = (data: any) => {
					dispatch(surveysApi.util.invalidateTags([{ type: 'Survey' }, { type: 'SurveyAnswer' }]));
				};

				socket.on(ON_SURVEY_TRACKING_EVENT, eventListner);
				socket.on(ON_SURVEY_ANSWER_EVENT, eventListner);

				try {
					await cacheEntryRemoved;
				} finally {
					socket.off(ON_SURVEY_TRACKING_EVENT, eventListner);
					socket.off(ON_SURVEY_ANSWER_EVENT, eventListner);
					socket.disconnect();
				}
			},
		}),
		getFamilyWithSurvey: builder.query<FamilyWithSurveyDto, { familyId?: string | null; engagementId?: string | null }>(
			{
				query: ({ familyId, engagementId }) => ({
					url: `surveys/family-with-survey/${familyId}/${engagementId}`,
				}),
				providesTags: ['Survey', 'SurveyAnswer'],
			}
		),
		sendSurveyByEmail: builder.mutation<void, EmailDto & { surveyId: string }>({
			query: (data) => ({
				url: `surveys/send-to-family/email/${data.surveyId}`,
				method: 'PATCH',
				body: data,
			}),
			invalidatesTags: ['SurveyAnswer'],
		}),
		sendSurveyBySms: builder.mutation<void, PhoneDto & { surveyId: string }>({
			query: (data) => ({
				url: `surveys/send-to-family/sms/${data.surveyId}`,
				method: 'PATCH',
				body: data,
			}),
			invalidatesTags: ['SurveyAnswer'],
		}),
		sendSurveyByPreferedMethod: builder.mutation<void, { surveyId: string; familyId: string }>({
			query: ({ surveyId, familyId }) => ({
				url: `surveys/send-to-family/prefered-method/${surveyId}/${familyId}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Survey'],
		}),
		getFamilySurveyTrack: builder.query<FamilySurveyTrackingDto, string | undefined>({
			query: (hash) => ({
				url: `surveys/family-track/${hash}`,
			}),
			extraOptions: {
				skipAuth: true,
			},
		}),
		getSurveySummary: builder.query<{ answeredSurveys: number; totalSurveys: number }, string>({
			query: (engagementId) => `surveys/summary/${engagementId}`,
			providesTags: ['Survey'],
		}),
	}),
});

export default surveysApi;

export const {
	useGetSurveyTemplatesListQuery,
	useGetSurveyTemplateByIdQuery,
	useCreateSurveyTemplateMutation,
	useUpdateSurveyTemplateMutation,
	useDeleteSurveyTemplateMutation,
	useGetEngagementSurveyByIdQuery,
	useCreateEngagementSurveyMutation,
	useCheckExistEngagementSurveyQuery,
	useGetAllSurveyTemplatesListQuery,
	useSendSurveyToFamiliesMutation,
	useWriteSurveyAnswersMutation,
	useGetSurveyAnswersQuery,
	useLazyGetSurveyAnswersQuery,
	useGetListEngagementFamiliesWithSurveyQuery,
	useLazyGetListEngagementFamiliesWithSurveyQuery,
	useSendSurveyByEmailMutation,
	useSendSurveyBySmsMutation,
	useSendSurveyByPreferedMethodMutation,
	useGetFamilyWithSurveyQuery,
	useGetSurveyTemplateBySurveyIdQuery,
	useCheckExistSurveyAnswerQuery,
	useGetFamilySurveyTrackQuery,
	useLazyGetFamilySurveyTrackQuery,
	useLazyGetSurveyTemplateBySurveyIdQuery,
	useLazyCheckExistSurveyAnswerQuery,
	useGetSurveySummaryQuery,
	// useGetEngagementInformationBySurveyQuery,
	useLazyGetEngagementInformationBySurveyQuery,
	useDeleteEngagementSurveyMutation,
	useLazyGetSurveyTemplateByLanguageQuery,
} = surveysApi;
