import React, { FC, useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import { FormControlsContainer } from '../_ui/form-controls';
import { PhoneInput } from '../../../_core/components/_ui/phone-input';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { SignupErrorLabel } from './signup-error-label.component';
import { titleStyles } from '../login-form/login-form.styles';
import { textFieldWrapperStyles } from './signup-form.styles';
import { CorePasswordInput } from '../../../_core/components/_ui/core-password-input';
import { CreateUserDto, SignUpWithEmailDto, UserDto, UserInfoDto } from '@families-link/shared';

const resolver = classValidatorResolver(UserInfoDto);

export interface SignUpWithEmailFormProps {
	error?: FetchBaseQueryError | SerializedError;
	profile: UserInfoDto;
	onSubmit(values: UserInfoDto): void;
}

export const SignUpWithEmailForm: FC<SignUpWithEmailFormProps> = (props) => {
	const { error, onSubmit, profile } = props;

	const [t] = useTranslation();

	const {
		control,
		handleSubmit,
		register,
		formState: { errors, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = useForm<UserInfoDto>({
		resolver,
		defaultValues: profile,
	});

	const disableSubmit = !isValid && (isDirty || isSubmitted);

	const handleFormSubmit = useCallback(
		async (values: UserInfoDto) => {
			await onSubmit({ ...values });
		},
		[onSubmit]
	);

	return (
		<Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
			<Typography variant="h3" sx={{ mb: 2, textAlign: 'center' }}>
				{t('sign-up.account-register')}
			</Typography>

			<CoreTextField
				fullWidth
				id="full-name"
				controlSx={{ mb: 2 }}
				label={t('sign-up.registration-form.full-name')}
				placeholder={t('sign-up.registration-form.full-name-ph') ?? ''}
				{...register('name')}
				error={!!errors.name}
				helperText={errors.name?.message}
			/>

			<CoreTextField
				fullWidth
				id="email"
				controlSx={{ mb: 2 }}
				label={t('sign-up.registration-form.email')}
				placeholder={t('sign-up.registration-form.email-ph') ?? ''}
				{...register('email')}
				error={!!errors.email}
				helperText={errors.email?.message}
			/>

			<SignupErrorLabel error={error} />

			<FormControlsContainer>
				<CoreButton
					loading={isSubmitting}
					contraModeStyleButton
					disabled={disableSubmit}
					type="submit"
					sx={{ minWidth: 104 }}
				>
					{t('sign-up.registration-form.next')}
				</CoreButton>
			</FormControlsContainer>
		</Box>
	);
};
