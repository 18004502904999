import { SxProps, Theme } from '@mui/material';

export const buttonHeaderStyles: SxProps<Theme> = [
    {
        display: 'flex',
        fontSize: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        whiteSpace: 'nowrap',
},
];


export const boxHeaderStyles: SxProps<Theme> = [
    {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        mb: 4,
        flexWrap: 'nowrap',
},
];