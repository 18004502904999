import { ArrayNotEmpty, IsArray, IsEnum, IsNotEmpty, Length, ValidateIf, ValidateNested } from 'class-validator';
import { FieldDataTypeEnum, SurveyQuestionTypeEnum } from '../../enums';
import { Type } from 'class-transformer';
import { FieldOptionDto } from './field-option.dto';

export class CreateSurveyQuestionDto {
	@IsNotEmpty()
	@Length(1)
	text: string;

	@IsNotEmpty()
	@IsEnum(SurveyQuestionTypeEnum)
	type: SurveyQuestionTypeEnum;

	@IsNotEmpty()
	isRequired: boolean;

	@ValidateIf((obj) => obj.type === FieldDataTypeEnum.Options || obj.type === FieldDataTypeEnum.MultiSelect)
	@IsArray()
	@ArrayNotEmpty()
	@ValidateNested({ each: true })
	@Type(() => FieldOptionDto)
	options?: Array<FieldOptionDto>;
}
