import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ProviderDto, WorkStatusEnum, ServiceTypeStatusEnum } from '@families-link/shared';

import { Verified, InHouse, Outsource } from '../../_core/constants/icons.constants';
import { Avatar, Typography, Box } from '@mui/material';
import { getObjectLanguage } from '../../_core/components/languages-component';
import { ProvidersTableHandlers } from '../components/providers-table';
import { ProvidersTableActions } from '../components/providers-table/providers-table-actions.component';
import { useLocation } from 'react-router-dom';
import { mergeSx } from 'merge-sx';
import { tableCellTypographyOverflowStyles } from '../../_core/styles';
import { InHouseIcon, OutsourceIcon } from '../../_core/components/icons';

const helper = createColumnHelper<ProviderDto & { createdAt: string; updatedAt: string }>();

export function useProvidersTableColumns(handlers: ProvidersTableHandlers): ColumnDef<ProviderDto, string>[] {
	const { onView, onDelete, onEdit } = handlers;

	const [t] = useTranslation();
	const location = useLocation();
	const isAdminRoute = location.pathname.startsWith('/admin');

	const result = useMemo(
		() => [
			helper.accessor('fullName', {
				header: t('providers.full-name'),
				meta: {
					flex: 1.5,
					useCustomOverflowContainer: true,
				},
				enableSorting: true,
				cell: ({ getValue, row }) => {
					const imageUrl = row.original.imageUrl;
					const fullName = getValue();

					return (
						<Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: '6px' }}>
							<Avatar
								src={imageUrl}
								alt={fullName}
								style={{ width: 24, height: 24, borderRadius: '6px' }}
							/>
							<Typography data-overflow="true" sx={mergeSx({ fontWeight: 500 }, tableCellTypographyOverflowStyles)}>
								{fullName}
							</Typography>
						</Box>
					);
				},
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('targetLanguage', {
				header: t('providers.target-lang'),
				meta: {
					flex: 1,
				},
				enableSorting: true,
				cell: (info) => {
					const languages = info.getValue() as string[];
					const options = languages.map(getObjectLanguage);

					return options.map((option) => (option?.name ? option.name : '')).join(', ');
				},
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('verificationType', {
				header: t('providers.type'),
				meta: {
					flex: 0.8,
					useCustomOverflowContainer: true,
				},
				enableSorting: true,
				cell: (info) =>
					info.getValue() ? (
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
							<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
								{t('providers.verified')}{' '}
							</Typography>
							<Verified />
						</Box>
					) : (
						<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
							{t('providers.no-verified')}
						</Typography>
					),
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('workStatus', {
				header: t('providers.status'),
				meta: {
					flex: 0.8,
					useCustomOverflowContainer: true,
				},
				enableSorting: true,
				cell: ({getValue}) => {
					switch (getValue()) {
						case WorkStatusEnum.InHouse:
							return (
								<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
									<InHouseIcon sx={{fontSize: '14px'}} />
									<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
										{t('providers.work-in-house')}
									</Typography>
								</Box>
							);
							break;
						case WorkStatusEnum.Outsource:
							return (
								<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
									<OutsourceIcon sx={{fontSize: '14px'}} />
									<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
										{t('providers.work-outsource')}
									</Typography>
								</Box>
							);
						default:
							return null;
					}
				},
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('serviceType', {
				header: t('providers.service-type'),
				meta: {
					flex: 1.2,
					useCustomOverflowContainer: true,
				},
				enableSorting: true,
				cell: (info) => {
					const value = info.getValue();
					switch (value) {
						case ServiceTypeStatusEnum.Appointment:
							return (
								<Typography data-overflow="true"
								sx={tableCellTypographyOverflowStyles}>
									{t('providers.appointment')}
								</Typography>
							);
						case ServiceTypeStatusEnum.Project:
							return (
								<Typography data-overflow="true"
								sx={tableCellTypographyOverflowStyles}>
									{t('providers.project')}
								</Typography>
							);
						case ServiceTypeStatusEnum.Both:
							return (
								<Typography data-overflow="true"
								sx={tableCellTypographyOverflowStyles}>
									{t('providers.appointment')}, {t('providers.project')}
								</Typography>
							);
						default:
							return null;
					}
				},
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('totalEngagements', {
				header: 'Total Engagements',
				meta: {
					flex: 1,
				},
				enableSorting: true,
			}) as ColumnDef<ProviderDto>,
			...(isAdminRoute
				? [
						helper.accessor('district', {
							header: 'District',
							meta: {
								flex: 1,
							},
							cell: ({ getValue }) => {
								const district = getValue();

								return district?.name;
							},
						}) as ColumnDef<ProviderDto, string>,
				  ]
				: []),

			helper.display({
				id: 'actions',
				cell: (props) => (
					<ProvidersTableActions provider={props.row.original} onView={onView} onDelete={onDelete} onEdit={onEdit} />
				),
			}) as ColumnDef<ProviderDto>,
		],
		[t, onView, onDelete, onEdit]
	);

	return result;
}
