import { FC, useCallback, useMemo } from 'react';
import { CoreTableActionsCell, CellAction } from '../../../../_core/components/_ui/core-table';
import { FamiliesDetailsTableHandlers } from './families-details-table.component';
import { EditIcon, EyeIcon, SendSmsIcon, ShareIcon } from '../../../../_core/components/icons';
import { FamilyWithSurveyDto, SurveyStatusEnum } from '@families-link/shared';
export interface SessionsTableActionsProps extends FamiliesDetailsTableHandlers {
	familyWithSurvey: FamilyWithSurveyDto;
}

export const FamiliesDetailsTableActions: FC<SessionsTableActionsProps> = (props) => {
	const { familyWithSurvey, onSendEmail, onSendSms, onEdit, onView } = props;

	const handleSendEmailFamilySurvey = useCallback(() => {
		if (!familyWithSurvey) {
			return;
		}

		onSendEmail?.(familyWithSurvey);
	}, [onSendEmail, familyWithSurvey]);

	const handleSendSmsFamilySurvey = useCallback(() => {
		if (!familyWithSurvey) {
			return;
		}

		onSendSms?.(familyWithSurvey);
	}, [onSendEmail, familyWithSurvey]);

	const handleManualEditFamilySurvey = useCallback(() => {
		if (!familyWithSurvey) {
			return;
		}

		onEdit?.(familyWithSurvey);
	}, [onEdit, familyWithSurvey]);

	const handleViewFamily = useCallback(() => {
		if (!familyWithSurvey) {
			return;
		}

		onView?.(familyWithSurvey);
	}, [onView, familyWithSurvey]);

	const actions = useMemo<CellAction[]>(
		() => [
			{
				id: 'view',
				type: 'button',
				props: {
					children: <EyeIcon sx={{ fontSize: 30 }} color="primary" />,
					onClick: handleViewFamily,
				},
			},
			{
				id: 'more',
				type: 'menu',
				props: {
					options: [
						{
							id: 'email',
							label: 'Send via email',
							icon: <ShareIcon color="primary" />,
							onClick: handleSendEmailFamilySurvey,
							disabled:
								!familyWithSurvey.family.email ||
								familyWithSurvey.surveyStatus === SurveyStatusEnum.NoSurvey ||
								familyWithSurvey.surveyStatus === SurveyStatusEnum.Completed,
						},
						{
							id: 'sms',
							label: 'Send via sms',
							icon: <SendSmsIcon color="primary" />,
							onClick: handleSendSmsFamilySurvey,
							disabled:
								familyWithSurvey.surveyStatus === SurveyStatusEnum.NoSurvey ||
								familyWithSurvey.surveyStatus === SurveyStatusEnum.Completed,
						},
						{
							id: 'edit',
							label: 'Manual editing',
							icon: <EditIcon color="primary" />,
							onClick: handleManualEditFamilySurvey,
							disabled:
								familyWithSurvey.surveyStatus === SurveyStatusEnum.NoSurvey ||
								familyWithSurvey.surveyStatus === SurveyStatusEnum.Completed,
						},
					],
				},
			},
		],
		[handleViewFamily, handleSendEmailFamilySurvey, handleSendSmsFamilySurvey, handleManualEditFamilySurvey]
	);

	return <CoreTableActionsCell actions={actions} />;
};
