import { FC, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldError, FieldValues, FormProvider, Resolver, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
	useWriteSurveyAnswersMutation,
	useLazyGetFamilySurveyTrackQuery,
	useLazyGetSurveyTemplateBySurveyIdQuery,
	useLazyCheckExistSurveyAnswerQuery,
	useLazyGetEngagementInformationBySurveyQuery,
} from '../../../../store/api/survey.api';
import { SurveyQuestionRender } from '../../components/question/question.component';
import { SurveyHeader } from '../../../_core/components/header-survey/header-survey.component';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { SurveyQuestionInterface } from '@families-link/shared';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';

const selectLanguageObject: { [key: string]: string } = {
	Arabic: 'ar',
	ar: 'ar',
	Spanish: 'es',
	es: 'es',
};

const customResolver: Resolver = async (values: FieldValues, context: { surveyTemplate: any }) => {
	const errors: { [key: string]: FieldError } = {};

	if (context.surveyTemplate) {
		context.surveyTemplate.questions.forEach((question: SurveyQuestionInterface) => {
			const fieldName = `field_${question._id}`;

			if (question.isRequired) {
				const fieldValue = values[fieldName];

				if (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0)) {
					errors[fieldName] = {
						type: 'required',
						message: `This field is required`,
					};
				}
			}
		});
	}

	return {
		values,
		errors,
	};
};

export const SurveyViewPage: FC = () => {
	const { t, i18n } = useTranslation('survey');
	const [triggerGetFamilySurveyTrackQuery, { data: familyTrack }] = useLazyGetFamilySurveyTrackQuery();
	const [triggerGetSurveyTemplateBySurveyIdQuery, { data: surveyTemplate, isError }] =
		useLazyGetSurveyTemplateBySurveyIdQuery();
	const [triggerCheckExistSurveyAnswerQuery, { data: surveyAnswers }] = useLazyCheckExistSurveyAnswerQuery();
	const [triggerGetEngagementInformationBySurveyQuery, { data: engagementInformation }] =
		useLazyGetEngagementInformationBySurveyQuery();
	const [writeSurveyAnswers] = useWriteSurveyAnswersMutation();

	const { urlHash, familyId: familyIdParam, surveyId: surveyIdParam } = useParams();

	useEffect(() => {
		if (urlHash) {
			triggerGetFamilySurveyTrackQuery(urlHash);
		}
	}, [urlHash, triggerGetFamilySurveyTrackQuery]);
	const familyId = familyTrack?.familyId ?? familyIdParam;
	const surveyId = familyTrack?.engagementSurveyId ?? surveyIdParam;

	useEffect(() => {
		if (surveyId) {
			triggerGetEngagementInformationBySurveyQuery(surveyId);
		}

		if (surveyId && familyId) {
			triggerGetSurveyTemplateBySurveyIdQuery(surveyId);
			triggerCheckExistSurveyAnswerQuery({
				surveyId,
				familyId,
			});
		}
	}, [
		surveyId,
		familyId,
		triggerCheckExistSurveyAnswerQuery,
		triggerGetSurveyTemplateBySurveyIdQuery,
		triggerGetEngagementInformationBySurveyQuery,
	]);

	const browserLanguage = navigator.language;

	const templateLanguageArabicOrSpanishArray = ['Arabic', 'Spanish'];
	const browserLanguageArabicOrSpanishArray = ['ar', 'es'];

	const selectedLanguage =
		surveyTemplate?.language && templateLanguageArabicOrSpanishArray.includes(surveyTemplate?.language)
			? selectLanguageObject[surveyTemplate?.language]
			: browserLanguageArabicOrSpanishArray.includes(browserLanguage)
			? selectLanguageObject[browserLanguage]
			: 'en';

	useEffect(() => {
		i18n.changeLanguage(selectedLanguage);
	}, [i18n, surveyTemplate?.language, browserLanguage]);

	const defaultValues = {
		familyId,
		surveyId,
		...surveyTemplate?.questions?.reduce((result: any, el: SurveyQuestionInterface) => {
			result[`field_${el._id}`] = '';

			return result;
		}, {}),
	};

	const methods = useForm({
		defaultValues,
		resolver: async (values, fieldNames) => customResolver(values, { surveyTemplate }, fieldNames),
		mode: 'onSubmit',
	});

	const {
		register,
		handleSubmit,
		formState: { isDirty, isLoading, isSubmitting, isSubmitted, isValid, errors },
	} = methods;

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const onSubmit = async (values: any) => {
		const transformValues = Object.keys(values).reduce(
			(submitObject: any, key: string) => {
				if (key.startsWith('field_')) {
					const fieldId = key.replace('field_', '');
					submitObject['answers'].push({
						fieldId,
						value: values[key],
					});
				} else {
					submitObject[key] = values[key];
				}
				submitObject['familyId'] = familyId;
				submitObject['surveyId'] = surveyId;

				return submitObject;
			},
			{ answers: [] }
		);
		await writeSurveyAnswers(transformValues);
	};

	if (!familyId || !surveyId) {
		return null;
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				minHeight: 0,
				height: '100%',
				direction: selectedLanguage === 'ar' ? 'rtl' : 'ltr',
				textAlign: selectedLanguage === 'ar' ? 'right' : 'left',
			}}
		>
			{isError ? (
				<Box sx={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Typography>{t('survey-templates.deleted-survey-template')}</Typography>
				</Box>
			) : surveyTemplate ? (
				<Box sx={{ width: '100%', height: '100%' }}>
					<Box component="header" sx={{ mb: 1 }}>
						<SurveyHeader
							title={surveyTemplate?.title}
							engagementType={engagementInformation?.type}
							engagementDuration={engagementInformation?.duration}
							engagementSchool={engagementInformation?.school}
						/>
					</Box>
					{surveyAnswers ? (
						<Paper elevation={0} sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
							<Typography fontSize={14} sx={{ fontWeight: '500' }}>
								{t('survey-templates.forms.completed-survey')}
							</Typography>
						</Paper>
					) : (
						<FormProvider {...methods}>
							<Box
								component="form"
								onSubmit={handleSubmit(onSubmit)}
								sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
							>
								{surveyTemplate?.questions?.map((question, index) => {
									const fieldName = `field_${question._id}`;
									const error = errors[fieldName];

									return (
										<Paper
											key={question._id.toString()}
											elevation={0}
											sx={{ p: 3, display: 'flex', flexDirection: 'column' }}
										>
											<Typography fontSize={14} sx={{ fontWeight: '500', mb: '10px' }}>
												{index + 1}. {question.text}{' '}
												{question.isRequired && (
													<span style={{ color: 'red', marginLeft: '4px', fontWeight: '300' }}>*</span>
												)}
											</Typography>
											<SurveyQuestionRender question={question} />
											{error && (
												<Typography color="error" fontSize="12px" sx={{ mt: 1 }}>
													The answer is required
												</Typography>
											)}
										</Paper>
									);
								})}
								<Box>
									<CoreButton type="submit" contraModeStyleButton disabled={disableSubmit} loading={loadingSubmit}>
										{'Submit'}
									</CoreButton>
								</Box>
							</Box>
						</FormProvider>
					)}
				</Box>
			) : null}
		</Box>
	);
};
