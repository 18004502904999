import { IsEmail, IsEnum, Length, IsNotEmpty } from 'class-validator';
import { UserRolesEnum } from '../../enums';

export class CreateUserFrontDto {
	@IsEmail({}, { message: 'Incorrect Email' })
	@IsNotEmpty({ message: 'Email is required' })
	email: string;

	@Length(1, 100)
	@IsNotEmpty({ message: 'Full Name is required' })
	name: string;

	@IsEnum(UserRolesEnum, { message: 'Role is required' })
	role: string;
}
