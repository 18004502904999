import { IsString, IsBoolean, IsOptional, IsArray, ValidateNested, IsEnum, IsNotEmpty, Length } from 'class-validator';
import { Transform, Type } from 'class-transformer';
import { FieldOptionDto } from './field-option.dto';
import { FieldOptionInterface } from '../../interfaces';
import { CustomFieldDataTypeEnum, ServiceTypeStatusEnum } from '../../enums';

export class CreateCustomFieldDto {
	@IsNotEmpty()
	districtId: string;

	@Length(1, 100)
	name: string;

	@IsEnum(CustomFieldDataTypeEnum)
	dataType: string;

	@Transform(({ value }) => {
		if (value === 'true') return true;

		if (value === 'false') return false;

		return value;
	})
	@IsBoolean()
	isRequired: boolean;

	@IsOptional()
	defaultFieldId?: string;

	@IsEnum(ServiceTypeStatusEnum)
	engagementType?: string;

	@IsOptional()
	@IsArray()
	@ValidateNested({ each: true })
	@Type(() => FieldOptionDto)
	options?: Array<FieldOptionInterface>;
}
