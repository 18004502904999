import React, { FC, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { ALLOWED_FILE_MIME_TYPES, CustomFieldDto, MAX_FILE_SIZE } from '@families-link/shared';
import { useDropzone } from 'react-dropzone';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { CoreFormControl, CoreFormControlProps } from '../../../_core/components/_ui/core-form-control';
import { UploadIcon } from '../../../_core/constants/icons.constants';
import CoreFileItemComponent from '../../../_core/components/_ui/core-file/core-file-item.component';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { v4 } from 'uuid';

export interface DynamicFileFieldProps extends CoreFormControlProps {
	customField: CustomFieldDto;
}

const validateFile = (value: any, isRequired: boolean) => {
	if (isRequired && !value?.length) {
		return `File is required`;
	}

	if (value) {
		for (const el of value) {
			if (el && typeof el !== 'string') {
				if (!ALLOWED_FILE_MIME_TYPES.includes(el.type)) {
					return 'Invalid file type.';
				}

				if (el.size > MAX_FILE_SIZE) {
					return 'File size exceeds 50 MB.';
				}
			}
		}
	}

	return true;
};

export const DynamicFileField: FC<DynamicFileFieldProps> = (props) => {
	const { customField, controlSx } = props;
	const inputName = `field_${customField._id}`;
	const {
		control,
		watch,
		setValue,
		getValues,
		trigger,
		formState: { errors },
	} = useFormContext();

	const files = watch(inputName);

	const onDrop = useCallback(
		(acceptedFiles: any) => {
			if (getValues(inputName)) {
				setValue(inputName, [
					...getValues(inputName),
					...acceptedFiles.map((file: any) =>
						Object.assign(file, {
							fileId: v4(),
						})
					),
				]);
			} else {
				setValue(inputName, [
					...acceptedFiles.map((file: any) =>
						Object.assign(file, {
							fileId: v4(),
						})
					),
				]);
			}

			trigger(inputName);
		},
		[setValue, inputName, trigger]
	);
	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop,
		multiple: true,
		noClick: true,
		accept: {
			'image/*': ['.jpeg', '.png', '.jpg', '.gif', '.webp', '.svg'],
			'video/*': ['.mp4', '.mkv', '.avi'],
			'text/*': ['.txt', '.csv'],
			'application/pdf': ['.pdf'],
			'application/msword': ['.doc'],
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
			'application/vnd.ms-excel': ['.xls'],
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls'],
			'application/vnd.ms-powerpoint': ['.ppt'],
			'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
		},
	});
	

	return (
		<Controller
			name={inputName}
			control={control}
			rules={{
				validate: (value) => validateFile(value, customField.isRequired),
			}}
			render={({ field }) => (
				<CoreFormControl
					label={customField.name || ''}
					requiredMark={customField.isRequired}
					fullWidth={true}
					error={!!errors[inputName]}
					helperText={errors[inputName] ? (errors[inputName] as FieldError).message : undefined}
					controlSx={controlSx}
				>
					<Box
						{...getRootProps()}
						sx={{
							border: `2px dashed ${errors[inputName] ? '#F8223C' : '#cccccc'}`,
							borderRadius: '10px',
							textAlign: 'center',
							padding: '20px',
							bgcolor: ({ colors }) => (isDragActive ? colors.blueTransparentLight : colors.white),
							transition: 'background-color 0.3s',
						}}
					>
						<input {...getInputProps()} />
						<Box component={UploadIcon} my={1} />
						{isDragActive ? (
							<Typography variant="body2">Drop the files here...</Typography>
						) : files?.length ? (
							files.map((file: any, index: number) => (
								<CoreFileItemComponent
									key={file.fileId}
									id={inputName}
									index={index}
									type={file.type}
									name={file.name}
									completed
									onCancel={() => {
										if (file?.url) {
											let currentListDeletedFiles = [];
											currentListDeletedFiles = getValues('deletedFiles');
											currentListDeletedFiles.push({ fieldId: customField._id, ...file });
											setValue('deletedFiles', currentListDeletedFiles);
										}
										setValue(
											inputName,
											getValues(inputName)?.filter((el: any) => el.fileId !== file.fileId)
										);
										trigger(inputName);
									}}
								/>
							))
						) : (
							<Typography variant="body2">{'Drag and Drop files here (Maximum file size is 50Mb)'}</Typography>
						)}
						<CoreButton variant="secondary" onClick={open} sx={{ my: 2 }}>
							Choose file
						</CoreButton>
					</Box>
				</CoreFormControl>
			)}
		/>
	);
};

