import { Box, Paper, Typography } from '@mui/material';
import { FC } from 'react';

interface NotFoundComponentProps {
	componentName: string;
}

export const NotFoundComponent: FC<NotFoundComponentProps> = ({ componentName }) => (
	<Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
		<Paper
			elevation={0}
			sx={{ flex: 1, pt: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
		>
			<Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{`404 this ${componentName} not found`}</Typography>
		</Paper>
	</Box>
);
