import { EngagementDto, FamilyDto, ProviderDto } from '@families-link/shared';
import { useCurrentFamilySelector } from '../../family/store/family.slice';
import { useCurrentProviderSelector } from '../../providers/store/provider.slice';
import { useCurrentEngagementSelector } from '../../engagement/store/engagement.slice';
import { useGetAllFieldsQuery } from '../../../store/api/customRequirements.api';

export const useReplaceName = () => {
	const currentFamily = useCurrentFamilySelector() as FamilyDto | null;
	const currentProvider = useCurrentProviderSelector() as ProviderDto | null;
	const currentEngagement = useCurrentEngagementSelector() as EngagementDto | null;
	const { data: customFields } = useGetAllFieldsQuery(
		{ districtId: currentEngagement?.districtId },
		{ skip: !currentEngagement?.districtId }
	);
	const titleField = customFields?.find((field) => field.name === 'Title');
	const engagementTitle = currentEngagement?.customFieldsValues?.find(
		(value) => value.fieldId.toString() === titleField?._id.toString()
	)?.value;

	return (str: string) => {
		let result = str;

		if (result.includes(':familyName')) {
			if (currentFamily) {
				result = result.replace(':familyName', currentFamily.fullName);
			} else {
				result = result.replace(':familyName', '');
			}
		}

		if (result.includes(':providerName')) {
			if (currentProvider) {
				result = result.replace(':providerName', currentProvider.fullName);
			} else {
				result = result.replace(':providerName', '');
			}
		}

		if (result.includes(':engagementTitle') && customFields) {
			if (currentEngagement) {
				result = result.replace(':engagementTitle', engagementTitle);
			} else {
				result = result.replace(':engagementTitle', '');
			}
		}

		return result;
	};
};
