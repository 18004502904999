import { ChildDto, DefaultFieldTypeStatusEnum, FamilyDto, FieldValueInterface } from '@families-link/shared';
import dayjs from 'dayjs';
import { DefaultFormFieldsInterface } from './getDefaultFormFields.util';

export const transformValuesObject = (
	values: any,
	typeInForm: string,
	defaultFormFields: DefaultFormFieldsInterface
) => {
	const transformedValues = Object.keys(values).reduce(
		(submitObject: { [k: string]: any }, key) => {
			if (
				typeInForm === DefaultFieldTypeStatusEnum.Appointment &&
				(key === `day` || key === 'time' || key === 'appointmentDuration')
			) {
				const existField = submitObject['customFieldsValues'].find(
					(el: FieldValueInterface) => el.fieldId.toString() === defaultFormFields.duration?._id
				);

				const startDate = values[`day`] && values['time'] ? dayjs(values[`day`])
					.hour(values['time'].hour())
					.minute(values['time'].minute())
					.second(values['time'].second()) : null;
				const endDate = startDate? startDate.add(values['appointmentDuration'], 'millisecond') : null;

				if (existField) {
					existField.value = {
						startDate: startDate ? startDate.toDate() : null, endDate: endDate?.toDate()
					};
				} else {
					submitObject['customFieldsValues'].push({
						fieldId: defaultFormFields.duration?._id,
						value: { startDate: startDate ? startDate.toDate() : null, endDate: endDate?.toDate() },
					});
				}
			} else if (
				typeInForm === DefaultFieldTypeStatusEnum.Project &&
				key === `field_${defaultFormFields.duration?._id}`
			) {
				const existField = submitObject['customFieldsValues'].find(
					(el: FieldValueInterface) => el.fieldId.toString() === key.replace('field_', '')
				);

				if (existField) {
					console.log(values[key][0]);
					existField.value = {
						startDate: values[key][0] ? dayjs(new Date(values[key][0])).hour(0).minute(0).second(0).toDate() : null,
						endDate: values[key][1] ? dayjs(new Date(values[key][1])).hour(23).minute(59).second(59).toDate() : null,
					};
				} else {
					submitObject['customFieldsValues'].push({
						fieldId: key.replace('field_', ''),
						value: {
							startDate:  values[key][0] ? dayjs(new Date(values[key][0])).hour(0).minute(0).second(0).toDate() : null,
							endDate:  values[key][1] ? dayjs(new Date(values[key][1])).hour(23).minute(59).second(59).toDate(): null,
						},
					});
				}
			} else if (key.startsWith('field_')) {
				if (key !== `field_${defaultFormFields.duration?._id}`) {
					const existField = submitObject['customFieldsValues'].find(
						(el: FieldValueInterface) => el.fieldId.toString() === key.replace('field_', '')
					);

					if (existField) {
						existField.value = values[key];
					} else {
						const fieldId = key.replace('field_', '');
						submitObject['customFieldsValues'].push({
							fieldId,
							value: values[key],
						});
					}
				}
			} else if (key === 'provider') {
				submitObject['providerId'] = values[key]?._id;
			} else if (key === 'children') {
				submitObject['childIds'] =
					values[key]?.length === 0 ? undefined : values[key]?.map((item: ChildDto) => item?._id);
			} else {
				submitObject[key] = values[key];
			}

			return submitObject;
		},
		{ customFieldsValues: [] }
	);

	return transformedValues;
};
