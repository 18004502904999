import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { DefaultValues, useForm } from 'react-hook-form';

type SchoolFilterFormValues = {
	schools: [];
};

export const useSchoolFilter = () => {
	const [schools, setSchools] = useState<string[]>([]);

	const defaultValues: DefaultValues<SchoolFilterFormValues> = {
		schools: [],
	};

	const { register, getValues, control, watch } = useForm({
		defaultValues,
	});

	const filter = watch('schools');

	const debouncedUpdate = useCallback(
		debounce((list?: string[]) => {
			setSchools(list || []);
		}, 300),
		[]
	);

	useEffect(() => {
		debouncedUpdate(filter);
	}, [filter, debouncedUpdate]);

	return {
		register,
		getValues,
		control,
		schools,
	};
};
