import React, { FC, useCallback, useEffect } from 'react';
import { FieldError, FieldValues, ResolverOptions, useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { UserRolesEnum } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import {
	containerButtonStyles,
	leftButtonStyles,
	inputBoxStyles,
	modalBoxStyles,
	errorTextStyles,
} from './modal-form.styles';
import { CoreSelect, CoreSelectMenuItem } from '../../../_core/components/_ui/core-select';
import { buttonModalWindowStyles, mainTitleModalWindowStyles } from '../../../_core/styles';
import { mergeSx } from 'merge-sx';

export type UserFormModalProps = CoreModalProps & {
	formTitle: string;
	resolver: <TFieldValues extends FieldValues, TContext>(
		values: TFieldValues,
		context: TContext | undefined,
		options: ResolverOptions<TFieldValues>
	) => any;
	submitAction(values: any): Promise<void>;
	closeModal(): void;
	defaultValues?: any;
	formError?: any;
	submitButtonName?: string;
};

export const UserFormModal: FC<UserFormModalProps> = (props) => {
	const [t] = useTranslation();
	const { formTitle, resolver, submitAction, formError, closeModal, defaultValues, submitButtonName, ...modalProps } =
		props;

	const {
		register,
		handleSubmit,
		watch,
		setError,
		formState: { errors, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = useForm({
		defaultValues,
		resolver,
		mode: 'all',
	});


	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const handleFormSubmit = useCallback(
		async (values: any) => {
			await submitAction(values);
		},
		[submitAction, setError]
	);

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0, px: 5, pb: 5 }} paperSx={{ width: '100%', maxWidth: '500px' }}>
			<Box
				component="form"
				onSubmit={handleSubmit(handleFormSubmit)}
				sx={{ display: 'flex', flexDirection: 'column', gap: 5, width: '100%' }}
			>
				<Box>
					<Typography variant="h5" sx={mainTitleModalWindowStyles}>
						{formTitle}
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
					<CoreTextField
						label={t('users.forms.labels.name')}
						requiredMark
						fullWidth
						placeholder={t('users.forms.placeholders.name') ?? ''}
						{...register('name')}
						error={!!errors['name']}
						helperText={errors['name'] ? (errors['name'] as FieldError).message : undefined}
					/>
					<CoreTextField
						label={t('users.forms.labels.email')}
						requiredMark
						fullWidth
						placeholder={t('users.forms.placeholders.email') ?? ''}
						{...register('email')}
						error={!!errors['email']}
						helperText={(errors['email'] as FieldError)?.message}
					/>
					<CoreSelect
						value={watch('role') || ''}
						label={t('users.forms.labels.role')}
						requiredMark
						fullWidth
						placeholder={t('users.forms.placeholders.role') ?? ''}
						{...register('role')}
						error={!!errors['role']}
						helperText={(errors['role'] as FieldError)?.message}
					>
						{Object.keys(UserRolesEnum)
							.filter((key) => key !== 'SuperAdmin')
							.map((key) => (
								<CoreSelectMenuItem key={key} value={UserRolesEnum[key as keyof typeof UserRolesEnum].toString()}>
									{key}
								</CoreSelectMenuItem>
							))}
					</CoreSelect>
				</Box>
				{formError && (
					<Typography sx={errorTextStyles}>
						{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
					</Typography>
				)}
				<Box sx={containerButtonStyles}>
					<CoreButton onClick={closeModal} variant="secondary" sx={mergeSx(leftButtonStyles, buttonModalWindowStyles)}>
						{t('users.forms.buttons.close-form')}
					</CoreButton>
					<CoreButton
						type="submit"
						contraModeStyleButton
						disabled={disableSubmit}
						loading={loadingSubmit}
						sx={buttonModalWindowStyles}
					>
						{submitButtonName || t('users.forms.buttons.save')}
					</CoreButton>
				</Box>
			</Box>
		</CoreModal>
	);
};
