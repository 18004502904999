import { Box, Container, Paper, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProvidersDetails } from '../../../providers/components/providers-details/providers-details.component';
import {
	useGenerateEngagementsCustomFieldFileUrlMutation,
	useGetEngagementQuery,
	useUpdateEngagementMutation,
} from '../../../../store/api/engagements.api';
import { EngagementDetails } from '../../components/engagement-details/engagement-details.component';
import { useSortingParams } from '../../../_core/hooks';
import { FamilyDetailsList } from '../../../family/components/family-details/family-details-list.component';
import {
	useGetAllSurveyTemplatesListQuery,
	useCreateEngagementSurveyMutation,
	useCheckExistEngagementSurveyQuery,
	useSendSurveyToFamiliesMutation,
	useGetListEngagementFamiliesWithSurveyQuery,
	useSendSurveyByEmailMutation,
	useSendSurveyBySmsMutation,
	useSendSurveyByPreferedMethodMutation,
	useGetSurveySummaryQuery,
} from '../../../../store/api/survey.api';
import { useParams } from 'react-router-dom';
import { useSessionStorage } from '../../../../hooks';
import { skipToken } from '@reduxjs/toolkit/query';
import { NotFoundComponent, ForbiddenComponent } from '../../../_core/components/exceptions';
import { useGetAllFieldsQuery } from '../../../../store/api/customRequirements.api';
import { useGetAllProvidersByDistrictIdQuery } from '../../../../store/api/providers.api';
import {
	useGetAllFamiliesByDistrictIdQuery,
	useGetChildrenByDistrictIdQuery,
} from '../../../../store/api/families.api';
import { useOpenModalForm } from '../../hooks/modal-forms/use-open-modal-form.hook';
import { AvatarIcon } from '../../../_core/constants/icons.constants';

export const EngagementDetailsPage: FC = () => {
	const [t] = useTranslation();
	const [sessionStorageDistrictId, setSessionStorageDistrictId] = useSessionStorage('districtId');

	const { engagementId } = useParams();

	const {
		data: currentEngagement,
		isError,
		error,
	} = useGetEngagementQuery({ id: engagementId }, { skip: !engagementId });

	useEffect(() => {
		if (currentEngagement?.districtId) {
			setSessionStorageDistrictId(currentEngagement?.districtId);
		}
	}, [currentEngagement, setSessionStorageDistrictId]);

	const { data: existSurvey, error: existSurveyError } = useCheckExistEngagementSurveyQuery(engagementId ?? skipToken);
	const { data: surveyTemplatesList } = useGetAllSurveyTemplatesListQuery(currentEngagement?.districtId ?? skipToken);
	const { sortingArray, handleSortingChange, sortingParams } = useSortingParams();

	const { data: familiesWithSurvey, isFetching } = useGetListEngagementFamiliesWithSurveyQuery(
		{ id: engagementId, ...sortingParams },
		{ skip: !engagementId }
	);

	const { data: surveySum } = useGetSurveySummaryQuery(engagementId ?? skipToken);

	const [createEngagementSurvey] = useCreateEngagementSurveyMutation();
	const [sendSurveyToFamilies] = useSendSurveyToFamiliesMutation();
	const [sendSurveyByEmail] = useSendSurveyByEmailMutation();
	const [sendSurveyBySms] = useSendSurveyBySmsMutation();
	const [sendSurveyByPreferedMethod] = useSendSurveyByPreferedMethodMutation();

	const { data: fieldsListFromDistrict } = useGetAllFieldsQuery(
		{ districtId: currentEngagement?.districtId },
		{ skip: !currentEngagement?.districtId }
	);
	const { data: providersList } = useGetAllProvidersByDistrictIdQuery(
		{ districtId: currentEngagement?.districtId },
		{ skip: !currentEngagement?.districtId }
	);
	const { data: familiesList } = useGetAllFamiliesByDistrictIdQuery(
		{ district: currentEngagement?.districtId },
		{ skip: !currentEngagement?.districtId }
	);

	const { data: childrenList } = useGetChildrenByDistrictIdQuery(
		{ districtId: currentEngagement?.districtId },
		{ skip: !currentEngagement?.districtId }
	);

	const [generateEngagementsCustomFieldFileUrl] = useGenerateEngagementsCustomFieldFileUrlMutation();

	const [updateEngagement] = useUpdateEngagementMutation();
	const featchActionEdit = async (data: any) => {
		await updateEngagement({ id: data?._id?.toString(), data }).unwrap();
	};
	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		districtId: currentEngagement?.districtId,
		isCreate: false,
		submitButtonName: t('engagements.forms.buttons.save'),
		toastText: 'Engagement record was successfully updated',
		fieldsListFromDistrict,
		providersList,
		familiesList,
		childrenList,
		generateFileUrlFunction: generateEngagementsCustomFieldFileUrl,
	});

	if (isError) {
		switch ((error as any).data.statusCode) {
			case 404:
			case 400:
				return <NotFoundComponent componentName="engagement" />;
			case 403:
				return <ForbiddenComponent componentName="engagement" />;
			default:
				break;
		}
	}

	return (
		<Container
			maxWidth={false}
			disableGutters
			sx={{
				display: 'flex',
				width: '100%',
				padding: 0,
				margin: 0,
				height: '100vh',
				overflow: 'auto',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '50%',
					height: '100%',
					mr: 2,
				}}
			>
				<Box sx={{ mb: 2 }}>
					<Paper elevation={0} sx={{ width: '100%', height: '100%' }}>
						{currentEngagement?.providerId ? (
							<ProvidersDetails providerId={currentEngagement.providerId} />
						) : (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '100%',
									gap: 3,

									padding: 3,
								}}
							>
								<Typography variant="h6" sx={{ fontWeight: 500, fontSize: '16px' }}>
									{t('providers.details')}
								</Typography>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										gap: 3,
									}}
								>
									<Box
										style={{
											width: 48,
											height: 48,
											borderRadius: '16px',
											backgroundColor: 'var(--blue-8-opacity, rgba(36, 57, 106, 0.08))',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<AvatarIcon />
									</Box>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<Typography
											sx={{ fontWeight: 500, fontSize: '14px', color: (theme) => theme.colors.grayLightBlue }}
										>
											Provider not chosen yet
										</Typography>
									</Box>
								</Box>
							</Box>
						)}
					</Paper>
				</Box>
				<Paper
					elevation={0}
					sx={{
						width: '100%',
						flexGrow: 1,
						pt: 2,
						pb: 2,
						pr: 3,
						pl: 3,
						borderBottomRightRadius: 0,
						borderBottomLeftRadius: 0,
						overflowY: 'auto',
					}}
				>
					<EngagementDetails
						engagement={currentEngagement}
						handleEditEngagement={() => {
							handleOpenModalEdit(currentEngagement);
						}}
					/>
				</Paper>
			</Box>
			<Box sx={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}>
				<Box sx={{ mb: 1 }}>
					<Paper elevation={0} sx={{ width: '100%', height: '100%', pt: 1, pb: 1 }}>
						<Box sx={{ ml: 3, mb: 1 }}>
							<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
								{t('surveys.survey-summary')}
							</Typography>
							<Typography variant="body1">
								{t('surveys.survey-answered')} {surveySum?.answeredSurveys} of {surveySum?.totalSurveys}
							</Typography>
						</Box>
					</Paper>
				</Box>
				<Paper elevation={0} sx={{ width: '100%', height: '100%' }}>
					<FamilyDetailsList
						engagementId={currentEngagement?._id.toString() ?? ''}
						engagementStatus={currentEngagement?.status ?? ''}
						itemsList={familiesWithSurvey}
						isFetching={isFetching}
						engagementSurvey={existSurveyError ? undefined : existSurvey}
						createSurveyAction={createEngagementSurvey}
						surveyTemplatesList={surveyTemplatesList}
						sendSurveyAction={sendSurveyToFamilies}
						sendSurveyByEmailAction={sendSurveyByEmail}
						sendSurveyBySmsAction={sendSurveyBySms}
						sortingArray={sortingArray}
						handleSortingChange={handleSortingChange}
						sendSurveyByPreferedMethodAction={sendSurveyByPreferedMethod}
					/>
				</Paper>
			</Box>
		</Container>
	);
};
