import React, { useCallback, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { Box, MenuItem, Typography, Chip } from '@mui/material';
import {
	LanguageEnum,
	ProviderDto,
	ProviderInfoDto,
	WorkStatusEnum,
	ServiceTypeStatusEnum,
} from '@families-link/shared';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { CoreSelect } from '../../../_core/components/_ui/core-select';
import UploadPhoto from '../../../_core/components/upload-photo/upload-photo.component';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import { AutocompleteTextField, CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { mergeSx } from 'merge-sx';
import { buttonModalWindowStyles, chipDeleteIconStyles, mainTitleModalWindowStyles } from '../../../_core/styles';
import { useGenerateProviderPhotoUrlMutation } from '../../../../store/api/providers.api';
import { ClearIcon } from '@mui/x-date-pickers';
import { CoreCheckbox } from '../../../_core/components/_ui/core-checkbox';
import { ChipCloseIcon, InHouseIcon, OutsourceIcon, VerifiedIcon } from '../../../_core/components/icons';

export type AddProviderFormProps = CoreModalProps & {
	formError?: any;
	defaultValues: any;
	formTitle: string;
	submitButtonName?: string;
	resolver?: any;
	submitAction(formData: ProviderInfoDto | ProviderDto): Promise<void>;
	closeModal(): void;
};

export const AddProviderForm: React.FC<AddProviderFormProps> = ({
	submitAction,
	formError,
	resolver,
	closeModal,
	formTitle,
	submitButtonName,
	defaultValues,
	...modalProps
}) => {
	const {
		register,
		handleSubmit,
		setError,
		control,
		getValues,
		formState: { errors, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = useForm({
		resolver,
		defaultValues,
		mode: 'all',
	});

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const [t] = useTranslation();
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [isPhotoDeleted, setIsPhotoDeleted] = useState(false);
	const [generateProviderPhotoUrl] = useGenerateProviderPhotoUrlMutation();

	const handleFormSubmit = useCallback(
		async (values: ProviderInfoDto | ProviderDto) => {
			const providerValues: ProviderInfoDto | ProviderDto = { ...values };
			const formData = new FormData();

			if (selectedFile) {
				formData.append('file', selectedFile);
				try {
					const { url: imageUrl } = await generateProviderPhotoUrl(formData).unwrap();

					if (imageUrl) {
						providerValues.imageUrl = imageUrl;
					}
				} catch (error) {
					providerValues.imageUrl = '';
					console.log(error);
				}
			}

			if (selectedFile === null && !!isPhotoDeleted) {
				providerValues.imageUrl = '';
			}

			try {
				await submitAction(providerValues);
			} catch (error) {
				console.error(error);
			}
		},
		[submitAction, setError, selectedFile, isPhotoDeleted]
	);

	const handleFileChange = (file: File | null) => {
		if (file) {
			setSelectedFile(file);
		} else {
			setSelectedFile(null);
			setIsPhotoDeleted(true);
		}
	};

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0, px: 5, pb: 5 }} paperSx={{ width: '100%' }}>
			<Typography variant="h5" sx={mergeSx(mainTitleModalWindowStyles, { mb: 5 })}>
				{formTitle}
			</Typography>
			<Box
				component="form"
				onSubmit={handleSubmit(handleFormSubmit)}
				sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}
			>
				<Box sx={{ width: '236px', maxHeight: 364 }}>
					<UploadPhoto onFileChange={handleFileChange} editable file={defaultValues.imageUrl ?? null} />
				</Box>
				<Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', width: '360px', gap: '24px', mb: '40px' }}>
						<CoreTextField
							label={t('providers.full-name')}
							{...register('fullName')}
							placeholder={t('providers.placeholders.full-name') ?? ''}
							requiredMark
							fullWidth
							error={!!errors['fullName']}
							helperText={errors['fullName'] ? (errors['fullName'] as FieldError).message : undefined}
						/>

						<CoreTextField
							label={t('providers.email')}
							{...register('email')}
							placeholder={t('providers.placeholders.email') ?? ''}
							requiredMark
							fullWidth
							error={!!errors['email']}
							helperText={errors['email'] ? (errors['email'] as FieldError).message : undefined}
						/>
						<Controller
							name={'targetLanguage'}
							control={control}
							render={({ field }) => (
								<CoreAutoComplete
									{...field}
									label={t('providers.target-lang')}
									multiple={true}
									requiredMark
									fullWidth
									{...register('targetLanguage')}
									error={!!errors['targetLanguage']}
									helperText={errors['targetLanguage'] ? (errors['targetLanguage'] as FieldError).message : undefined}
									options={Object.values(LanguageEnum)}
									control={control}
									getOptionLabel={(option) => {
										const language = getObjectLanguage(option);

										return language ? language.name : '';
									}}
									selectOnFocus={true}
									isOptionEqualToValue={(option, value) => option === value}
									renderInput={(params) => (
										<AutocompleteTextField
											{...params}
											error={!!errors['targetLanguage']}
											placeholder={
												getValues('targetLanguage')?.length ? '' : t('providers.placeholders.prefered-language')
											}
											sx={{
												'& .MuiOutlinedInput-root': {
													'&.Mui-focused fieldset': {
														borderColor: (theme) => theme.colors.blue,
													},
												},
											}}
										/>
									)}
									renderOption={(props, option, { selected }) => {
										const language = getObjectLanguage(option);

										return (
											<MenuItem {...props} key={language?.value} value={language?.value}>
												<CoreCheckbox checked={selected} sx={{ mr: 2, p: 0, ml: 0 }} />
												{language?.name}
											</MenuItem>
										);
									}}
									renderTags={(value, getTagProps) => {
										const count = value?.length - 1;

										return [
											value[0] && (
												<Chip
													sx={chipDeleteIconStyles}
													{...getTagProps({ index: 0 })}
													size="small"
													label={value[0]}
													deleteIcon={<ChipCloseIcon />}
												/>
											),
											count > 0 && (
												<Chip
													sx={chipDeleteIconStyles}
													{...getTagProps({ index: count })}
													size="small"
													label={`+${count}`}
													deleteIcon={<ChipCloseIcon />}
												/>
											),
										];
									}}
									clearIcon={
										<ClearIcon sx={{ fontSize: '16px', '& .MuiAutocomplete-clearIndicator': { padding: '2px' } }} />
									}
								/>
							)}
						/>

						<CoreSelect
							label={t('providers.verification-type')}
							{...register('verificationType')}
							requiredMark
							placeholder={t('providers.placeholders.options') ?? ''}
							error={!!errors['verificationType']}
							defaultValue={defaultValues.verificationType.toString()}
							helperText={errors['verificationType'] ? (errors['verificationType'] as FieldError).message : undefined}
							fullWidth
						>
							<MenuItem value="true">
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography component="span">{t('providers.verified')} </Typography>
									<VerifiedIcon sx={{ fontSize: '18px', marginLeft: '8px' }} />
								</Box>
							</MenuItem>
							<MenuItem value="false">{t('providers.no-verified')}</MenuItem>
						</CoreSelect>

						<Controller
							name={'workStatus'}
							control={control}
							render={({ field }) => (
								<CoreSelect
									label={t('providers.work-status')}
									{...field}
									{...register('workStatus')}
									fullWidth
									requiredMark
									placeholder={t('providers.placeholders.options') ?? ''}
									error={!!errors['workStatus']}
									helperText={errors['workStatus'] ? (errors['workStatus'] as FieldError).message : undefined}
								>
									<MenuItem value={WorkStatusEnum.InHouse}>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Typography component="span">{t('providers.work-in-house')}</Typography>
											<InHouseIcon sx={{ fontSize: '16px', marginLeft: '8px' }} />
										</Box>
									</MenuItem>
									<MenuItem value={WorkStatusEnum.Outsource}>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Typography>{t('providers.work-outsource')}</Typography>
											<OutsourceIcon sx={{ fontSize: '16px', marginLeft: '8px' }} />
										</Box>
									</MenuItem>
								</CoreSelect>
							)}
						/>
						<Controller
							name={'serviceType'}
							control={control}
							render={({ field }) => (
								<CoreSelect
									label={'Service Type'}
									{...field}
									{...register('serviceType')}
									fullWidth
									requiredMark
									placeholder={t('providers.placeholders.options') ?? ''}
									error={!!errors['serviceType']}
									helperText={errors['serviceType'] ? (errors['serviceType'] as FieldError).message : undefined}
								>
									<MenuItem value={ServiceTypeStatusEnum.Appointment}>Appointment</MenuItem>
									<MenuItem value={ServiceTypeStatusEnum.Project}>Project</MenuItem>
									<MenuItem value={ServiceTypeStatusEnum.Both}>Both</MenuItem>
								</CoreSelect>
							)}
						/>
					</Box>
					{formError && (
						<Typography sx={{ fontWeight: 'bold', color: (theme) => theme.colors.red, pb: 2 }}>
							{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
						</Typography>
					)}
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-end',
							alignItems: 'center',
							gap: 3,
						}}
					>
						<CoreButton variant="secondary" onClick={closeModal} sx={buttonModalWindowStyles}>
							{t('providers.close')}
						</CoreButton>

						<CoreButton
							type="submit"
							contraModeStyleButton
							loading={loadingSubmit}
							disabled={disableSubmit}
							sx={buttonModalWindowStyles}
						>
							{submitButtonName}
						</CoreButton>
					</Box>
				</Box>
			</Box>
		</CoreModal>
	);
};
