import {
	CreateCustomFieldDto,
	CustomFieldDto,
	CustomFieldInfoDto,
	DefaultFieldInfoDto,
	IdDto,
	OrderEnum,
	UpdateCustomFieldDto,
} from '@families-link/shared';
import api from '.';

const customRequirementsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getAllFields: builder.query<
			CustomFieldDto[],
			{ districtId?: string | null; sortBy?: string; sortOrder?: OrderEnum.ASC | OrderEnum.DESC }
		>({
			query: ({ districtId, sortBy, sortOrder }) => ({
				url: 'custom-fields',
				params: { districtId, sortBy, sortOrder },
			}),
			providesTags: ['CustomField'],
		}),

		createCustomField: builder.mutation<CustomFieldDto, CreateCustomFieldDto>({
			query: (newCustomField) => ({
				url: 'custom-fields',
				method: 'POST',
				body: newCustomField,
			}),
			invalidatesTags: ['CustomField'],
		}),

		updateCustomField: builder.mutation<CustomFieldDto, { id: string; updateData: UpdateCustomFieldDto }>({
			query: ({ id, updateData }) => ({
				url: `custom-fields/${id}`,
				method: 'PUT',
				body: updateData,
			}),
			invalidatesTags: ['CustomField'],
		}),

		deleteCustomField: builder.mutation<IdDto, string>({
			query: (id) => ({
				url: `custom-fields/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['CustomField'],
		}),
		getAllDefaultFields: builder.query<
			DefaultFieldInfoDto[],
			{ sortBy?: string; sortOrder?: OrderEnum.ASC | OrderEnum.DESC }
		>({
			query: ({ sortBy, sortOrder }) => ({
				url: 'default-fields',
				params: { sortBy, sortOrder },
			}),
		}),
	}),
	overrideExisting: false,
});

export default customRequirementsApi;
export const {
	useGetAllFieldsQuery,
	useCreateCustomFieldMutation,
	useUpdateCustomFieldMutation,
	useDeleteCustomFieldMutation,
	useGetAllDefaultFieldsQuery,
} = customRequirementsApi;
