import {
	Length,
	IsPhoneNumber,
	IsEmail,
	IsNotEmpty,
	IsOptional,
	IsArray,
	ValidateNested,
	ArrayNotEmpty,
	ValidateIf,
} from 'class-validator';
import { Type } from 'class-transformer';
import { UpdateChildDto } from './update-child.dto';
import { IsValidSendMethod } from '../../decorators/prefered-send-method.decorator';

export class UpdateFamilyDto {
	@IsOptional()
	_id: string;

	@IsNotEmpty()
	districtId: string;

	@Length(1, 100)
	@IsNotEmpty({ message: 'Full Name is required' })
	fullName: string;

	@ValidateIf((obj) => !!obj.email)
	@IsEmail({}, { message: 'Incorrect Email' })
	email: string;

	@IsPhoneNumber(undefined, { message: 'Incorrect Phone Number' })
	phoneNumber: string;

	@Length(1)
	@IsNotEmpty({ message: 'Location is required' })
	location: string;

	@ArrayNotEmpty({ message: 'Preferred languages are required' })
	preferedLanguage: string[];

	@IsValidSendMethod()
	@IsNotEmpty({ message: 'Preferred Send Method is required' })
	preferedSendMethod: string;

	@IsOptional()
	imageUrl?: string;

	@IsArray()
	@ArrayNotEmpty()
	@ValidateNested({ each: true })
	@Type(() => UpdateChildDto)
	children: Array<UpdateChildDto>;
}
