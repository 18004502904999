import { FC, useCallback, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FamiliesDetailsTable } from './families-details-table/families-details-table.component';
import {
	EngagementStatusEnum,
	EngagementSurveyDto,
	FamilyWithSurveyDto,
	SurveyStateStatusEnum,
	SurveyStatusEnum,
	SurveyTemplateDto,
} from '@families-link/shared';
import { useMuiModal } from '../../../_core/hooks';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useSnackbar } from 'notistack';
import {
	CreateSurveyFormModal,
	CreateSurveyFormModalProps,
} from '../../../survey/components/survey-tracking/modal-foms/create-survey-form.component';
import { ResultsSurvey } from '../../../survey/components/survey-tracking/survey-results.component';
import { SortingState } from '@tanstack/react-table';
import { SurveyRoutes } from '../../../survey/constants';
import { generatePath } from 'react-router-dom';
import { useOpenConfirmModal } from '../../hooks/modal-forms/use-open-confirm-modal-form.hook';
import { useDeleteEngagementSurveyMutation } from '../../../../store/api/survey.api';

interface FamilyDetailsListProps {
	engagementId: string;
	engagementStatus: string;
	itemsList?: FamilyWithSurveyDto[];
	engagementSurvey?: EngagementSurveyDto;
	isFetching: boolean;
	surveyTemplatesList?: SurveyTemplateDto[];
	sortingArray: SortingState;
	sendSurveyAction?: any;
	createSurveyAction?: any;
	sendSurveyByEmailAction?: any;
	sendSurveyBySmsAction?: any;
	handleSortingChange: any;
	sendSurveyByPreferedMethodAction: any;
}

export const FamilyDetailsList: FC<FamilyDetailsListProps> = (props) => {
	const {
		engagementId,
		engagementStatus,
		isFetching,
		itemsList = [],
		engagementSurvey,
		sendSurveyAction,
		createSurveyAction,
		sendSurveyByEmailAction,
		sendSurveyBySmsAction,
		surveyTemplatesList,
		sortingArray,
		handleSortingChange,
		sendSurveyByPreferedMethodAction,
	} = props;
	const disabledStatuses = [
		SurveyStatusEnum.DeliveredEmail,
		SurveyStatusEnum.DeliveredEmailAndSms,
		SurveyStatusEnum.DeliveredSms,
		SurveyStatusEnum.Completed,
	];
	const [t] = useTranslation();
	const [resultComponent, setResultComponent] = useState(false);
	const [currentFamilyWithSurvey, setCurrentFamilyWithSurvey] = useState<FamilyWithSurveyDto>();
	const { enqueueSnackbar } = useSnackbar();

	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
		});
	};

	const { openModal, closeModal, updateCurrentModal } = useMuiModal<CreateSurveyFormModalProps>(CreateSurveyFormModal);

	const handleSubmitCreate = async (values: any): Promise<void> => {
		try {
			await createSurveyAction({
				surveyTemplateId: values.surveyTemplate._id,
				engagementId,
			}).unwrap();
			showToast('Survey for this engagement was successfully created', 'success');
			closeModal();
		} catch (error: any) {
			updateCurrentModal({ formError: error });
		}
	};

	const handleSubmitSend = async (): Promise<void> => {
		try {
			if (!engagementSurvey?.surveyTemplate) {
				showToast(`This survey was deleted from system, you can't send it to family`, 'error');

				return;
			}
			await sendSurveyAction({
				engagementId,
			}).unwrap();
			showToast('Survey was successfully sent to families', 'success');
		} catch (error: any) {
			console.log(error);
			showToast('Survey sending was failed', 'error');
		}
	};

	const handleOpenModalCreate = useCallback(() => {
		openModal({
			surveyTemplatesList,
			submitAction: handleSubmitCreate,
			closeModal,
		});
	}, [openModal, closeModal, surveyTemplatesList, handleSubmitCreate]);

	const [deleteEngagementSurvey] = useDeleteEngagementSurveyMutation();
	const featchActionDisconnect = async () => {
		if (engagementSurvey) {
			await deleteEngagementSurvey({ id: engagementSurvey._id }).unwrap();
		}
	};
	const handleOpenDisconnectConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDisconnect,
		title: t('surveys.forms.disconnect-title'),
		description: t('surveys.forms.disconnect-description'),
		submitButtonName: t('surveys.forms.buttons.disconnect'),
		toastText: 'Survey was successfully disconnected',
	});

	const handleOnSendEmail = async (familyWithSurvey: FamilyWithSurveyDto) => {
		try {
			if (!familyWithSurvey?.surveyTemplate) {
				showToast(`This survey was deleted from system, you can't send it to family`, 'error');

				return;
			}

			await sendSurveyByEmailAction({
				email: familyWithSurvey.family.email,
				surveyId: familyWithSurvey.survey?._id,
			}).unwrap();
			showToast('Survey was successfully sent to family by email', 'success');
		} catch (error) {
			showToast('Survey sending was failed', 'error');
		}
	};

	const handleOnSendSms = async (familyWithSurvey: FamilyWithSurveyDto) => {
		try {
			if (!familyWithSurvey?.surveyTemplate) {
				showToast(`This survey was deleted from system, you can't send it to family`, 'error');

				return;
			}

			await sendSurveyBySmsAction({
				phoneNumber: familyWithSurvey.family.phoneNumber,
				surveyId: familyWithSurvey.survey?._id,
			}).unwrap();
			showToast('Survey was successfully sent to family by sms', 'success');
		} catch (error) {
			showToast('Survey sending was failed', 'error');
		}
	};

	const handleSendByPreferedMethod = async (familyWithSurvey?: FamilyWithSurveyDto) => {
		try {
			if (!familyWithSurvey?.surveyTemplate) {
				showToast(`This survey was deleted from system, you can't send it to family`, 'error');

				return;
			}
			await sendSurveyByPreferedMethodAction({
				familyId: familyWithSurvey?.family._id,
				surveyId: familyWithSurvey?.survey?._id,
			}).unwrap();
			showToast('Survey was successfully sent to family by prefered method', 'success');
		} catch (error) {
			showToast('Survey sending was failed', 'error');
		}
	};

	const handleOnManualEditing = async (familyWithSurvey?: FamilyWithSurveyDto) => {
		try {
			const surveyLink = generatePath(SurveyRoutes.ManualEditing, {
				surveyId: familyWithSurvey?.survey?._id,
				familyId: familyWithSurvey?.family?._id,
			});
			window.open(surveyLink, '_blank');
		} catch (error) {
			console.log(error);
		}
	};

	const handleOnView = async (familyWithSurvey: FamilyWithSurveyDto) => {
		setCurrentFamilyWithSurvey(familyWithSurvey);
		setResultComponent(true);
	};

	const returnFromResults = () => {
		setCurrentFamilyWithSurvey(undefined);
		setResultComponent(false);
	};

	return (
		<Box sx={{ height: '100%' }}>
			{resultComponent ? (
				<ResultsSurvey
					currentFamilyWithSurvey={currentFamilyWithSurvey}
					currentEngagementId={engagementId}
					handleOpenModalCreate={handleOpenModalCreate}
					handleSubmitSend={handleSendByPreferedMethod}
					handleOnManualEditing={handleOnManualEditing}
					closeResultsComponent={returnFromResults}
				/>
			) : (
				<Container
					maxWidth={false}
					sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'flex-start',
							width: '100%',
							mt: 1,
						}}
					>
						<Box>
							<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
								{t('families.family-contact')}
							</Typography>
						</Box>
						<Box sx={{ ml: 'auto', mr: 1 }}>
							{!engagementSurvey ? (
								<CoreButton
									disabled={engagementStatus === EngagementStatusEnum.Incomplete}
									onClick={handleOpenModalCreate}
								>
									Connect Survey
								</CoreButton>
							) : (
								<CoreButton
									onClick={handleOpenDisconnectConfirmModal}
									disabled={
										itemsList?.filter((familyWithSurvey: FamilyWithSurveyDto) =>
											disabledStatuses.includes(familyWithSurvey.surveyStatus)
										)?.length > 0
									}
								>
									Disconnect Survey
								</CoreButton>
							)}
						</Box>
						<Box sx={{ mr: 1 }}>
							<CoreButton
								onClick={handleSubmitSend}
								disabled={
									itemsList?.filter(
										(familyWithSurvey: FamilyWithSurveyDto) =>
											familyWithSurvey.surveyStatus === SurveyStatusEnum.NotShared
									)?.length === 0
								}
							>
								Send Survey
							</CoreButton>
						</Box>
					</Box>

					<FamiliesDetailsTable
						data={itemsList ?? []}
						loading={isFetching}
						onSendEmail={handleOnSendEmail}
						onSendSms={handleOnSendSms}
						onEdit={handleOnManualEditing}
						onView={handleOnView}
						sorting={sortingArray}
						onSortingChange={handleSortingChange}
					/>
				</Container>
			)}
		</Box>
	);
};
