import React, { FC, forwardRef, ReactNode, RefAttributes, useState } from 'react';
import { CoreFormControl, CoreFormControlProps } from '../core-form-control';
import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from '@mui/material';
import { DropdownArrow } from './dropdown-arrow.component';
import { Controller, ControllerProps } from 'react-hook-form';
import { FilterIconComponent } from './filter-icon.component';
import { mergeSx } from 'merge-sx';

export interface CoreAutocompleteProps
	extends CoreFormControlProps,
		Omit<ControllerProps, 'render'>,
		AutocompleteProps<any, boolean, boolean, boolean> {
	options: any[];
	ruleIsRequired?: boolean;
	ruleName?: string;
	multiple?: boolean;
	disableClearable?: boolean;
	freeSolo?: boolean;
	IconComponent?: any;
	small?: boolean;
	isFilter?: boolean;
}

const validateRequired = (value: any, name?: string) => {
	if (!value) {
		return `${name || 'This field'} is required`;
	}
	return true;
};

export const CoreAutoComplete: FC<Omit<CoreAutocompleteProps, 'ref'> & RefAttributes<unknown>> = forwardRef(
	(props, ref) => {
		const {
			options,
			ruleIsRequired,
			ruleName,
			multiple = false,
			disableClearable = false,
			freeSolo = false,
			focused,
			helperText,
			error,
			controlSx,
			disabled,
			fullWidth,
			label,
			labelHint,
			requiredMark,
			id,
			IconComponent,
			name,
			control,
			small,
			isFilter,
			...rest
		} = props;

		const [manualFocused, setManualFocused] = useState(false);

		return (
			<CoreFormControl
				label={label}
				labelHint={labelHint}
				id={id}
				requiredMark={requiredMark}
				fullWidth={fullWidth}
				focused={manualFocused}
				controlSx={controlSx}
				helperText={helperText}
				error={error}
				disabled={disabled}
			>
				<Controller
					name={name}
					control={control}
					rules={
						ruleIsRequired ? { validate: (value) => validateRequired(value, ruleName) } : { validate: (value) => true }
					}
					render={({ field }) => (
						<Autocomplete
							{...rest}
							{...field}
							ref={ref}
							multiple={multiple}
							disableCloseOnSelect={multiple}
							disabled={disabled}
							disableClearable={disableClearable}
							freeSolo={freeSolo}
							options={options}
							onFocus={() => setManualFocused(true)}
							onBlur={() => setManualFocused(false)}
							onChange={(_, value) => field.onChange(value)}
							popupIcon={isFilter ? <FilterIconComponent /> : <DropdownArrow />}
							className="autocomplete"
							sx={mergeSx(
								{
									'&.MuiAutocomplete-root .MuiChip-label': {
										p: 0,
									},
									'& .MuiAutocomplete-tag': {
										height: `${small ? 16 : 20}px`,
										display: 'flex',
										padding: `${small ? 2 : 4}px ${small ? 3 : 6}px`,
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: '12px',
										backgroundColor: (theme) => theme.colors.lightBlue,
										color: (theme) => theme.colors.white,
										fontSize: '12px',
										gap: '6px',
										margin: '1px',
										maxWidth: '90px'
									},
									'& .MuiOutlinedInput-root ': {
										padding: `11px ${small ? 12 : 16}px 11px ${small ? 12 : 16}px`,
									},
									'& .MuiOutlinedInput-root  .MuiOutlinedInput-input': {
										height: `${small ? 20 : 26}px`,
										display: 'flex',
										alignItems: 'center',
									},

									'&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': {
										paddingRight: `${small ? 45 : 65}px`,
									},

									'&.MuiAutocomplete-clearIndicator': {
										padding: '2px',
									},
									'& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
										right: '16px',
									},
									'& fieldset': {
										height: '100%',
										top: '1px',
										borderColor: (theme) => (error ? theme.colors.red : theme.colors.blueTransparent),
										borderWidth: manualFocused ? 2 : 1,
									},
									'& legend': {
										height: 0,
									},
									'&.MuiAutocomplete-root .MuiOutlinedInput-root  .MuiAutocomplete-input': {
										padding: 0,
									},
									'&.MuiAutocomplete-endAdornment': {
										height: `${small ? 20 : 26}px`,
									},
									'&.MuiAutocomplete-root  .MuiAutocomplete-clearIndicator': {
										visibility: 'visible',
									},
									'&.MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
										minWidth: '10px',
									},
									'&.MuiAutocomplete-root .MuiChip-deleteIcon': {
										margin: 0,
									},
								},
								isFilter && {
									'&.MuiAutocomplete-root .MuiAutocomplete-popupIndicator': {
										stroke: (theme) => theme.colors.gray,
									},
									'&.MuiAutocomplete-root .MuiInputBase-adornedStart .MuiAutocomplete-popupIndicator': {
										stroke: (theme) => theme.colors.lightBlue,
									},
									'&.MuiAutocomplete-root .Mui-focused .MuiAutocomplete-popupIndicator': {
										stroke: (theme) => theme.colors.lightBlue,
									},
									'&.MuiAutocomplete-root .MuiAutocomplete-popupIndicatorOpen': {
										stroke: (theme) => theme.colors.lightBlue,
										transform: 'none',
									},
								}
							)}
						/>
					)}
				/>
			</CoreFormControl>
		);
	}
);

export const AutocompleteTextField: FC<TextFieldProps> = (props) => (
	<TextField
		{...props}
		sx={{
			'& .MuiOutlinedInput-root': {
				'&.Mui-focused fieldset': {
					borderColor: (theme) => theme.colors.lightBlue,
				},
				'&.Mui-disabled fieldset': {
					borderColor: (theme) => theme.colors.blueTransparent,
				},
			},
		}}
	/>
);
