import { FC, useRef, useCallback } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	useCreateCustomFieldMutation,
	useDeleteCustomFieldMutation,
	useGetAllFieldsQuery,
	useUpdateCustomFieldMutation,
} from '../../../store/api/customRequirements.api';
import { CustomRequirementsTable } from '../components/custom-requirements-table';
import { CoreButton } from '../../_core/components/_ui/core-button';
import AddIcon from '@mui/icons-material/Add';
import {
	AddCustomFieldForm,
	AddCustomFieldFormProps,
} from '../components/create-cutom-field/create-custom-field-form.component';
import { CreateCustomFieldDto, CustomFieldDto, CustomFieldInfoDto, UpdateCustomFieldDto } from '@families-link/shared';
import { useSnackbar } from 'notistack';
import { useMuiModal, useSortingParams } from '../../_core/hooks';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { ConfirmationModal, ConfirmationModalProps } from '../../_core/components/confirmation-modal';
import { DeleteIcon } from '../../_core/components/icons';
import { boxHeaderStyles, buttonHeaderStyles, buttonModalWindowStyles } from '../../_core/styles';
import { getLocalStorageDistrictId } from '../../_core/utils';
import { useSessionStorage } from '../../../hooks';
import { mergeSx } from 'merge-sx';

export const CustomRequirementsListPage: FC = () => {
	const [sessionStorageDistrictId] = useSessionStorage('districtId');

	const currentDistrictId = sessionStorageDistrictId ?? getLocalStorageDistrictId();
	const [t] = useTranslation();

	const { enqueueSnackbar } = useSnackbar();
	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();

	const {
		data: customFieldCurrentDistrictIdsList,
		isFetching,
		refetch,
	} = useGetAllFieldsQuery(
		{ districtId: currentDistrictId, sortBy: sortingParams.orderField, sortOrder: sortingParams.order },
		{ skip: !currentDistrictId }
	);

	const defaultCreateValues = {
		districtId: currentDistrictId,
		name: '',
		engagementType: '',
		isRequired: undefined,
		dataType: '',
		options: [],
	};
	const [createCustomField] = useCreateCustomFieldMutation();
	const resolverCreate = classValidatorResolver(CreateCustomFieldDto);

	const {
		openModal: openCreateModal,
		closeModal: closeCreateModal,
		updateCurrentModal: updateCreateModal,
	} = useMuiModal<AddCustomFieldFormProps>(AddCustomFieldForm);

	const handleAddCustomField = useCallback(
		async (newCustomField: CreateCustomFieldDto) => {
			try {
				await createCustomField({ ...newCustomField }).unwrap();
				enqueueSnackbar(t('custom-fields.added'), { variant: 'success' });
				closeCreateModal();
			} catch (error) {
				enqueueSnackbar(t('custom-fields.add-failed'), { variant: 'error' });
			}
		},
		[createCustomField, refetch, enqueueSnackbar, closeCreateModal]
	);

	const handleOpenModalCreate = useCallback(() => {
		openCreateModal({
			resolver: resolverCreate,
			defaultValues: defaultCreateValues,
			submitAction: handleAddCustomField,
			closeModal: closeCreateModal,
			formTitle: t('custom-fields.create-custom-field'),
		});
	}, [openCreateModal, handleAddCustomField]);

	const resolverEdit = classValidatorResolver(UpdateCustomFieldDto);

	const [updateCustomField] = useUpdateCustomFieldMutation();
	const { openModal: openEditModal, closeModal: closeEditModal } =
		useMuiModal<AddCustomFieldFormProps>(AddCustomFieldForm);

	const handleEditCustomField = useCallback(
		async (updatedCustomField: UpdateCustomFieldDto) => {
			try {
				await updateCustomField({ id: updatedCustomField._id, updateData: updatedCustomField }).unwrap();
				enqueueSnackbar('Custom Field updated successfully', { variant: 'success' });
				closeEditModal();
				refetch();
			} catch (error) {
				enqueueSnackbar('Failed to update custom field', { variant: 'error' });
			}
		},
		[updateCustomField]
	);

	const handleOpenModalEdit = useCallback(
		(customField: CustomFieldDto) => {
			const defaultValues = { ...customField, isRequired: customField.isRequired.toString() };
			const isDefaultType = customField.attributeType === 'Default';
			openEditModal({
				resolver: resolverEdit,
				defaultValues,
				submitAction: handleEditCustomField,
				closeModal: closeEditModal,
				formTitle: isDefaultType ? t('custom-fields.edit-default-field') : t('custom-fields.edit-custom-field'),
				isEditMode: true,
				isDefault: isDefaultType,
			});
		},
		[openEditModal, handleEditCustomField]
	);

	const { openModal: openConfirmDeleteModal, closeModal: closeConfirmDeleteModal } =
		useMuiModal<ConfirmationModalProps>(ConfirmationModal);

	const [deleteCustomField] = useDeleteCustomFieldMutation();

	const handleDelete = async (customField: any): Promise<void> => {
		try {
			if (customField._id) {
				await deleteCustomField(customField._id).unwrap();
				enqueueSnackbar('Custom Field deleted successfully', { variant: 'success' });

				refetch();
				closeConfirmDeleteModal();
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleOpenDeleteConfirmModal = useCallback(
		(customField: CustomFieldInfoDto) => {
			const handleSubmitDelete = async () => {
				await handleDelete(customField);
			};
			openConfirmDeleteModal({
				title: t('custom-fields.delete-title'),
				description: t('custom-fields.delete-description').replace(':Custom_fullname', customField.name),
				onSubmit: handleSubmitDelete,
				onClose: closeConfirmDeleteModal,
				icon: <DeleteIcon sx={{ fontSize: 40 }} />,
				customBackgrondColorKey: 'white',
				customColorKey: 'red',
				customBorderColorKey: 'red',
				danger: true,
				leftButtonProps: {
					children: t('families.forms.buttons.cancel'),
					variant: 'secondary',
					contraModeStyleButton: true,
					sx: buttonModalWindowStyles,
				},
				rightButtonProps: {
					children: t('families.forms.buttons.delete'),
					variant: 'danger',
					contraModeStyleButton: true,
					sx: buttonModalWindowStyles,
				},
			});
		},
		[handleDelete, openConfirmDeleteModal]
	);

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box sx={boxHeaderStyles}>
				<CoreButton onClick={handleOpenModalCreate} sx={mergeSx(buttonHeaderStyles, { ml: 'auto' })}>
					<AddIcon /> {t('custom-fields.add-custom')}
				</CoreButton>
			</Box>
			<CustomRequirementsTable
				data={customFieldCurrentDistrictIdsList ?? []}
				loading={isFetching}
				sorting={sortingArray}
				onSortingChange={handleSortingChange}
				onDelete={handleOpenDeleteConfirmModal}
				onEdit={handleOpenModalEdit}
			/>
		</Container>
	);
};
