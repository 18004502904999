export const SmsTemplates = {
	surveyLink: {
		en: (name: string, district: string, link: string) =>
			`Hi ${name}. Thanks for participating in an engagement at ${district}. Please let us know how we're doing. Complete the following survey: ${link}`,
		es: (name: string, district: string, link: string) =>
			`Hola ${name}! Gracias por participar en un compromiso en ${district}. Por favor, háganos saber cómo lo estamos haciendo. Complete la siguiente encuesta: ${link}`,
		ru: (name: string, district: string, link: string) =>
			`Привет ${name}! Спасибо за участие в мероприятии в ${district}. Пожалуйста, дайте нам знать, как мы справляемся. Заполните следующий опрос: ${link}`,
		uk: (name: string, district: string, link: string) =>
			`Привіт ${name}. Дякуємо за участь у заході в  ${district}. Будь ласка, дайте нам знати, як ми працюємо. Заповніть наступне опитування: ${link}`,
		vi: (name: string, district: string, link: string) =>
			`Xin chào ${name}.Cảm ơn bạn đã tham gia vào một sự kiện tại  ${district}. Vui lòng cho chúng tôi biết chúng tôi đang làm như thế nào. Hoàn thành khảo sát sau: ${link}`,
		zh: (name: string, district: string, link: string) =>
			`你好 ${name}！感谢您参与 ${district} 的活动。请告诉我们我们的表现如何。请完成以下调查：${link}`,
		ar: (name: string, district: string, link: string) =>
			`مرحبًا ${name}! شكرًا لمشاركتك في فعالية في ${district}. يرجى إخبارنا كيف نقوم بعملنا. أكمل الاستبيان التالي: ${link}`,
	},
	appointmentReminder: {
		en: (appointmentTime: string, appointmentSchool: string, providerFullName: string) =>
			`Reminder: you have an appointment tomorrow at ${appointmentTime} at ${appointmentSchool}. Your interpreter will be ${providerFullName}.`,
		es: (appointmentTime: string, appointmentSchool: string, providerFullName: string) =>
			`Recordatorio: tienes una cita mañana a las ${appointmentTime} en ${appointmentSchool}. Tu intérprete será ${providerFullName}.`,
		ru: (appointmentTime: string, appointmentSchool: string, providerFullName: string) =>
			`Напоминание: у Вас назначена встреча завтра в ${appointmentTime} в ${appointmentSchool}. Ваш переводчик – ${providerFullName}.`,
		uk: (appointmentTime: string, appointmentSchool: string, providerFullName: string) =>
			`Нагадування: у Вас запланована зустріч завтра о ${appointmentTime} у ${appointmentSchool}. Ваш перекладач – ${providerFullName}.`,
		vi: (appointmentTime: string, appointmentSchool: string, providerFullName: string) =>
			`Nhắc nhở: bạn có một cuộc hẹn vào ngày mai lúc ${appointmentTime} tại ${appointmentSchool}. Phiên dịch viên của bạn sẽ là ${providerFullName}.`,
		zh: (appointmentTime: string, appointmentSchool: string, providerFullName: string) =>
			`提醒：您明天在 ${appointmentTime} 于 ${appointmentSchool} 有一个预约。您的口译员是 ${providerFullName}。`,
		ar: (appointmentTime: string, appointmentSchool: string, providerFullName: string) =>
			`تذكير: لديك موعد غدًا في ${appointmentTime} في ${appointmentSchool}. سيكون مترجمك ${providerFullName}.`,
	},
};
