import React, { FC, useCallback, useEffect } from 'react';
import { Controller, FieldError, useFieldArray, useForm } from 'react-hook-form';
import { Box, MenuItem, Grid, Button, IconButton, Typography } from '@mui/material';
import {
	CustomFieldDataTypeArray,
	ServiceTypeStatusArray,
	CustomFieldDataTypeEnum,
	CreateCustomFieldDto,
	UpdateCustomFieldDto,
} from '@families-link/shared';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { CoreSelect } from '../../../_core/components/_ui/core-select';
import CloseIcon from '@mui/icons-material/Close';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { buttonModalWindowStyles, mainTitleModalWindowStyles } from '../../../_core/styles';
import { mergeSx } from 'merge-sx';

export type AddCustomFieldFormProps = CoreModalProps & {
	resolver: any;
	formError?: any;
	defaultValues?: any;
	formTitle?: string;
	isEditMode?: boolean;
	isDefault?: boolean;
	submitAction(customFields: CreateCustomFieldDto | UpdateCustomFieldDto): Promise<void>;
	closeModal(): void;
};

export const AddCustomFieldForm: FC<AddCustomFieldFormProps> = ({
	closeModal,
	submitAction,
	formError,
	defaultValues,
	resolver,
	formTitle,
	isEditMode = false,
	isDefault = false,
	...modalProps
}) => {
	const [t] = useTranslation();

	const {
		register,
		control,
		handleSubmit,
		setValue,
		watch,
		formState: { errors, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = useForm({
		defaultValues,
		resolver,
		mode: 'all',
	});

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const fieldType = watch('dataType');

	useEffect(() => {
		if (fieldType !== CustomFieldDataTypeEnum.Options && fieldType !== CustomFieldDataTypeEnum.MultiSelect) {
			setValue('options', []);
		}
	}, [fieldType, setValue]);

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'options',
	});

	const handleFormSubmit = useCallback(
		async (values: CreateCustomFieldDto | UpdateCustomFieldDto) => {
			const newValues = { ...values };
			await submitAction(newValues);
		},
		[submitAction]
	);

	return (
		<CoreModal onClose={closeModal} {...modalProps} bodySx={{ pt: 0, m: 2 }} paperSx={{ width: '100%' }}>
			<Typography variant="h5" sx={mergeSx(mainTitleModalWindowStyles, { mb: 5 })}>
				{formTitle}
			</Typography>
			<Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', mb: '40px' }}>
					<CoreTextField
						label={t('custom-fields.forms.labels.name')}
						{...register('name')}
						requiredMark
						fullWidth
						placeholder={t('custom-fields.forms.placeholders.name')}
						error={!!errors['name']}
						helperText={errors['name'] ? (errors['name'] as FieldError).message : undefined}
					/>
					{!isDefault && (
						<Controller
							name={'engagementType'}
							control={control}
							render={({ field }) => (
								<CoreSelect
									{...field}
									{...register('engagementType')}
									label={t('custom-fields.forms.labels.engagement-type')}
									requiredMark
									placeholder={t('custom-fields.forms.placeholders.select-option')}
									error={!!errors['engagementType']}
									helperText={errors['engagementType'] ? (errors['engagementType'] as FieldError).message : undefined}
									fullWidth
								>
									{ServiceTypeStatusArray?.map((el: any) => (
										<MenuItem key={el} value={el}>
											{el}
										</MenuItem>
									))}
								</CoreSelect>
							)}
						/>
					)}
					<Controller
						name={'isRequired'}
						control={control}
						render={({ field }) => (
							<CoreSelect
								{...field}
								{...register('isRequired')}
								label={t('custom-fields.forms.labels.verification-type')}
								requiredMark
								placeholder={t('custom-fields.forms.placeholders.select-option')}
								error={!!errors['isRequired']}
								helperText={errors['isRequired'] ? (errors['isRequired'] as FieldError).message : undefined}
								fullWidth
							>
								<MenuItem value="true">{t('custom-fields.isRequired')}</MenuItem>
								<MenuItem value="false">{t('custom-fields.optional')}</MenuItem>
							</CoreSelect>
						)}
					/>
					<Controller
						name={'dataType'}
						control={control}
						render={({ field }) => (
							<CoreSelect
								{...field}
								{...register('dataType')}
								label={t('custom-fields.forms.labels.data-type')}
								error={!!errors['dataType']}
								helperText={errors['dataType'] ? (errors['dataType'] as FieldError).message : undefined}
								requiredMark
								placeholder={t('custom-fields.forms.placeholders.select-option')}
								disabled={isDefault}
								fullWidth
							>
								{CustomFieldDataTypeArray.map((fieldType) => (
									<MenuItem key={fieldType} value={fieldType} disabled={isDefault}>
										{fieldType}
									</MenuItem>
								))}
							</CoreSelect>
						)}
					/>

					{(fieldType === CustomFieldDataTypeEnum.Options || fieldType === CustomFieldDataTypeEnum.MultiSelect) && (
						<Box sx={{ mt: 2 }}>
							{fields?.map((field, index) => (
								<Box key={field.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
									<Controller
										name={`options.${index}.text`}
										control={control}
										render={({ field }) => (
											<CoreTextField
												{...field}
												label={`${t('custom-fields.forms.labels.option')} ${index + 1}`}
												error={!!(errors['options'] as any)?.[index]?.text}
												helperText={(errors['options'] as any)?.[index]?.text?.message}
												placeholder={t('custom-fields.forms.placeholders.option')}
												fullWidth
											/>
										)}
									/>
									<IconButton
										edge="end"
										onClick={() => {
											remove(index);
										}}
									>
										<CloseIcon />
									</IconButton>
								</Box>
							))}
							<Button
								variant="text"
								onClick={() => {
									append({ text: '' });
								}}
							>
								+ Add Option
							</Button>
						</Box>
					)}
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item>
							<CoreButton variant="secondary" contraModeStyleButton onClick={closeModal} sx={buttonModalWindowStyles}>
								{t('providers.close')}
							</CoreButton>
						</Grid>
						<Grid item>
							<CoreButton
								type="submit"
								contraModeStyleButton
								loading={loadingSubmit}
								disabled={disableSubmit}
								sx={buttonModalWindowStyles}
							>
								{t('custom-fields.save')}
							</CoreButton>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</CoreModal>
	);
};
