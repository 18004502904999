import React, { forwardRef, useState } from 'react';
import { CoreFormControl } from '../core-form-control';
import { DatePicker } from '@mui/x-date-pickers';
import { Box } from '@mui/material';

export const CoreDatePicker = forwardRef<unknown, any>((props, ref) => {
	const { id, fullWidth, controlSx, label, helperText, error, disabled, focused, requiredMark, ...inputProps } = props;
	const [manualFocused, setManualFocused] = useState(false);

	return (
		<CoreFormControl
			disabled={disabled}
			error={error}
			helperText={helperText}
			label={label}
			id={id}
			requiredMark={requiredMark}
			focused={manualFocused}
			fullWidth={fullWidth}
			controlSx={controlSx}
		>
			<Box onFocus={() => setManualFocused(true)} onBlur={() => setManualFocused(false)}>
				<DatePicker
					{...inputProps}
					slotProps={{
						layout: {
							sx: {
								'& .MuiMultiSectionDigitalClockSection-root::after': {
									display: 'none',
								},
							},
						},
						textField: {
							sx: {
								'&.MuiTextField-root': {
									width: '100%',
								},
								'& .MuiOutlinedInput-input': {
									padding: '13px 16px',
								},
								'& .MuiInputBase-root':{
									paddingRight: '18px'
								},
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: (theme) => theme.colors.blue,
									},
								},
								'& .MuiOutlinedInput-notchedOutline': {
									borderColor: (theme) => (error ? theme.colors.red : theme.colors.blueTransparent),
								},
							},
						},
					}}
				/>
			</Box>
		</CoreFormControl>
	);
});
