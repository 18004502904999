import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { sessionSlice } from '../modules/auth';
import api from './api';
import { districtSlice } from '../modules/district/store/district.slice';
import { familySlice } from '../modules/family/store/family.slice';
import { providerSlice } from '../modules/providers/store/provider.slice';
import { engagementSlice } from '../modules/engagement/store/engagement.slice';
import { sessionStorageSlice } from './sesionStorage.slice';

const store = configureStore({
	reducer: {
		session: sessionSlice.reducer,
		[api.reducerPath]: api.reducer,
		district: districtSlice.reducer,
		family: familySlice.reducer,
		provider: providerSlice.reducer,
		engagement: engagementSlice.reducer,
		sessionStorage: sessionStorageSlice.reducer,
	},
	// Adding the api middleware enables caching, invalidation, polling,
	// and other useful features of `rtk-query`.
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkConfig = {
	state: RootState;
	dispatch: AppDispatch;
};

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
