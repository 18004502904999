import {
	DistrictDto,
	PageDto,
	SearchFilter,
	PageOptionsDto,
	CreateDistrictDto,
	UpdateDistrictDto,
} from '@families-link/shared';
import api from '.';

const districtsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getDistrictListPagination: builder.query<PageDto<DistrictDto>, PageOptionsDto & SearchFilter>({
			query: ({ page, take, orderField, order, search }) => ({
				url: `districts`,
				params: { page, take, orderField, order, search },
			}),
		}),
		getDistrictList: builder.query<DistrictDto[], void>({
			query: () => ({
				url: `districts/all`,
			}),
		}),
		getDistrict: builder.query<DistrictDto, { id?: string | null }>({
			query: ({ id }) => ({
				url: `districts/${id}`,
			}),
		}),
		// getDistrictWithoutInitiate: builder.query<DistrictDto, { id?: string | null }>({
		// 	query: ({ id }) => ({
		// 		url: `districts/${id}/without-initiate`,
		// 	}),
		// }),
		createDistrict: builder.mutation<DistrictDto, CreateDistrictDto>({
			query: (data) => ({
				url: `districts`,
				method: 'POST',
				body: data,
			}),
		}),
		updateDistrict: builder.mutation<DistrictDto, { id: string; data: UpdateDistrictDto }>({
			query: ({ id, data }) => ({
				url: `districts/${id}`,
				method: 'PUT',
				body: data,
			}),
		}),
		deleteDistrict: builder.mutation<DistrictDto, { id: string }>({
			query: ({ id }) => ({
				url: `districts/${id}`,
				method: 'DELETE',
			}),
		}),
	}),
});

export default districtsApi;

export const {
	useGetDistrictListPaginationQuery,
	useGetDistrictQuery,
	useGetDistrictListQuery,
	useCreateDistrictMutation,
	useUpdateDistrictMutation,
	useDeleteDistrictMutation,
	// useGetDistrictWithoutInitiateQuery,
} = districtsApi;
