import { Length, IsNotEmpty } from 'class-validator';

export class CreateChildDto {
	@IsNotEmpty()
	familyId: string;

	@Length(1, 100)
	name: string;

	@Length(1, 100)
	studentId: string;
}

export class CreateChildFromFamilyDto {
	@Length(1, 100)
	@IsNotEmpty({ message: 'Student Name is required' })
	name: string;

	@Length(1, 100)
	@IsNotEmpty({ message: 'Student ID is required' })
	studentId: string;
}
