import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FilterIconComponent: FC<SvgIconProps> = (props) => (
	<SvgIcon
		{...props}
		viewBox="0 0 24 24"
		sx={{
			fontSize: '20px',
			fill: 'none',
			stroke: 'inherit',
		}}
	>
		<path
			d="M21 4H3L10.2 12.4089V18.2222L13.8 20V12.4089L21 4Z"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</SvgIcon>
);
