import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const InHouseIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon {...props}>
		<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="14" height="16" fill="#C1C1C1" />
			<g id="FamiliesLink Level">
				<path
					d="M-1044 -3344C-1044 -3345.1 -1043.1 -3346 -1042 -3346H6954C6955.1 -3346 6956 -3345.1 6956 -3344V1752C6956 1753.1 6955.1 1754 6954 1754H-1042C-1043.1 1754 -1044 1753.1 -1044 1752V-3344Z"
					fill="#E9E9E9"
				/>
				<g id="Providers">
					<rect width="1440" height="900" transform="translate(-944 -246)" fill="#EFF0F3" />
					<g id="Content">
						<path
							d="M-700 -146C-700 -152.627 -694.627 -158 -688 -158H468C474.627 -158 480 -152.627 480 -146V654H-700V-146Z"
							fill="white"
						/>
						<g id="Frame 5518">
							<g id="Group 4048">
								<g id="Frame 1000002464">
									<g id="Frame 1000002463">
										<g id="lucide:house">
											<g id="Group">
												<path
													id="Vector"
													d="M9 14.333V8.99967C9 8.82286 8.92976 8.65329 8.80474 8.52827C8.67971 8.40325 8.51014 8.33301 8.33333 8.33301H5.66667C5.48986 8.33301 5.32029 8.40325 5.19526 8.52827C5.07024 8.65329 5 8.82286 5 8.99967V14.333"
													stroke="#3C8DBC"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													id="Vector_2"
													d="M1 6.99973C0.999954 6.80578 1.04222 6.61415 1.12386 6.43821C1.20549 6.26227 1.32453 6.10627 1.47267 5.98107L6.13933 1.98173C6.37999 1.77834 6.6849 1.66675 7 1.66675C7.3151 1.66675 7.62001 1.77834 7.86067 1.98173L12.5273 5.98107C12.6755 6.10627 12.7945 6.26227 12.8761 6.43821C12.9578 6.61415 13 6.80578 13 6.99973V12.9997C13 13.3534 12.8595 13.6925 12.6095 13.9425C12.3594 14.1926 12.0203 14.3331 11.6667 14.3331H2.33333C1.97971 14.3331 1.64057 14.1926 1.39052 13.9425C1.14048 13.6925 1 13.3534 1 12.9997V6.99973Z"
													stroke="#3C8DBC"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	</SvgIcon>
);
