import { baseQueryWithReAuth } from '../../modules/_core/services';
import { createApi } from '@reduxjs/toolkit/query/react';

const api = createApi({
	reducerPath: 'api',
	baseQuery: baseQueryWithReAuth,
	endpoints: () => ({}),
	tagTypes: [
		'UserProfile',
		'Family',
		'Engagement',
		'Provider',
		'Survey',
		'SurveyAnswer',
		'CustomField',
		'SurveyTemplate',
	],
});

export default api;
