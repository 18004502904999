import { FC, useCallback, useRef } from 'react';
import { CoreInput } from '../../_core/components/_ui/core-input';
import {
	useDeleteProviderMutation,
	useGetAllProvidersQuery,
	useUpdateProviderMutation,
} from '../../../store/api/providers.api';
import { ProvidersTable } from '../components/providers-table/providers-table.component';
import { Box, Chip, Container, InputAdornment, MenuItem } from '@mui/material';
import { LanguageEnum, ProviderDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useLanguageFilter, useSortingParams, useSearch, useDistrictFilter, useMuiModal } from '../../_core/hooks';
import { generatePath, useNavigate } from 'react-router-dom';
import { AutocompleteTextField, CoreAutoComplete } from '../../_core/components/_ui/core-autocomplete';
import { getObjectLanguage } from '../../_core/components/languages-component';
import { ProfileRoutes } from '../../profile/constants';
import { useGetDistrictListQuery } from '../../../store/api/district.api';
import { useOpenModalForm } from '../hooks/use-open-modal-form.hook';
import { ConfirmationModal, ConfirmationModalProps } from '../../_core/components/confirmation-modal';
import { ChipCloseIcon, DeleteIcon } from '../../_core/components/icons';
import { boxHeaderStyles, buttonModalWindowStyles, chipDeleteIconStyles } from '../../_core/styles';
import { CoreCheckbox } from '../../_core/components/_ui/core-checkbox';
import { mergeSx } from 'merge-sx';
import { Search } from '../../_core/constants/icons.constants';
import { CoreOverflowTypographyTooltip } from '../../_core/components/_ui/core-overflow-tooltip';

export const ProvidersPage: FC = () => {
	const [t] = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const { register, search } = useSearch();
	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();

	const { register: registerLang, getValues, control, languages } = useLanguageFilter();
	const { data: districtList } = useGetDistrictListQuery();
	const {
		register: registerDistrict,
		getValues: getValuesDistrict,
		control: controlDistrict,
		districts,
	} = useDistrictFilter();

	const {
		data: providers,
		isLoading,
		refetch,
	} = useGetAllProvidersQuery({
		languages: languages.join(','),
		sortBy: sortingParams.orderField,
		sortOrder: sortingParams.order,
		searchQuery: search,
		districts: districts.join(','),
	});

	const [deleteProvider] = useDeleteProviderMutation();

	const [updateProvider] = useUpdateProviderMutation();
	const featchActionEdit = async (data: ProviderDto) => {
		const providerId = data?._id?.toString();

		if (providerId) {
			await updateProvider({ id: providerId, updateData: data }).unwrap();
		}
	};

	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		isCreate: false,
		submitButtonName: t('providers.save-changes'),
		toastText: 'Provider updated successfully',
		formTitle: 'Edit Provider',
	});

	const { openModal: openConfirmDeleteModal, closeModal: closeConfirmDeleteModal } =
		useMuiModal<ConfirmationModalProps>(ConfirmationModal);

	const handleOnDelete = useCallback(
		async (providerId: string) => {
			try {
				await deleteProvider(providerId).unwrap();
				enqueueSnackbar('Provider deleted successfully!', { variant: 'success' });
				closeConfirmDeleteModal();
			} catch (error) {
				enqueueSnackbar('Failed to delete provider', { variant: 'error' });
				console.log(error);
			}
		},
		[deleteProvider, enqueueSnackbar]
	);

	const handleOpenDeleteConfirmModal = useCallback(
		(provider: ProviderDto) => {
			const handleSubmitDelete = async () => {
				await handleOnDelete(provider._id);
			};
			openConfirmDeleteModal({
				title: t('providers.confirm-deletion'),
				description: t('providers.sure-delete').replace(':provider_fullname', provider.fullName),
				onSubmit: handleSubmitDelete,
				onClose: closeConfirmDeleteModal,
				icon: <DeleteIcon sx={{ fontSize: 40 }} />,
				customBackgrondColorKey: 'white',
				customColorKey: 'red',
				customBorderColorKey: 'red',
				danger: true,
				leftButtonProps: {
					children: t('families.forms.buttons.cancel'),
					variant: 'secondary',
					contraModeStyleButton: true,
					sx: buttonModalWindowStyles,
				},
				rightButtonProps: {
					children: t('families.forms.buttons.delete'),
					variant: 'danger',
					contraModeStyleButton: true,
					sx: buttonModalWindowStyles,
				},
			});
		},
		[handleOnDelete, openConfirmDeleteModal]
	);

	const navigate = useNavigate();

	const handleOnView = (provider: ProviderDto) => {
		navigate(generatePath(ProfileRoutes.ProviderDetails, { providerId: provider._id }));
	};

	const scrollRef = useRef<HTMLDivElement>(null);

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box sx={mergeSx(boxHeaderStyles, { justifyContent: 'space-between' })}>
				<Box>
					<CoreInput
						{...register('search')}
						placeholder={t('common.search-by-name')}
						hasEndAdornment
						endAdornment={
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						}
						sx={{ marginRight: 1 }}
					/>
					<CoreAutoComplete
						multiple={true}
						{...registerLang('languages')}
						control={control}
						controlSx={{ minWidth: '220px', marginRight: 1 }}
						isFilter
						options={Object.values(LanguageEnum)}
						getOptionLabel={(option) => {
							const language = getObjectLanguage(option);

							return language ? language.name : '';
						}}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option === value}
						renderInput={(params) => (
							<AutocompleteTextField
								{...params}
								placeholder={!getValues('languages')?.length ? t('common.filter-lang') : ''}
								sx={{
									'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
										padding: '4.5px 4px 5.5px 5px',
										minWidth: 0,
									},
									'& .MuiInputBase-input': {
										height: '100%',
										padding: '0',
									},
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: (theme) => theme.colors.blue,
										},
									},
								}}
							/>
						)}
						renderOption={(props, option, { selected }) => {
							const language = getObjectLanguage(option);

							return (
								<MenuItem {...props} key={language?.value}>
									<CoreCheckbox checked={selected} sx={{ mr: 2, p: 0, ml: 0 }} />
									<CoreOverflowTypographyTooltip>{language?.name}</CoreOverflowTypographyTooltip>
								</MenuItem>
							);
						}}
						renderTags={(value, getTagProps) => {
							const count = value.length - 1;

							return [
								value[0] && (
									<Chip
										sx={chipDeleteIconStyles}
										{...getTagProps({ index: 0 })}
										label={getObjectLanguage(value[0])?.name}
										deleteIcon={<ChipCloseIcon />}
									/>
								),
								count > 0 && (
									<Chip
										sx={chipDeleteIconStyles}
										{...getTagProps({ index: count })}
										label={`+${count}`}
										deleteIcon={<ChipCloseIcon />}
									/>
								),
							];
						}}
					/>
					<CoreAutoComplete
						multiple={true}
						{...registerDistrict('districts')}
						control={controlDistrict}
						controlSx={{ minWidth: '190px' }}
						isFilter
						options={districtList || []}
						getOptionLabel={(option) => option.name || ''}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option._id === value._id}
						renderInput={(params) => (
							<AutocompleteTextField
								{...params}
								placeholder={!getValuesDistrict('districts')?.length ? t('common.filter-district') : ''}
								sx={{
									'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
										padding: '4.5px 4px 5.5px 5px',
										minWidth: 0,
									},
									'& .MuiInputBase-input': {
										height: '100%',
										padding: '0',
									},
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: (theme) => theme.colors.blue,
										},
									},
								}}
							/>
						)}
						renderOption={(props, option, { selected }) => (
							<MenuItem {...props} key={option._id}>
								<CoreCheckbox checked={selected} sx={{ mr: 2, p: 0, ml: 0 }} />
								<CoreOverflowTypographyTooltip>{option?.name}</CoreOverflowTypographyTooltip>
							</MenuItem>
						)}
						renderTags={(value, getTagProps) => {
							const count = value.length - 1;

							return [
								value[0] && (
									<Chip
										sx={chipDeleteIconStyles}
										{...getTagProps({ index: 0 })}
										label={value[0]?.name}
										deleteIcon={<ChipCloseIcon />}
									/>
								),
								count > 0 && (
									<Chip
										sx={chipDeleteIconStyles}
										{...getTagProps({ index: count })}
										label={`+${count}`}
										deleteIcon={<ChipCloseIcon />}
									/>
								),
							];
						}}
					/>
				</Box>
			</Box>

			<ProvidersTable
				data={providers ?? []}
				loading={isLoading}
				sorting={sortingArray}
				onSortingChange={handleSortingChange}
				onDelete={handleOpenDeleteConfirmModal}
				onEdit={handleOpenModalEdit}
				onView={handleOnView}
			/>
		</Container>
	);
};
