import React, { useState, useRef, useEffect } from 'react';
import { Tooltip, Typography } from '@mui/material';

export const CoreOverflowTypographyTooltip = (props: any) => {
    const {children} = props;
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);

  const checkOverflow = () => {
    if (textRef.current) {
      const isOverflowing = textRef.current.scrollWidth > textRef.current.clientWidth;
      setIsOverflowing(isOverflowing);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [children]);

  return (
    <Tooltip title={isOverflowing ? children : ''} placement="bottom">
      <Typography ref={textRef} noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {children}
      </Typography>
    </Tooltip>
  );
};