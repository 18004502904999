import React, { FC, useEffect, useRef } from 'react';
import { UsersTable } from '../../components/users-table';
import { Box, Container, InputAdornment } from '@mui/material';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import { CreateUserDto, UserDto } from '@families-link/shared';
import { useSearch, useSortingParams, usePaginatedData, useMuiModal } from '../../../_core/hooks';
import {
	useCreateUserMutation,
	useDeleteUserMutation,
	useGetUsersListQuery,
	useUpdateUserMutation,
} from '../../../../store/api/users.api';
import { useOpenModalForm } from '../../hooks/modal-forms/use-open-modal-form.hook';
import { useOpenConfirmModal } from '../../hooks/modal-forms/use-open-confirm-modal-form.hook';
import {
	useGenerateRegistrationLinkMutation,
	useInviteRequestMutation,
	useRestorePasswordRequestMutation,
} from '../../../../store/api/auth.api';
import { getLocalStorageDistrictId } from '../../../_core/utils';
import AddIcon from '@mui/icons-material/Add';
import { DeleteIcon, InfoIcon } from '../../../_core/components/icons';
import { RegistrationLinkModal } from '../../components/link-modal/link-modal.component';
import { useSessionStorage } from '../../../../hooks';
import { mergeSx } from 'merge-sx';
import { boxHeaderStyles, buttonHeaderStyles } from '../../../_core/styles';
import { Search } from '../../../_core/constants/icons.constants';

export const UsersListPage: FC = () => {
	const [sessionStorageDistrictId] = useSessionStorage('districtId');
	const districtId = sessionStorageDistrictId ?? getLocalStorageDistrictId();
	const [t] = useTranslation();

	const isFirstRender = useRef(true);

	const { register, search } = useSearch();
	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const { requestObject, meta, page, handleResetPage, itemsList, fetchMore } = usePaginatedData<UserDto>(
		useGetUsersListQuery,
		{ search, ...sortingParams, district: districtId },
		{ refetchOnMountOrArgChange: true }
	);
	const { refetch, isFetching } = requestObject;
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}

		handleResetPage();
	}, [sortingArray, search, handleResetPage]);

	const handlePagination = () => {
		handleResetPage();

		if (page === 1) {
			refetch();
		}
	};

	const [inviteRequest, { reset: resetInvitation }] = useInviteRequestMutation();
	const featchActionSendInvitation = async (data: UserDto) => {
		await inviteRequest({ email: data.email }).unwrap();
	};
	useEffect(() => {
		resetInvitation();
	}, [resetInvitation]);

	const handleOpenInvitationConfirmModal = useOpenConfirmModal({
		featchAction: featchActionSendInvitation,
		handlePagination,
		title: t('users.forms.send-invitation-title'),
		description: t('users.forms.send-invitation-description'),
		icon: <InfoIcon sx={{ fontSize: 40 }} />,
		customBackgrondColorKey: 'grayLight',
		submitButtonName: t('users.forms.buttons.send'),
		toastText: 'Email was successfully sent',
	});

	const [createUser] = useCreateUserMutation();
	const featchActionCreate = async (data: CreateUserDto) => {
		await createUser({ ...data }).unwrap();
		await inviteRequest({ email: data.email }).unwrap();
	};
	const handleOpenModalCreate = useOpenModalForm({
		featchAction: featchActionCreate,
		handlePagination,
		isCreate: true,
		districtId,
		submitButtonName: t('users.forms.buttons.create'),
		toastText: 'User was successfully created',
	});

	const [updateUser] = useUpdateUserMutation();
	const featchActionEdit = async (data: UserDto) => {
		await updateUser({ id: data._id, data: { ...data } }).unwrap();
	};
	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		handlePagination,
		isCreate: false,
		districtId,
		submitButtonName: t('users.forms.buttons.save'),
		toastText: 'User was successfully updated',
	});

	const [deleteUser] = useDeleteUserMutation();
	const featchActionDelete = async (data: UserDto) => {
		await deleteUser({ id: data._id }).unwrap();
	};
	const handleOpenDeleteConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDelete,
		handlePagination,
		title: t('users.forms.delete-title'),
		description: t('users.forms.delete-description'),
		icon: <DeleteIcon sx={{ fontSize: 40 }} />,
		danger: true,
		submitButtonName: t('users.forms.buttons.delete'),
		toastText: 'User was successfully deleted',
	});

	const [restorePasswordRequest, { reset: resetRestorePass }] = useRestorePasswordRequestMutation();
	const featchActionResetPassword = async (data: UserDto) => {
		await restorePasswordRequest({ email: data.email }).unwrap();
	};
	useEffect(() => {
		resetRestorePass();
	}, [resetRestorePass]);
	const handleOpenResetPasswordConfirmModal = useOpenConfirmModal({
		featchAction: featchActionResetPassword,
		handlePagination,
		title: t('users.forms.reset-password-title'),
		description: t('users.forms.reset-password-description'),
		icon: <InfoIcon sx={{ fontSize: 40 }} color="primary" />,
		customBackgrondColorKey: 'grayLight',
		submitButtonName: t('users.forms.buttons.send'),
		toastText: 'Email was successfully sent',
	});

	const { openModal, closeModal } = useMuiModal(RegistrationLinkModal);

	const [generateLink] = useGenerateRegistrationLinkMutation();

	const handleGenerateLink = async () => {
		try {
			if (districtId) {
				const link = await generateLink({ districtId }).unwrap();

				openModal({ link: link.link, closeModal });
			} else {
				throw new Error('District id is empty');
			}
		} catch (err) {
			console.error('Error generating link:', err);
		}
	};

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box sx={boxHeaderStyles}>
				<CoreTextField
					{...register('search')}
					placeholder={t('common.search-by-name')}
					hasEndAdornment
					endAdornment={
						<InputAdornment position="end">
							<Search />
						</InputAdornment>
					}
					sx={{ marginRight: '16px', width: '400px' }}
				/>
				<CoreButton
					variant="info-light"
					sx={mergeSx(buttonHeaderStyles, { marginLeft: 'auto' })}
					onClick={handleGenerateLink}
				>
					<AddIcon />
					Create registration link
				</CoreButton>
				<CoreButton
					sx={mergeSx(buttonHeaderStyles, { marginLeft: 1 })}
					onClick={() => {
						handleOpenModalCreate();
					}}
				>
					<AddIcon />
					{t('users.create-user')}
				</CoreButton>
			</Box>
			<UsersTable
				data={itemsList}
				loading={isFetching}
				hasMore={meta?.hasNextPage}
				onDelete={handleOpenDeleteConfirmModal}
				onEdit={handleOpenModalEdit}
				onMailSend={handleOpenInvitationConfirmModal}
				onResetPassword={handleOpenResetPasswordConfirmModal}
				sorting={sortingArray}
				onSortingChange={handleSortingChange}
				fetchMore={fetchMore}
			/>
		</Container>
	);
};
