import React, { FC, useEffect, useRef } from 'react';
import { SurveyTemplatesTable } from '../../components/survey-templates-table';
import { Box, Container } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import { SurveyTemplateDto } from '@families-link/shared';
import { useSortingParams, usePaginatedData } from '../../../_core/hooks';
import { useDeleteSurveyTemplateMutation, useGetSurveyTemplatesListQuery } from '../../../../store/api/survey.api';
import { useOpenConfirmModal } from '../../hooks/modal-forms/use-open-confirm-modal-form.hook';
import { getLocalStorageDistrictId } from '../../../_core/utils';
import AddIcon from '@mui/icons-material/Add';
import { DistrictRoutes } from '../../../district/constants';
import { useNavigate, generatePath } from 'react-router-dom';
import { useSessionStorage } from '../../../../hooks';
import { ProfileRoutes } from '../../../profile/constants';
import { useOpenModalAttachDistrictFormHook } from '../../hooks/modal-forms/use-open-attach-modal.hook';
import { mergeSx } from 'merge-sx';
import { boxHeaderStyles, buttonHeaderStyles } from '../../../_core/styles';

export type SurveysListPageProps = {
	isAdminRoute: boolean;
};
export const SurveyTemplatesListPage: FC<SurveysListPageProps> = ({ isAdminRoute }) => {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const [sessionStorageDistrictId] = useSessionStorage('districtId');
	const districtId = sessionStorageDistrictId ?? getLocalStorageDistrictId();

	const isFirstRender = useRef(true);

	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const { requestObject, meta, page, handleResetPage, itemsList, fetchMore } = usePaginatedData<SurveyTemplateDto>(
		useGetSurveyTemplatesListQuery,
		{
			...sortingParams,
			adminTemplate: isAdminRoute,
			district: districtId,
		},
		{ refetchOnMountOrArgChange: true, keepPreviousData: true }
	);

	const { refetch } = requestObject;

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}
		handleResetPage();
	}, [sortingArray]);

	const handlePagination = () => {
		handleResetPage();

		if (page === 1) {
			refetch();
		}
	};

	const handleOpenCreate = () => {
		if (isAdminRoute) {
			navigate(ProfileRoutes.SurveyTemplates.Creating);
		} else {
			navigate(DistrictRoutes.SurveyTemplates.Creating);
		}
	};

	const handleOpenEdit = (surveyTemplate: SurveyTemplateDto) => {
		if (isAdminRoute) {
			navigate(generatePath(ProfileRoutes.SurveyTemplates.Editing, { surveyTemplateId: surveyTemplate._id }), {
				state: { data: surveyTemplate },
			});
		} else {
			navigate(generatePath(DistrictRoutes.SurveyTemplates.Editing, { surveyTemplateId: surveyTemplate._id }), {
				state: { data: surveyTemplate },
			});
		}
	};

	const [deleteSurveyTemplate] = useDeleteSurveyTemplateMutation();
	const featchActionDelete = async (data: SurveyTemplateDto) => {
		await deleteSurveyTemplate({ id: data._id }).unwrap();
	};
	const handleOpenDeleteConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDelete,
		handlePagination,
		title: t('survey-templates.forms.delete-title'),
		description: t('survey-templates.forms.delete-description'),
		icon: <DeleteOutlineOutlinedIcon />,
		danger: true,
		submitButtonName: t('survey-templates.forms.buttons.delete'),
		toastText: 'Survey template was successfully deleted',
	});

	const handleOpenView = (surveyTemplate: SurveyTemplateDto) => {
		const url = generatePath(DistrictRoutes.SurveyTemplates.Details, { surveyTemplateId: surveyTemplate._id });
		window.open(url, '_blank');
	};

	const handleAttach = useOpenModalAttachDistrictFormHook();

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box sx={boxHeaderStyles}>
				<CoreButton
					sx={mergeSx(buttonHeaderStyles, { marginLeft: 'auto' })}
					onClick={() => {
						handleOpenCreate();
					}}
				>
					<AddIcon />
					{t('survey-templates.create-survey')}
				</CoreButton>
			</Box>
			<SurveyTemplatesTable
				data={itemsList}
				loading={false}
				hasMore={meta?.hasNextPage}
				onDelete={handleOpenDeleteConfirmModal}
				onEdit={handleOpenEdit}
				onView={handleOpenView}
				onAttachDistrict={handleAttach}
				sorting={sortingArray}
				onSortingChange={handleSortingChange}
				fetchMore={fetchMore}
			/>
		</Container>
	);
};
