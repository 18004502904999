import React, { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SurveyAnswerDto } from '@families-link/shared';
import { SurveyAnswerRender } from './survey-answer.component';

export type SurveyResultsAnswerListProps = {
	surveyAnswer?: SurveyAnswerDto;
};

export const SurveyResultsAnswerList: FC<SurveyResultsAnswerListProps> = ({ surveyAnswer }) => {
	const [t] = useTranslation();

	return (
		<Container
			maxWidth={false}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				width: '100%',
				paddingBottom: '16px',
				marginTop: '16px',
			}}
		>
			<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
				{t('surveys.survey-results')}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					paddingX: 1,
				}}
			>
				{surveyAnswer?.answers?.map((answer, index) => (
					<SurveyAnswerRender answer={answer} order={index + 1} />
				))}
			</Box>
		</Container>
	);
};
