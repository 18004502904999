import { FC } from 'react';
import { EngagementsListPage } from './engagements-list.page';
import { getLocalStorageDistrictId } from '../../../_core/utils';
import { useSessionStorage } from '../../../../hooks';
import { useGetAllFieldsQuery } from '../../../../../src/store/api/customRequirements.api';
import { useGetAllProvidersByDistrictIdQuery } from '../../../../../src/store/api/providers.api';
import {
	useGetAllFamiliesByDistrictIdQuery,
	useGetChildrenByDistrictIdQuery,
} from '../../../../../src/store/api/families.api';

export const EngagementsListDistrictLevelPage: FC = () => {
	const [sessionStorageDistrictId] = useSessionStorage('districtId');
	const districtId = sessionStorageDistrictId ?? getLocalStorageDistrictId();

	const { data: fieldsListFromDistrict } = useGetAllFieldsQuery({ districtId }, { skip: !districtId });
	const { data: providersList } = useGetAllProvidersByDistrictIdQuery({ districtId }, { skip: !districtId });
	const { data: familiesList } = useGetAllFamiliesByDistrictIdQuery({ district: districtId }, { skip: !districtId });
	const { data: childrenList } = useGetChildrenByDistrictIdQuery({ districtId }, { skip: !districtId });

	return (
		<EngagementsListPage
			permission={{ create: true }}
			isAdminRoute={false}
			currentDistrictId={districtId}
			fieldsListFromDistrict={fieldsListFromDistrict}
			providersList={providersList}
			familiesList={familiesList}
			childrenList={childrenList}
		/>
	);
};
