import React, { FC, useCallback, useEffect, useState } from 'react';
import { FieldError, FormProvider, useForm } from 'react-hook-form';
import { Box, FormControlLabel, MenuItem, Switch } from '@mui/material';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { CreateSurveyTemplateDto, LanguageEnum } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { SurveyTemplateQuestionsArray } from './questions-array.component';
import AddIcon from '@mui/icons-material/Add';
import { EyeIcon } from '../../../_core/components/icons';
import { useOpenSurveyModal } from '../../hooks/modal-forms/use-open-view-modal.hook';
import { AutocompleteTextField, CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import { useOpenConfirmModal } from '../../hooks/modal-forms/use-open-confirm-modal-form.hook';

export type SurveyTemplateFormProps = {
	isEditing: boolean;
	adminTemplate: boolean;
	currentDistrictId?: string | null;
	defaultValues?: any;
	submitAction(values: CreateSurveyTemplateDto): Promise<void>;
};

export const SurveyTemplateForm: FC<SurveyTemplateFormProps> = (props) => {
	const [t] = useTranslation();

	const { submitAction, defaultValues, isEditing, adminTemplate, currentDistrictId } = props;

	const initiateValues = {
		districtIds: currentDistrictId && !adminTemplate ? [currentDistrictId] : [],
		title: '',
		questions: [],
		language: null,
		adminTemplate,
	};

	const resolver = classValidatorResolver(CreateSurveyTemplateDto);

	const methods = useForm({
		defaultValues: isEditing ? defaultValues : initiateValues,
		resolver,
		mode: 'all',
	});

	const {
		register,
		reset,
		handleSubmit,
		getValues,
		setValue,
		setError,
		clearErrors,
		control,
		watch,
		formState: { errors, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = methods;

	useEffect(() => {
		if (isEditing && defaultValues) {
			reset(defaultValues);
		}
	}, [defaultValues, reset, isEditing]);

	const validateCustomField = (value: string) => {
		if (isLanguageTemplate && !value) {
			setError('language', { type: 'custom', message: 'Language field is required.' });
		}
	};

	const language = watch('language');
	const [isLanguageTemplate, setIsLanguageTemplate] = useState(!!getValues('language'));

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const handleFormSubmit = useCallback(
		async (values: CreateSurveyTemplateDto) => {
			if (isLanguageTemplate && !values.language) {
				setError('language', { type: 'custom', message: 'Language is required' });

				return;
			}
			await submitAction(values);
		},
		[submitAction, isLanguageTemplate, setError]
	);

	const handleOpenDefaultTemplateConfirmModal = useOpenConfirmModal({
		featchAction: handleFormSubmit,
		title: t('survey-templates.forms.default-template-title'),
		description: t('survey-templates.forms.default-template-description'),
		submitButtonName: t('survey-templates.forms.buttons.confirm'),
		needToast: false,
		danger: true,
	});

	useEffect(() => {
		if (!isLanguageTemplate) {
			setValue('language', null);
			clearErrors('language');
		}
	}, [isLanguageTemplate]);

	const currentTitle = getValues('title');
	const currentQuestions = getValues('questions');
	const currentLanguage = getValues('language');

	const openSurveyModal = useOpenSurveyModal({
		modalTitle: currentTitle,
		questions: currentQuestions,
		language: isLanguageTemplate ? currentLanguage : null,
	});

	return (
		<FormProvider {...methods}>
			<Box
				component="form"
				onSubmit={handleSubmit(
					!defaultValues?.language && language ? handleOpenDefaultTemplateConfirmModal : handleFormSubmit
				)}
				sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
			>
				<Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid lightgrey', paddingBottom: '16px' }}>
					<CoreTextField
						placeholder={t('survey-templates.forms.placeholders.enter-survey-name')}
						{...register('title')}
						error={!!errors['title']}
						helperText={(errors['title'] as FieldError)?.message}
						controlSx={{
							width: {
								xs: '200px',
								lg: '320px',
							},
						}}
					/>

					<CoreAutoComplete
						{...register('language')}
						control={control}
						disabled={!isLanguageTemplate}
						ruleIsRequired={isLanguageTemplate}
						ruleName={t('engagements.forms.labels.language')}
						error={!!errors['language']}
						helperText={(errors['language'] as FieldError)?.message}
						options={Object.values(LanguageEnum)}
						getOptionLabel={(option) => {
							const language = getObjectLanguage(option);

							return language ? language.name : '';
						}}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option === value}
						renderInput={(params) => (
							<AutocompleteTextField
								{...params}
								disabled={!isLanguageTemplate}
								onBlur={(e) => {
									validateCustomField(e.target.value);
								}}
								placeholder={t('engagements.forms.placeholders.options') ?? ''}
							/>
						)}
						renderOption={(props, option) => {
							const language = getObjectLanguage(option);

							return (
								<MenuItem {...props} key={language?.value}>
									{language?.label}
								</MenuItem>
							);
						}}
						controlSx={{
							minWidth: {
								xs: '160px',
								lg: '200px',
							},
							marginLeft: '8px',
						}}
					/>
					<FormControlLabel
						labelPlacement="end"
						control={
							<Switch
								checked={isLanguageTemplate}
								onChange={(e) => {
									setIsLanguageTemplate(e.target.checked);
								}}
								name="gilad"
								sx={{
									width: 52,
									height: 32,
									padding: 0,
									'& .MuiSwitch-switchBase': {
										padding: 0,
										top: `4px`,
										left: '4px',

										'&.Mui-checked': {
											transform: 'translateX(20px)',
											color: '#fff',
											'& + .MuiSwitch-track': {
												backgroundColor: '#3C8DBC',
												opacity: 1,
												border: 0,
											},
										},
									},
									'& .MuiSwitch-thumb': {
										boxSizing: 'border-box',
										width: 24,
										height: 24,
									},
									'& .MuiSwitch-track': {
										borderRadius: 26 / 2,
										backgroundColor: '#E9E9EA',
										opacity: 1,
									},
								}}
							/>
						}
						label={
							<Box
								component="span"
								sx={{
									color: (theme) => (isLanguageTemplate ? theme.colors.black : theme.colors.gray),
									fontSize: '16px',
									ml: '4px',
								}}
							>
								Default survey
							</Box>
						}
						sx={{ ml: '8px' }}
					/>

					<Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
						<CoreButton variant="info-light" circle size="large" onClick={openSurveyModal}>
							<EyeIcon sx={{ fontSize: 30 }} />
						</CoreButton>
						<CoreButton
							type="submit"
							disabled={disableSubmit}
							loading={loadingSubmit}
							sx={{ marginLeft: '8px', fontSize: '16px' }}
						>
							<AddIcon />
							{t('survey-templates.forms.buttons.save-changes')}
						</CoreButton>
					</Box>
				</Box>
				<SurveyTemplateQuestionsArray />
			</Box>
		</FormProvider>
	);
};
