export enum TranslateLanguages {
	Spanish = 'Spanish',
	Russian = 'Russian',
	Ukrainian = 'Ukrainian',
	Vietnamese = 'Vietnamese',
	ChineseSimplified = 'Chinese (Simplified)',
	English = 'English',
	Arabic = 'Arabic',
}

export enum LanguageCodes {
	Spanish = 'es',
	Russian = 'ru',
	Ukrainian = 'uk',
	Vietnamese = 'vi',
	ChineseSimplified = 'zh',
	English = 'en',
	Arabic = 'ar',
}
