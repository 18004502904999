import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DistrictsTable } from '../../components/districts-table';
import { Box, Container, InputAdornment } from '@mui/material';
import { debounce } from 'lodash';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import SearchIcon from '@mui/icons-material/Search';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import {
	useCreateDistrictMutation,
	useDeleteDistrictMutation,
	useGetDistrictListPaginationQuery,
	useUpdateDistrictMutation,
} from '../../../../store/api/district.api';
import {
	CreateDistrictDto,
	DistrictDto,
	OrderEnum,
	PageOptionsDto,
	SearchFilter,
	UpdateDistrictDto,
} from '@families-link/shared';
import { useClearStore, useMuiModal } from '../../../_core/hooks';
import { CreateDistrictFormModalProps, CreateDistrictFormModal } from '../../components/create-form';
import { SortingState } from '@tanstack/react-table';
import { useForm } from 'react-hook-form';
import { EditDistrictFormModal, EditDistrictFormModalProps } from '../../components/edit-form';
import { useNavigate } from 'react-router-dom';
import { DistrictRoutes, DistrictPage } from '../../constants';
import { ConfirmationModal, ConfirmationModalProps } from '../../../_core/components/confirmation-modal';
import { useSnackbar } from 'notistack';
import { setLocalStorageDistrictId } from '../../../_core/utils';
import AddIcon from '@mui/icons-material/Add';
import { boxHeaderStyles, buttonHeaderStyles, buttonModalWindowStyles } from '../../../_core/styles';
import { DeleteIcon } from '../../../_core/components/icons';
import { useSessionStorage } from '../../../../hooks';
import { mergeSx } from 'merge-sx';
import { Search } from '../../../_core/constants/icons.constants';

export const DistrictListPage: FC = () => {
	const [sessionStorageDistrictId, setSessionStorageDistrictId] = useSessionStorage('districtId');

	const [t] = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [districtsList, setDistrictsList] = useState<DistrictDto[]>([]);
	const [sortingArray, setSortingArray] = useState<SortingState>([]);
	const sortingParams = useMemo<{ orderField?: string; order?: OrderEnum }>(() => {
		const newSortingParams = sortingArray.length
			? {
					orderField: sortingArray[0].id,
					order: sortingArray[0].desc ? OrderEnum.DESC : OrderEnum.ASC,
			  }
			: {};

		return newSortingParams;
	}, [sortingArray]);
	const handleSortingChange = (sorting: SortingState) => {
		setSortingArray(sorting);
		setPage(1);
	};
	const [districtName, setDistrictName] = useState('');
	const { data, refetch, isFetching } = useGetDistrictListPaginationQuery({
		page,
		take: DistrictPage.PAGINATION_COUNT,
		...sortingParams,
		search: districtName,
	} as PageOptionsDto & SearchFilter);

	useEffect(() => {
		refetch();
	}, [page, refetch]);

	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
		});
	};

	useEffect(() => {
		if (data) {
			if (data.meta?.page === 1) {
				setDistrictsList(data.data);
			} else {
				setDistrictsList((prevDistricts) => [...prevDistricts, ...data.data]);
			}
		}
	}, [data]);

	const fetchMore = useCallback(() => {
		setPage(page + 1);
	}, [page]);

	const { register, watch } = useForm({
		defaultValues: { search: '' },
	});
	const searchTerm = watch('search');

	const debouncedUpdate = useCallback(
		debounce((term: string) => {
			setPage(1);
			setDistrictName(term);
		}, 300),
		[]
	);

	useEffect(() => {
		debouncedUpdate(searchTerm);
	}, [searchTerm, debouncedUpdate]);

	const [createDistrict] = useCreateDistrictMutation();

	const {
		openModal: openCreateModal,
		closeModal: closeCreateModal,
		updateCurrentModal: updateCreateModal,
	} = useMuiModal<CreateDistrictFormModalProps>(CreateDistrictFormModal);

	const handleSubmitCreate = async (values: CreateDistrictDto): Promise<void> => {
		const createDistrictData: CreateDistrictDto = { ...values };
		try {
			await createDistrict(createDistrictData).unwrap();
			showToast('District was successfully created', 'success');
			setPage(1);

			if (page === 1) {
				refetch();
			}
			closeCreateModal();
		} catch (error: any) {
			updateCreateModal({ formError: error });
		}
	};

	const handleOpenModalCreate = useCallback(() => {
		openCreateModal({
			submitAction: handleSubmitCreate,
			closeCreateModal,
		});
	}, [openCreateModal, handleSubmitCreate]);

	const [updateDistrict] = useUpdateDistrictMutation();

	const {
		openModal: openEditModal,
		closeModal: closeEditModal,
		updateCurrentModal: updateEditModal,
	} = useMuiModal<EditDistrictFormModalProps>(EditDistrictFormModal);

	const handleSubmitEdit = async (values: DistrictDto): Promise<void> => {
		const editDistrictData: UpdateDistrictDto = { ...values };
		try {
			await updateDistrict({ id: values._id, data: editDistrictData }).unwrap();
			showToast('District was successfully updated', 'success');
			setPage(1);

			if (page === 1) {
				refetch();
			}
			closeEditModal();
		} catch (error) {
			updateEditModal({ formError: error });
		}
	};

	const handleOpenModalEdit = useCallback(
		(district: DistrictDto) => {
			openEditModal({
				defaultValues: district,
				submitAction: handleSubmitEdit,
				closeEditModal,
			});
		},
		[openEditModal, handleSubmitEdit]
	);

	const { openModal: openConfirmDeleteModal, closeModal: closeConfirmDeleteModal } =
		useMuiModal<ConfirmationModalProps>(ConfirmationModal);
	const [deleteDistrict] = useDeleteDistrictMutation();

	const handleDelete = async (district: DistrictDto): Promise<void> => {
		try {
			await deleteDistrict({ id: district._id }).unwrap();
			showToast('District was successfully deleted', 'success');
			setPage(1);

			if (page === 1) {
				refetch();
			}
			closeConfirmDeleteModal();
		} catch (error) {
			console.error(error);
		}
	};

	const handleOpenDeleteConfirmModal = useCallback(
		(district: DistrictDto) => {
			const handleSubmitDelete = async () => {
				await handleDelete(district);
			};
			openConfirmDeleteModal({
				title: t('districts.forms.delete-title'),
				description: t('districts.forms.delete-description').replace(':districtName', district.name),
				onSubmit: handleSubmitDelete,
				onClose: closeConfirmDeleteModal,
				icon: <DeleteIcon sx={{ fontSize: 40 }} />,
				customBackgrondColorKey: 'white',
				customColorKey: 'red',
				customBorderColorKey: 'red',
				danger: true,
				leftButtonProps: {
					children: 'Cancel',
					variant: 'secondary',
					contraModeStyleButton: true,
					sx: buttonModalWindowStyles,
				},
				rightButtonProps: {
					children: 'Delete',
					variant: 'danger',
					contraModeStyleButton: true,
					sx: buttonModalWindowStyles,
				},
			});
		},
		[handleDelete, openConfirmDeleteModal]
	);

	const handleOnView = (district: DistrictDto) => {
		setSessionStorageDistrictId(district._id);
		setLocalStorageDistrictId(district._id);
		navigate(DistrictRoutes.Engagements.Root);
	};

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box sx={mergeSx(boxHeaderStyles, { justifyContent: 'space-between'})}>
				<CoreTextField
					{...register('search')}
					placeholder={t('common.search-by-name')}
					hasEndAdornment
					endAdornment={
						<InputAdornment position="end">
							<Search />
						</InputAdornment>
					}
					sx={{ width: '400px' }}
				/>
				<CoreButton sx={buttonHeaderStyles} onClick={handleOpenModalCreate}>
					<AddIcon /> {t('districts.create-district')}
				</CoreButton>
			</Box>

			<DistrictsTable
				data={districtsList ?? []}
				loading={isFetching}
				hasMore={data?.meta.hasNextPage}
				onDelete={handleOpenDeleteConfirmModal}
				onEdit={handleOpenModalEdit}
				onView={handleOnView}
				sorting={sortingArray}
				onSortingChange={handleSortingChange}
				fetchMore={fetchMore}
			/>
		</Container>
	);
};
