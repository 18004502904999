import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { OutlinedInput, OutlinedInputProps, SxProps, Theme } from '@mui/material';

export interface CoreInputProps {
	hasEndAdornment?: boolean;
	small?: boolean;
	fullWidth?: boolean;
	sx?: SxProps<Theme>;
}

export const CoreInput: FC<OutlinedInputProps & CoreInputProps> = styled(OutlinedInput, {
	shouldForwardProp: (name) => name !== 'small',
})<CoreInputProps>(({ theme, small, hasEndAdornment }) => ({
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: theme.colors.blueTransparent,
	},
	'&:hover .MuiOutlinedInput-notchedOutline': {
		borderColor: theme.colors.black,
	},
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: theme.colors.lightBlue,
	},
	'&.Mui-error .MuiOutlinedInput-notchedOutline': {
		borderColor: theme.colors.red,
	},
	'&.Mui-focused .MuiOutlinedInput-notchedOutline legend, &.Mui-error .MuiOutlinedInput-notchedOutline legend, &:not(.MuiInputBase-empty) .MuiOutlinedInput-notchedOutline legend':
		{
			maxWidth: 0,
		},
	'&.Mui-disabled': {
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.blueTransparent,
		},
		'.MuiOutlinedInput-input': {
			WebkitTextFillColor: 'unset',

			'&::placeholder': {
				color: theme.colors.blueTransparent,
			},
		},
	},
	'&.MuiOutlinedInput-root': {
		paddingRight: hasEndAdornment ? '16px' : 0,
	},
	'& .MuiOutlinedInput-input': {
		height: `${small ? 16 : 22}px`,
		padding: `${hasEndAdornment ? `13px 0 13px ${small ? 12 : 16}px` : `13px ${small ? 12 : 16}px`}`,
		fontSize: 14,
		fontWeight: 400,
		color: theme.colors.black,
		'&::placeholder': {
			opacity: 1,
			color: theme.colors.gray,
		},
	},
	'& fieldset': {
		height: '100%',
		top: '1px',
	},
	'& legend': {
		height: 0,
	},
	'& .MuiInputBase-inputMultiline': {
		padding: '0 0',
	},
	'&.MuiInputBase-multiline': {
		padding: '13px 16px',
	},
	'&.MuiInputBase-adornedStart': {
		paddingLeft: `${small ? 4 : 8}px`,
		'.MuiOutlinedInput-input': {
			paddingLeft: 12,
		},
	},
}));
