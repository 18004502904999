import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CalendarIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon {...props}>
		<svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M17 1.2C17 0.537258 16.4628 0 15.8 0C15.1373 0 14.6 0.537258 14.6 1.2V2.4H7.40001V1.2C7.40001 0.537258 6.86275 0 6.20001 0C5.53727 0 5.00001 0.537258 5.00001 1.2V2.4H3.53335C1.6924 2.4 0.200012 3.89238 0.200012 5.73333V20.6667C0.200012 22.5076 1.6924 24 3.53335 24H18.4667C20.3076 24 21.8 22.5076 21.8 20.6667V5.73333C21.8 3.89238 20.3076 2.4 18.4667 2.4H17V1.2ZM19.4 8.4V5.73333C19.4 5.21787 18.9821 4.8 18.4667 4.8H17V6C17 6.66274 16.4628 7.2 15.8 7.2C15.1373 7.2 14.6 6.66274 14.6 6V4.8H7.40001V6C7.40001 6.66274 6.86275 7.2 6.20001 7.2C5.53727 7.2 5.00001 6.66274 5.00001 6V4.8H3.53335C3.01788 4.8 2.60001 5.21787 2.60001 5.73333V8.4H19.4ZM2.60001 10.8H19.4V20.6667C19.4 21.1821 18.9821 21.6 18.4667 21.6H3.53335C3.01788 21.6 2.60001 21.1821 2.60001 20.6667V10.8Z"
				fill="currentColor"
			/>
		</svg>
	</SvgIcon>
);
