import { useMuiModal } from '../../../_core/hooks';
import { EngagementFormModal, EngagementFormModalProps } from '../../components/modal-form';
import {
	FamilyDto,
	EngagementDto,
	CustomFieldDto,
	ProviderDto,
	FieldDto,
	EmailStatusEnum,
	SurveyStatusEnum,
	EngagementStatusEnum,
	DefaultFieldTypeStatusEnum,
	FieldDataTypeEnum,
	ChildDto,
} from '@families-link/shared';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export type useOpenModalFormHookProps = {
	isCreate: boolean;
	districtId?: string | null;
	defaultValues?: any;
	submitButtonName?: string;
	toastText?: string;
	fieldsListFromDistrict?: CustomFieldDto[];
	providersList?: ProviderDto[];
	familiesList?: FamilyDto[];
	childrenList?: ChildDto[];
	generateFileUrlFunction: any;
	featchAction(values: any): Promise<void>;
	handlePagination?(): void;
};

export function useOpenModalForm(props: useOpenModalFormHookProps) {
	const {
		featchAction,
		handlePagination,
		isCreate = true,
		submitButtonName,
		toastText,
		fieldsListFromDistrict,
		providersList,
		familiesList,
		childrenList,
		generateFileUrlFunction,
		districtId,
	} = props;

	const initialDefaultValues = {
		districtId,
		children: [],
		provider: null,
		status: EngagementStatusEnum.New,
		description: '',
		...fieldsListFromDistrict?.reduce((allObj: any, el) => {
			if (el.dataType === FieldDataTypeEnum.File) {
				allObj[`field_${el._id}`] = [];
			} else {
				allObj[`field_${el._id}`] = '';
			}

			return allObj;
		}, {}),
		[`field_${fieldsListFromDistrict?.find((el: FieldDto) => el.name === 'Type')?._id}`]:
			DefaultFieldTypeStatusEnum.Project,
		day: null,
		time: null,
		appointmentDuration: '',
	};

	const transformEngagementForForm = (engagement: EngagementDto) => {
		const fieldsList = !fieldsListFromDistrict?.length ? engagement.customFields : fieldsListFromDistrict;
		const fieldDuration = fieldsList?.find((el: FieldDto) => el.name === 'Duration')?._id;
		const fieldType = fieldsList?.find((el: FieldDto) => el.name === 'Type')?._id;
		const valueType = engagement.customFieldsValues.find(
			(item) => item.fieldId.toString() === fieldType?.toString()
		)?.value;
		const transformedFields = engagement.customFieldsValues?.reduce((result: any, el) => {
			if (valueType === DefaultFieldTypeStatusEnum.Appointment) {
				if (el.fieldId.toString() === fieldDuration) {
					result[`day`] = el.value.startDate? dayjs(el.value.startDate) : null;
					result[`time`] = el.value.startDate? dayjs(el.value.startDate) : null;
					result[`appointmentDuration`] =el.value.startDate && el.value.endDate? dayjs(el.value.endDate).diff(el.value.startDate, 'millisecond'): '';
					result[`field_${el.fieldId}`] = [];
				} else {
					result[`field_${el.fieldId}`] = el.value;
				}
			} else {
				if (el.fieldId.toString() === fieldDuration) {
					result[`field_${el.fieldId}`] = [el.value.startDate, el.value.endDate];
				} else {
					result[`field_${el.fieldId}`] = el.value;
				}
			}
			result['deletedFiles'] = [];

			return result;
		}, {});
		const transformedEngagement = { ...Object.assign(engagement, {}), ...transformedFields, deletedFiles: [] };

		return transformedEngagement;
	};

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
		});
	};

	const { openModal, closeModal, updateCurrentModal } = useMuiModal<EngagementFormModalProps>(EngagementFormModal);

	const handleSubmit = async (values: any): Promise<void> => {
		try {
			await featchAction(values);
			showToast(toastText ?? 'Successfully performed', 'success');

			if (handlePagination) {
				handlePagination();
			}
			closeModal();
		} catch (error) {
			updateCurrentModal({ formError: error });
		}
	};

	const handleOpenModal = useCallback(
		(engagement?: EngagementDto) => {
			openModal({
				isCreate,
				formTitle: isCreate ? t('engagements.forms.create-engagement') : t('engagements.forms.edit-engagement'),
				defaultValues: engagement
					? transformEngagementForForm(engagement)
					: { ...initialDefaultValues, ...props.defaultValues },
				submitAction: handleSubmit,
				closeModal,
				submitButtonName,
				fieldsListFromDistrict,
				providersList: !isCreate
					? providersList?.filter((provider: ProviderDto) => provider.districtId === engagement?.districtId)
					: providersList,
				familiesList: !isCreate
					? familiesList?.filter((family: FamilyDto) => family.districtId === engagement?.districtId)
					: familiesList,
				childrenList: !isCreate
					? childrenList?.filter((child: ChildDto) => child.family?.districtId === engagement?.districtId)
					: childrenList,
				generateFileUrlFunction,
			});
		},
		[openModal, handleSubmit]
	);

	return handleOpenModal;
}
