import { SurveyTemplateDto } from '@families-link/shared';
import { FC, useCallback, useMemo } from 'react';
import { CoreTableActionsCell, CellAction } from '../../../_core/components/_ui/core-table';
import { SurveyTemplatesTableHandlers } from './survey-templates-table.component';
import { AttachIcon, DeleteIcon, EditIcon, EyeIcon } from '../../../_core/components/icons';
import { useLocation } from 'react-router-dom';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { CoreTooltip } from '../../../_core/components/_ui/core-tooltip';

export interface SurveyTemplatesTableActionsProps extends SurveyTemplatesTableHandlers {
	surveyTemplate: SurveyTemplateDto;
	adminTemplate: boolean;
}

export const SurveyTemplatesTableActions: FC<SurveyTemplatesTableActionsProps> = (props) => {
	const { surveyTemplate, onEdit, onDelete, onView, onAttachDistrict, adminTemplate } = props;

	const location = useLocation();
	const isAdminRoute = location.pathname.startsWith('/admin');

	const handleDeleteSurveyTemplate = useCallback(() => {
		if (!surveyTemplate) {
			return;
		}

		onDelete?.(surveyTemplate);
	}, [onDelete, surveyTemplate]);

	const handleEditSurveyTemplate = useCallback(() => {
		if (!surveyTemplate) {
			return;
		}

		onEdit?.(surveyTemplate);
	}, [onEdit, surveyTemplate]);

	const handleViewSurveyTemplate = useCallback(() => {
		if (!surveyTemplate) {
			return;
		}

		onView?.(surveyTemplate);
	}, [onView, surveyTemplate]);

	const handleAttachDistrictToSurveyTemplate = useCallback(() => {
		if (!surveyTemplate) {
			return;
		}

		onAttachDistrict?.(surveyTemplate);
	}, [onAttachDistrict, surveyTemplate]);

	const actions = useMemo<CellAction[]>(() => {
		const lockImageObject: CellAction = {
			id: 'lock',
			type: 'react-node',
			node: (
				<CoreTooltip title={'This survey is managed by Superadmin'} placement="top" arrow>
					<HttpsOutlinedIcon color="primary" fontSize="small" />
				</CoreTooltip>
			),
		};

		return [
			...(!isAdminRoute && adminTemplate ? [lockImageObject] : []),
			{
				id: 'view',
				type: 'button',
				props: {
					children: <EyeIcon sx={{ fontSize: 30 }} color="primary" />,
					onClick: handleViewSurveyTemplate,
				},
			},
			{
				id: 'more',
				type: 'menu',
				props: {
					disabled: !isAdminRoute && adminTemplate,
					options: [
						{
							id: 'attach',
							label: 'Attach districts',
							icon: <AttachIcon color="primary" />,
							onClick: handleAttachDistrictToSurveyTemplate,
							hidden: !adminTemplate,
						},
						{
							id: 'edit',
							label: 'Edit',
							icon: <EditIcon color="primary" />,
							onClick: handleEditSurveyTemplate,
						},
						{
							id: 'delete',
							label: 'Delete',
							icon: <DeleteIcon color="primary" />,
							onClick: handleDeleteSurveyTemplate,
						},
					],
				},
			},
		];
	}, [
		handleEditSurveyTemplate,
		handleViewSurveyTemplate,
		handleDeleteSurveyTemplate,
		handleAttachDistrictToSurveyTemplate,
		adminTemplate,
		isAdminRoute,
	]);

	return <CoreTableActionsCell actions={actions} />;
};
