import React, { FC, memo } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

export type DotsLoaderProps = {
	size?: number;
	color?: string;
	sx?: SxProps<Theme>;
};

const animationName = 'SystemDotsLoader';

export const DotsLoader: FC<DotsLoaderProps> = memo((props) => {
	const { size = 60, color = '#0000', sx } = props;
	const g = `no-repeat radial-gradient(circle closest-side,${color} 90%,#0000)`;

	return (
		<Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2}}>
		<Box
			sx={{
				width: size,
				aspectRatio: '2',
				background: `${g} 0% 50%, ${g} 50% 50%, ${g} 100% 50%`,
				backgroundSize: 'calc(100%/3) 50%',
				animation: `${animationName} 1s infinite linear` ,
			}}
		/>
		</Box>
	);
});
