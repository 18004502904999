import { useDispatch } from 'react-redux';
import { useAppSelector } from '../store';
import { clearSessionStorageDistrictId, setSessionStorageDistrictId } from '../store/sesionStorage.slice';

export type SessionStorageKeys = 'districtId';

export const useSessionStorage = (key: SessionStorageKeys): [string | null, (newValue: any) => void, () => void] => {
	const dispatch = useDispatch();

	const reduxSesionStorageValue: string | null = useAppSelector((state) => state.sessionStorage[key]);

	const setSessionStoredValue = (newValue: any) => {
		try {
			dispatch(setSessionStorageDistrictId(newValue));
		} catch (error) {
			console.error(`Error setting sessionStorage key "${key}":`, error);
		}
	};

	const removeStoredValue = () => {
		try {
			dispatch(clearSessionStorageDistrictId());
		} catch (error) {
			console.error('Error removing sessionStorage key:', key, error);
		}
	};

	return [reduxSesionStorageValue, setSessionStoredValue, removeStoredValue];
};
