import { FC, useRef, useState, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Container, InputAdornment, MenuItem, Typography } from '@mui/material';
import { CoreInput } from '../../../_core/components/_ui/core-input';
import {
	useCreateProviderMutation,
	useDeleteProviderMutation,
	useGetAllProvidersByDistrictIdQuery,
	useImportProvidersMutation,
	useUpdateProviderMutation,
} from '../../../../store/api/providers.api';
import { useSearch, useSortingParams, useLanguageFilter, useMuiModal } from '../../../_core/hooks';
import { ProvidersDistrictTable } from '../../components/provider-district-table/provider-district-table.component';
import { LanguageEnum, ProviderDto, ProviderImportDto, ProviderInfoDto, UserRolesEnum } from '@families-link/shared';
import { DistrictRoutes } from '../../../district/constants';
import { getLocalStorageDistrictId } from '../../../_core/utils';
import { AutocompleteTextField, CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import { useSnackbar } from 'notistack';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import AddIcon from '@mui/icons-material/Add';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { ProviderImportModal } from '../../components/modal-import-form/modal-import-form.component';
import { CoreModal } from '../../../_core/components/_ui/core-modal';
import { containerStyles } from '../../../_core/components/_ui/core-modal/core-modal.styles';
import { useSessionStorage } from '../../../../hooks';
import { useOpenModalForm } from '../../hooks/use-open-modal-form.hook';
import { useCurrentAccessTokenSelector } from '../../../auth';
import { ConfirmationModal, ConfirmationModalProps } from '../../../_core/components/confirmation-modal';
import { ChipCloseIcon, DeleteIcon } from '../../../_core/components/icons';
import {
	boxHeaderStyles,
	buttonHeaderStyles,
	buttonModalWindowStyles,
	chipDeleteIconStyles,
} from '../../../_core/styles';
import { CoreCheckbox } from '../../../_core/components/_ui/core-checkbox';
import { mergeSx } from 'merge-sx';
import { Search } from '../../../_core/constants/icons.constants';
import { CoreOverflowTypographyTooltip } from '../../../_core/components/_ui/core-overflow-tooltip';

export const ProvidersListPage: FC = () => {
	const [sessionStorageDistrictId] = useSessionStorage('districtId');
	const districtId = sessionStorageDistrictId ?? getLocalStorageDistrictId();
	const currentToken = useCurrentAccessTokenSelector();
	const isRequester = currentToken?.payload.sur === UserRolesEnum.Requester;

	const { register, search } = useSearch();
	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const { register: registerLang, getValues, control, languages } = useLanguageFilter();

	const navigate = useNavigate();

	const {
		data: providers,
		isFetching,
		refetch,
	} = useGetAllProvidersByDistrictIdQuery(
		{
			districtId,
			languages: languages.join(','),
			sortBy: sortingParams.orderField,
			sortOrder: sortingParams.order,
			searchQuery: search,
		},
		{ skip: !districtId }
	);

	const [t] = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const [createProvider] = useCreateProviderMutation();

	const featchActionCreate = async (data: ProviderInfoDto) => {
		await createProvider(data).unwrap();
	};

	const handleOpenModalCreate = useOpenModalForm({
		featchAction: featchActionCreate,
		isCreate: true,
		districtId,
		submitButtonName: t('families.forms.buttons.create'),
		toastText: 'Provider was successfully created',
		formTitle: t('providers.create-provider'),
	});

	const [updateProvider] = useUpdateProviderMutation();
	const featchActionEdit = async (data: ProviderDto) => {
		const providerId = data?._id.toString();

		if (providerId) {
			await updateProvider({ id: providerId, updateData: data }).unwrap();
		}
	};

	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		isCreate: false,
		districtId,
		submitButtonName: t('providers.save-changes'),
		toastText: 'Provider updated successfully',
		formTitle: 'Edit Provider',
	});
	const [deleteProvider] = useDeleteProviderMutation();

	const { openModal: openConfirmDeleteModal, closeModal: closeConfirmDeleteModal } =
		useMuiModal<ConfirmationModalProps>(ConfirmationModal);

	const handleOnDelete = useCallback(
		async (providerId: string) => {
			try {
				await deleteProvider(providerId).unwrap();
				enqueueSnackbar('Provider deleted successfully!', { variant: 'success' });
				closeConfirmDeleteModal();
			} catch (error) {
				enqueueSnackbar('Failed to delete provider', { variant: 'error' });
				console.log(error);
			}
		},
		[deleteProvider, enqueueSnackbar]
	);

	const handleOpenDeleteConfirmModal = useCallback(
		(provider: ProviderDto) => {
			const handleSubmitDelete = async () => {
				await handleOnDelete(provider._id);
			};
			openConfirmDeleteModal({
				title: t('providers.confirm-deletion'),
				description: t('providers.sure-delete').replace(':provider_fullname', provider.fullName),
				onSubmit: handleSubmitDelete,
				onClose: closeConfirmDeleteModal,
				icon: <DeleteIcon sx={{ fontSize: 40 }} />,
				customBackgrondColorKey: 'white',
				customColorKey: 'red',
				customBorderColorKey: 'red',
				danger: true,
				leftButtonProps: {
					children: t('families.forms.buttons.cancel'),
					variant: 'secondary',
					contraModeStyleButton: true,
					sx: buttonModalWindowStyles,
				},
				rightButtonProps: {
					children: t('families.forms.buttons.delete'),
					variant: 'danger',
					contraModeStyleButton: true,
					sx: buttonModalWindowStyles,
				},
			});
		},
		[handleOnDelete, openConfirmDeleteModal]
	);

	const handleOnView = (provider: ProviderDto) => {
		navigate(generatePath(DistrictRoutes.Providers.Details, { providerId: provider._id }));
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);

	const [importProviders] = useImportProvidersMutation();

	const { openModal, closeModal } = useMuiModal(ProviderImportModal);

	const handleOpenModal = () => {
		openModal({
			submitAction: async (formData: FormData) => {
				enqueueSnackbar(t('providers.import-start'), {
					variant: 'info',
					autoHideDuration: 3000,
				});
				closeModal();
				try {
					const districtId = getLocalStorageDistrictId() || undefined;

					const result = await importProviders({ formData, districtId }).unwrap();

					const { success, failed } = result;

					const successCount = success.length;
					const failureCount = failed.length;
					const total = successCount + failureCount;

					const message = (
						<>
							<Typography variant="body1" fontWeight="bold">
								{successCount} of {total} providers were successfully imported.
							</Typography>
							{failureCount > 0 && (
								<Box mt={2}>
									<Typography variant="body1" fontWeight="bold">
										{t('providers.import-providers')}
									</Typography>
									{failed.map((f: any, index: number) => (
										<Box key={index} mt={1}>
											<Typography variant="body2">- {f.row['Full Name']}</Typography>
											{f.error && (
												<Box ml={2}>
													<Typography variant="body2" color="error">
														Reasons:
													</Typography>

													{f.error.split(';').map((reason: string, idx: number) => (
														<MenuItem key={idx}>
															<Typography variant="body2" color="error">
																{reason.trim()}
															</Typography>
														</MenuItem>
													))}
												</Box>
											)}
										</Box>
									))}
								</Box>
							)}
						</>
					);

					setModalContent(message);
					setModalOpen(true);
				} catch (error) {
					console.error('File Import Error:', error);
					setModalContent(
						<Typography variant="body1" color="error">
							{t('providers.import-error')}
						</Typography>
					);
					setModalOpen(true);
				}
			},
			closeModal,
			formTitle: 'Import Providers',
			submitButtonName: 'Import',
		});
	};

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box sx={mergeSx(boxHeaderStyles, { justifyContent: 'space-between' })}>
				<Box>
					<CoreInput
						sx={{ marginRight: 1 }}
						{...register('search')}
						placeholder={t('common.search-by-name')}
						hasEndAdornment
						endAdornment={
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						}
					/>
					<CoreAutoComplete
						multiple={true}
						{...registerLang('languages')}
						control={control}
						controlSx={{ minWidth: '220px', marginRight: 1 }}
						isFilter
						options={Object.values(LanguageEnum)}
						getOptionLabel={(option) => {
							const language = getObjectLanguage(option);

							return language ? language.name : '';
						}}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option === value}
						renderInput={(params) => (
							<AutocompleteTextField
								{...params}
								placeholder={!getValues('languages')?.length ? t('common.filter-lang') : ''}
								sx={{
									'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
										padding: '4.5px 4px 5.5px 5px',
										minWidth: 0,
									},
									'& .MuiInputBase-input': {
										height: '100%',
										padding: '0',
									},
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: (theme) => theme.colors.blue,
										},
									},
								}}
							/>
						)}
						renderOption={(props, option, { selected }) => {
							const language = getObjectLanguage(option);

							return (
								<MenuItem {...props} key={language?.value}>
									<CoreCheckbox checked={selected} sx={{ mr: 2, p: 0, ml: 0 }} />
									<CoreOverflowTypographyTooltip>{language?.name}</CoreOverflowTypographyTooltip>
								</MenuItem>
							);
						}}
						renderTags={(value, getTagProps) => {
							const count = value.length - 1;

							return [
								value[0] && (
									<Chip
										sx={chipDeleteIconStyles}
										{...getTagProps({ index: 0 })}
										label={getObjectLanguage(value[0])?.name}
										deleteIcon={<ChipCloseIcon />}
									/>
								),
								count > 0 && (
									<Chip
										sx={chipDeleteIconStyles}
										{...getTagProps({ index: count })}
										label={`+${count}`}
										deleteIcon={<ChipCloseIcon />}
									/>
								),
							];
						}}
					/>
				</Box>
				{isRequester ? null : (
					<Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto', alignItems: 'center' }}>
						<CoreButton
							sx={{ display: 'flex', gap: 1, marginRight: 1 }}
							variant="base"
							circle
							size="large"
							onClick={handleOpenModal}
						>
							<FileUploadOutlinedIcon color="primary" />
						</CoreButton>
						<CoreModal
							open={modalOpen}
							containerSx={containerStyles}
							bodySx={{ maxHeight: 400, overflowY: 'auto' }}
							onClose={() => {
								setModalOpen(false);
							}}
							hideCloseIcon={false}
						>
							{modalContent}
							<Box mt={3} display="flex" justifyContent="flex-end">
								<CoreButton
									onClick={() => {
										setModalOpen(false);
									}}
								>
									OK
								</CoreButton>
							</Box>
						</CoreModal>

						<CoreButton
							onClick={() => {
								handleOpenModalCreate();
							}}
							sx={buttonHeaderStyles}
						>
							<AddIcon /> {t('providers.add-provider')}
						</CoreButton>
					</Box>
				)}
			</Box>
			<ProvidersDistrictTable
				data={providers || []}
				loading={isFetching}
				sorting={sortingArray}
				onSortingChange={handleSortingChange}
				onView={handleOnView}
				onDelete={handleOpenDeleteConfirmModal}
				onEdit={handleOpenModalEdit}
			/>
		</Container>
	);
};
