import { FC, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetSurveyTemplateByIdQuery } from '../../../../store/api/survey.api';
import { SurveyQuestionRender } from '../../components/question/question.component';
import { SurveyHeader } from '../../../_core/components/header-survey/header-survey.component';
import { skipToken } from '@reduxjs/toolkit/query';

const selectLanguageObject: { [key: string]: string } = {
	Arabic: 'ar',
	ar: 'ar',
	Spanish: 'es',
	es: 'es',
};

export const SurveyPreviewPage: FC = () => {
	const { t, i18n } = useTranslation('survey');
	const { surveyTemplateId } = useParams();

	const { data: surveyTemplate, isError } = useGetSurveyTemplateByIdQuery(surveyTemplateId ?? skipToken);

	const browserLanguage = navigator.language;

	const templateLanguageArabicOrSpanishArray = ['Arabic', 'Spanish', 'Spanish (Spain)'];
	const browserLanguageArabicOrSpanishArray = ['ar', 'es'];

	const selectedLanguage =
		surveyTemplate?.language && templateLanguageArabicOrSpanishArray.includes(surveyTemplate?.language)
			? selectLanguageObject[surveyTemplate?.language]
			: browserLanguageArabicOrSpanishArray.includes(browserLanguage)
			? selectLanguageObject[browserLanguage]
			: 'en';

	useEffect(() => {
		i18n.changeLanguage(selectedLanguage);
	}, [i18n, surveyTemplate?.language, browserLanguage]);

	if (!surveyTemplate) {
		return null;
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				minHeight: 0,
				height: '100%',
				direction: selectedLanguage === 'ar' ? 'rtl' : 'ltr',
				textAlign: selectedLanguage === 'ar' ? 'right' : 'left',
			}}
		>
			{isError ? (
				<Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Typography>{t('survey-templates.deleted-survey-template')}</Typography>
				</Box>
			) : (
				<Box sx={{ width: '100%', height: '100%' }}>
					<Box component="header" sx={{ mb: 1 }}>
						<SurveyHeader title={surveyTemplate?.title ?? 'Survey'} />
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
						{surveyTemplate?.questions?.map((question, index) => (
							<Paper
								key={question._id.toString()}
								elevation={0}
								sx={{ p: 3, display: 'flex', flexDirection: 'column' }}
							>
								<Typography fontSize={14} sx={{ fontWeight: '500', mb: '10px' }}>
									{index + 1}. {question.text}{' '}
									{question.isRequired && <span style={{ color: 'red', marginLeft: '4px', fontWeight: '300' }}>*</span>}
								</Typography>
								<SurveyQuestionRender question={question} />
							</Paper>
						))}
					</Box>
				</Box>
			)}
		</Box>
	);
};
