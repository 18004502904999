import { FC } from 'react';
import { useGetProviderByIdQuery, useUpdateProviderMutation } from '../../../../store/api/providers.api';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { InHouse, Outsource, Verified, AvatarIcon } from '../../../_core/constants/icons.constants';
import {
	ProviderDto,
	UserRolesEnum,
	WorkStatusEnum,
	ServiceTypeStatusEnum,
} from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { useCurrentAccessTokenSelector } from '../../../auth';
import { skipToken } from '@reduxjs/toolkit/query';
import { FillEditIcon } from '../../../_core/components/icons';
import { useOpenModalForm } from '../../hooks/use-open-modal-form.hook';

interface ProvidersDetailsProps {
	providerId?: string;
}

export const ProvidersDetails: FC<ProvidersDetailsProps> = ({ providerId }) => {
	const currentToken = useCurrentAccessTokenSelector();
	const isRequester = currentToken?.payload.sur === UserRolesEnum.Requester;

	const { data: provider } = useGetProviderByIdQuery(providerId ?? skipToken);
	const [t] = useTranslation();

	const [updateProvider] = useUpdateProviderMutation();
	const featchActionEdit = async (data: ProviderDto) => {
		const providerId = data?._id.toString();

		if (providerId) {
			await updateProvider({ id: providerId, updateData: data }).unwrap();
		}
	};

	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		isCreate: false,
		submitButtonName: t('providers.save-changes'),
		toastText: 'Provider updated successfully',
		formTitle: 'Edit Provider',
	});

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', padding: 3 }}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					width: '100%',
				}}
			>
				<Box>
					<Typography variant="h6" sx={{ fontWeight: 500, fontSize: '16px' }}>
						{t('providers.details')}
					</Typography>
				</Box>

				<Box sx={{ paddingTop: 0, paddingLeft: 0 }}>
					<IconButton
						color="primary"
						disabled={isRequester}
						onClick={() => {
							handleOpenModalEdit(provider);
						}}
					>
						<FillEditIcon sx={{ fontSize: 32 }} />
					</IconButton>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%' }}>
				<Box
					sx={{
						flexGrow: 1,
						padding: 1,
						display: 'flex',
						minWidth: '300px',
						gap: 3,
					}}
				>
					{provider?.imageUrl ? (
						<Avatar
							src={provider?.imageUrl}
							alt={provider?.fullName}
							style={{ width: 98, height: 98, borderRadius: '32px' }}
						/>
					) : (
						<Box
							style={{
								width: 98,
								height: 98,
								borderRadius: '32px',
								backgroundColor: 'var(--blue-8-opacity, rgba(36, 57, 106, 0.08))',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<AvatarIcon />
						</Box>
					)}
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							maxWidth: '100%',
							overflow: 'hidden',
						}}
					>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayLightBlue }}>
								{t('providers.full-name')}
							</Typography>
							<Typography sx={{ fontWeight: 500, overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{provider?.fullName}
							</Typography>
						</Box>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayLightBlue }}>
								{t('providers.target-lang')}
							</Typography>
							<Box sx={{ fontWeight: 500 }}>{provider?.targetLanguage?.join(', ')}</Box>
						</Box>
					</Box>
				</Box>

				<Box
					sx={{
						flexGrow: 1,
						padding: 1,
						display: 'flex',
						height: '100%',
						minWidth: '120px',
					}}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayLightBlue }}>
								{t('providers.type')}
							</Typography>
							<Typography sx={{ fontWeight: 500 }}>
								{provider?.verificationType ? (
									<>
										{t('providers.verified')} <Verified style={{ marginLeft: '8px' }} />
									</>
								) : (
									t('providers.no-verified')
								)}
							</Typography>
						</Box>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayLightBlue }}>
								{t('providers.status')}
							</Typography>
							<Typography sx={{ fontWeight: 500 }}>
								{provider?.workStatus === WorkStatusEnum.InHouse ? (
									<>
										<InHouse style={{ marginRight: '8px' }} />
										{t('providers.work-in-house')}
									</>
								) : (
									<>
										<Outsource style={{ marginRight: '8px' }} />
										{t('providers.work-outsource')}
									</>
								)}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						padding: 1,
						display: 'flex',
						height: '100%',
						minWidth: '120px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							maxWidth: '100%',
							overflow: 'hidden',
						}}
					>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayLightBlue }}>
								{t('providers.service-type')}
							</Typography>
							<Typography sx={{ fontWeight: 500 }}>
								{provider?.serviceType === ServiceTypeStatusEnum.Appointment
									? t('providers.appointment')
									: provider?.serviceType === ServiceTypeStatusEnum.Project
									? t('providers.project')
									: provider?.serviceType === ServiceTypeStatusEnum.Both
									? `${t('providers.appointment')}, ${t('providers.project')}`
									: ''}
							</Typography>
						</Box>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayLightBlue }}>
								{t('providers.email')}
							</Typography>
							<Typography sx={{ fontWeight: 500, overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{provider?.email}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
