import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
	DefaultFieldTypeStatusEnum,
	EmailStatusColorEnum,
	EmailStatusEnum,
	EngagementDto,
	EngagementStatusColorEnum,
	EngagementStatusEnum,
	PermissionDto,
	SurveyStatusColorEnum,
	SurveyStatusEnum,
	WorkStatusEnum,
} from '@families-link/shared';
import { EngagementsTableHandlers } from '../../components/engagements-table/engagements-table.component';
import { EngagementsTableActions } from '../../components/engagements-table/engagements-table-actions.component';
import { Verified } from '../../../_core/constants/icons.constants';
import { Avatar, Box, Typography } from '@mui/material';
import { InHouseIcon, OutsourceIcon } from '../../../_core/components/icons';
import { tableCellTypographyOverflowStyles } from '../../../_core/styles';
import { mergeSx } from 'merge-sx';
const helper = createColumnHelper<EngagementDto & { createdAt: string; updatedAt: string }>();

export function useEngagementsTableColumns(
	handlers: EngagementsTableHandlers,
	permission?: PermissionDto
): ColumnDef<EngagementDto, string>[] {
	const { onDelete, onEdit, onView, onSendEmail, onSendSMS, onArchive, onUnarchive } = handlers;

	const [t] = useTranslation();

	const result = useMemo(
		() => [
			...(permission?.read
				? [
						helper.accessor('district', {
							header: 'District',
							meta: {
								flex: 1,
								useCustomOverflowContainer: true,
							},
							cell: ({ getValue }) => (
								<Typography sx={tableCellTypographyOverflowStyles} data-overflow="true">
									{getValue()?.name}
								</Typography>
							),
						}) as ColumnDef<EngagementDto, string>,
				  ]
				: []),
			helper.accessor((row) => row.customFieldsValues?.find((el) => el.fieldName === 'Title'), {
				header: t('engagements.columns.title'),
				meta: {
					flex: 1.2,
					useCustomOverflowContainer: true,
				},
				cell: ({ getValue }) => (
					<Typography sx={mergeSx({ fontWeight: 500 }, tableCellTypographyOverflowStyles)} data-overflow="true">
						{getValue()?.valuePlaceholder ? getValue()?.valuePlaceholder : '-'}
					</Typography>
				),
			}) as ColumnDef<EngagementDto, string>,
			helper.accessor('customId', {
				header: t('engagements.columns.id'),
				meta: {
					flex: 0.6,
					useCustomOverflowContainer: true,
				},
				cell: ({ getValue }) => (
					<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
						{getValue()}
					</Typography>
				),
			}) as ColumnDef<EngagementDto, string>,

			helper.accessor('provider', {
				header: t('engagements.columns.providers'),
				meta: {
					flex: 1.7,
					useCustomOverflowContainer: true,
				},
				cell: ({ getValue }) => {
					const imageUrl = getValue()?.imageUrl;
					const fullName = getValue()?.fullName;
					const workStatus = getValue()?.workStatus;
					const verificationType = getValue()?.verificationType;
					let workStatusIcon;
					switch (workStatus) {
						case WorkStatusEnum.InHouse:
							workStatusIcon = <InHouseIcon sx={{ fontSize: '12px', marginLeft: '8px' }} />;
							break;
						case WorkStatusEnum.Outsource:
							workStatusIcon = <OutsourceIcon sx={{ fontSize: '12px', marginLeft: '8px' }} />;
							break;
					}

					return fullName ? (
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
							<Box position="relative" sx={{ height: 28 }}>
								<Avatar src={imageUrl} alt={fullName} style={{ width: 24, height: 24, borderRadius: '6px' }} />
								{verificationType ? (
									<Verified style={{ position: 'absolute', bottom: 0, right: 0, height: 12, width: 12 }} />
								) : null}
							</Box>
							<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
								{fullName}
							</Typography>
							{workStatusIcon}
						</Box>
					) : (
						<Typography>{'-'}</Typography>
					);
				},
			}) as ColumnDef<EngagementDto, string>,
			helper.accessor('language', {
				header: t('engagements.columns.language'),
				meta: {
					flex: 0.7,
					useCustomOverflowContainer: true,
				},
				cell: ({ getValue }) => (
					<Typography sx={tableCellTypographyOverflowStyles} data-overflow="true">
						{getValue() ? getValue() : '-'}
					</Typography>
				),
			}) as ColumnDef<EngagementDto, string>,
			...(!permission?.isDetailsPage
				? [
						helper.accessor((row) => row.customFieldsValues?.find((el) => el.fieldName === 'School'), {
							header: t('engagements.columns.school'),
							meta: {
								flex: 0.7,
								useCustomOverflowContainer: true,
							},
							cell: ({ getValue }) => (
								<Typography sx={tableCellTypographyOverflowStyles} data-overflow="true">
									{getValue()?.valuePlaceholder ? getValue()?.valuePlaceholder : '-'}
								</Typography>
							),
						}) as ColumnDef<EngagementDto, string>,
						helper.accessor((row) => row.customFieldsValues?.find((el) => el.fieldName === 'Program'), {
							header: t('engagements.columns.program'),
							meta: {
								flex: 0.7,
								useCustomOverflowContainer: true,
							},
							cell: ({ getValue }) => (
								<Typography sx={tableCellTypographyOverflowStyles} data-overflow="true">
									{getValue()?.valuePlaceholder ? getValue()?.valuePlaceholder : '-'}
								</Typography>
							),
						}) as ColumnDef<EngagementDto, string>,
				  ]
				: []),
			helper.accessor((row) => row.customFieldsValues?.find((el) => el.fieldName === 'Duration'), {
				header: t('engagements.columns.date'),
				meta: {
					flex: 1.4,
					useCustomOverflowContainer: true,
				},
				cell: ({ getValue, row }) => {
					const fieldDurationValue = getValue()?.value;
					const fieldTypeValue = row.original.customFieldsValues?.find((el) => el.fieldName === 'Type')?.value;

					return (
						<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
							{fieldDurationValue?.startDate
								? fieldTypeValue === DefaultFieldTypeStatusEnum.Appointment
									? `${format(new Date(fieldDurationValue.startDate), 'MM.dd.yyyy')}`
									: `${format(new Date(fieldDurationValue.startDate), 'MM.dd.yyyy')}-${format(
											new Date(fieldDurationValue.endDate),
											'MM.dd.yyyy'
									  )}`
								: '-'}
						</Typography>
					);
				},
			}) as ColumnDef<EngagementDto, string>,
			helper.accessor('status', {
				header: t('engagements.columns.status'),
				meta: {
					flex: 0.6,
					useCustomOverflowContainer: true,
				},
				cell: ({ getValue }) => {
					const engagementStatus = getValue() as EngagementStatusEnum;

					return engagementStatus ? (
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
							<Box
								sx={{
									height: 10,
									width: 10,
									borderRadius: '50%',
									backgroundColor: EngagementStatusColorEnum[engagementStatus],
									flexShrink: 0,
								}}
							></Box>
							<Typography data-overflow="true" variant="body2" sx={tableCellTypographyOverflowStyles}>
								{engagementStatus}
							</Typography>
						</Box>
					) : (
						<Typography variant="body2">{'-'}</Typography>
					);
				},
			}) as ColumnDef<EngagementDto, string>,
			helper.accessor((row) => row.customFieldsValues?.find((el) => el.fieldName === 'Email status'), {
				header: t('engagements.columns.email-status'),
				meta: {
					flex: 0.7,
					useCustomOverflowContainer: true,
				},
				cell: ({ getValue }) => {
					const fieldEmailStatusValue = getValue()?.value as EmailStatusEnum;

					return fieldEmailStatusValue ? (
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
							<Box
								sx={{
									height: 10,
									width: 10,
									borderRadius: '50%',
									backgroundColor: EmailStatusColorEnum[fieldEmailStatusValue],
									flexShrink: 0,
								}}
							></Box>
							<Typography data-overflow="true" variant="body2" sx={tableCellTypographyOverflowStyles}>
								{fieldEmailStatusValue}
							</Typography>
						</Box>
					) : (
						'-'
					);
				},
			}) as ColumnDef<EngagementDto, string>,
			helper.accessor((row) => row.customFieldsValues?.find((el) => el.fieldName === 'Survey status'), {
				header: t('engagements.columns.survey-status'),
				meta: {
					flex: 0.7,
					useCustomOverflowContainer: true,
				},
				cell: ({ getValue }) => {
					const fieldSurveyValue = getValue()?.value as SurveyStatusEnum;

					return fieldSurveyValue ? (
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
							<Box
								sx={{
									height: 10,
									width: 10,
									borderRadius: '50%',
									backgroundColor: SurveyStatusColorEnum[fieldSurveyValue],
									flexShrink: 0,
								}}
							></Box>
							<Typography data-overflow="true" variant="body2" sx={tableCellTypographyOverflowStyles}>
								{fieldSurveyValue}
							</Typography>
						</Box>
					) : (
						'-'
					);
				},
			}) as ColumnDef<EngagementDto, string>,

			helper.display({
				id: 'actions',
				meta: {
					flex: 1,
				},
				cell: (props) => (
					<EngagementsTableActions
						engagement={props.row.original}
						onDelete={onDelete}
						onEdit={onEdit}
						onView={onView}
						onSendEmail={onSendEmail}
						onSendSMS={onSendSMS}
						onArchive={onArchive}
						onUnarchive={onUnarchive}
					/>
				),
			}) as ColumnDef<EngagementDto>,
		],
		[t, onDelete, onEdit, onView, onArchive]
	);

	return result;
}
