import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';

export const useSearch = () => {
	const [search, setSearch] = useState('');
	const { register, watch } = useForm({
		defaultValues: { search: '' },
	});
	const searchTerm = watch('search');
	const debouncedUpdate = useCallback(
		debounce((term: string) => {
			setSearch(term);
		}, 300),
		[]
	);

	useEffect(() => {
		debouncedUpdate(searchTerm);
	}, [searchTerm, debouncedUpdate]);

	return {
		register,
		search,
	};
};
