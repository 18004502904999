import React, { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { CreateDistrictDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import {
	containerButtonStyles,
	buttonStyles,
	leftButtonStyles,
	inputBoxStyles,
	modalBoxStyles,
	errorTextStyles,
} from './create-form.styles';
import { buttonModalWindowStyles, mainTitleModalWindowStyles } from '../../../_core/styles';
import { mergeSx } from 'merge-sx';

export type CreateDistrictFormModalProps = CoreModalProps & {
	formError?: any;
	submitAction(values: CreateDistrictDto): Promise<void>;
	closeCreateModal(): void;
};

export const CreateDistrictFormModal: FC<CreateDistrictFormModalProps> = (props) => {
	const [t] = useTranslation();

	const { submitAction, formError, closeCreateModal, ...modalProps } = props;
	const resolver = classValidatorResolver(CreateDistrictDto);
	const defaultValues = { name: '' };

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = useForm({
		defaultValues,
		resolver,
		mode: 'all',
	});

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const handleFormSubmit = useCallback(
		async (values: CreateDistrictDto) => {
			await submitAction(values);
		},
		[submitAction]
	);

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0, px: 5, pb: 5 }} paperSx={{ width: '100%', maxWidth: '550px' }}>
			<Box>
				<Typography variant="h5" sx={mergeSx(mainTitleModalWindowStyles, { mb: 5 })}>
					{t('districts.forms.create-districts')}
				</Typography>
				<Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
					<Box sx={inputBoxStyles}>
						<CoreTextField
							label={t('districts.forms.labels.name')}
							requiredMark
							fullWidth
							placeholder={t('districts.forms.placeholders.name') ?? ''}
							{...register('name')}
							error={!!errors['name']}
							helperText={errors['name'] && errors['name']?.message}
						/>
					</Box>
					{formError && (
						<Typography sx={errorTextStyles}>
							{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
						</Typography>
					)}
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-end',
							alignItems: 'center',
							gap: 3,
						}}
					>
						<CoreButton onClick={closeCreateModal} variant="secondary" sx={buttonModalWindowStyles}>
							{t('districts.forms.buttons.close-form')}
						</CoreButton>
						<CoreButton
							type="submit"
							contraModeStyleButton
							disabled={disableSubmit}
							loading={loadingSubmit}
							sx={buttonModalWindowStyles}
						>
							{t('districts.forms.buttons.create-district')}
						</CoreButton>
					</Box>
				</Box>
			</Box>
		</CoreModal>
	);
};
