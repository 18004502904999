import { useMuiModal } from '../../../_core/hooks';
import { FamilyFormModal, FamilyFormModalProps } from '../../components/modal-form';
import { FamilyDto, CreateFamilyDto, UpdateFamilyDto } from '@families-link/shared';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type useOpenModalFormHookProps = {
	isCreate: boolean;
	districtId?: string | null;
	defaultValues?: any;
	submitButtonName?: string;
	toastText?: string;
	featchAction(values: CreateFamilyDto | UpdateFamilyDto): Promise<void>;
	handlePagination?(): void;
};

export function useOpenModalForm(props: useOpenModalFormHookProps) {
	const { districtId } = props;

	const initialDefaultValues = {
		fullName: '',
		email: null,
		phoneNumber: '',
		location: '',
		preferedLanguage: [],
		preferedSendMethod: '',
		districtId,
		imageUrl: null,
		children: [{ name: '', studentId: '' }],
	};

	const {
		featchAction,
		handlePagination,
		isCreate = true,
		defaultValues = initialDefaultValues,
		submitButtonName,
		toastText,
	} = props;

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
		});
	};

	const { openModal, closeModal, updateCurrentModal } = useMuiModal<FamilyFormModalProps>(FamilyFormModal);

	const handleSubmit = async (values: CreateFamilyDto | UpdateFamilyDto): Promise<void> => {
		try {
			await featchAction(values);
			showToast(toastText || 'Successfully performed', 'success');

			if (handlePagination) {
				handlePagination();
			}

			closeModal();
		} catch (error) {
			updateCurrentModal({ formError: error });
		}
	};

	const resolver = isCreate ? classValidatorResolver(CreateFamilyDto) : classValidatorResolver(UpdateFamilyDto);
	const handleOpenModal = useCallback(
		(family?: FamilyDto) => {
			openModal({
				formTitle: isCreate ? t('families.forms.create-family') : t('families.forms.edit-family'),
				resolver,
				defaultValues: family ? family : defaultValues,
				submitAction: handleSubmit,
				closeModal,
				submitButtonName,
			});
		},
		[openModal, handleSubmit]
	);

	return handleOpenModal;
}
