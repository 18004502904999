import React, { useCallback } from 'react';
import { SurveyTemplateDto, UpdateSurveyTemplateDto } from '@families-link/shared';
import { useSnackbar } from 'notistack';
import { useMuiModal } from '../../../_core/hooks';
import { useUpdateSurveyTemplateMutation } from '../../../../store/api/survey.api';
import { useGetDistrictListQuery } from '../../../../store/api/district.api';
import {
	AttachDistrictsToSurveyTemplateFormModalProps,
	AttachDistrictsToSurveyTemplateFormModal,
} from '../../components/modal-forms/attach-district-form.component';

export function useOpenModalAttachDistrictFormHook() {
	const { data: districtList } = useGetDistrictListQuery();

	const [updateSurveyTemplate] = useUpdateSurveyTemplateMutation();

	const { enqueueSnackbar } = useSnackbar();

	const { openModal, closeModal, updateCurrentModal } = useMuiModal<AttachDistrictsToSurveyTemplateFormModalProps>(
		AttachDistrictsToSurveyTemplateFormModal
	);

	const handleOpenModal = useCallback(
		(surveyTemplate: SurveyTemplateDto) => {
			const showToast = (
				message: string,
				variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined
			) => {
				enqueueSnackbar(message, {
					variant,
				});
			};

			const handleSubmit = async (values: UpdateSurveyTemplateDto): Promise<void> => {
				try {
					const updateSurveyTemplateData: UpdateSurveyTemplateDto = { ...values };
					await updateSurveyTemplate({ id: values._id, data: updateSurveyTemplateData }).unwrap();
					showToast('Districts list was successfully attached to survey template', 'success');

					closeModal();
				} catch (error) {
					updateCurrentModal({ formError: error });
					showToast('Districts attaching was failed', 'error');
				}
			};

			openModal({
				districtList,
				defaultValues: surveyTemplate,
				submitAction: handleSubmit,
				closeModal,
			});
		},
		[openModal, closeModal, updateSurveyTemplate, updateCurrentModal, enqueueSnackbar, districtList]
	);

	return handleOpenModal;
}
