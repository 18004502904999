import { Box, Paper, Typography } from '@mui/material';
import { FC } from 'react';

interface ForbiddenComponentProps {
	componentName: string;
}

export const ForbiddenComponent: FC<ForbiddenComponentProps> = ({ componentName }) => (
	<Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
		<Paper
			elevation={0}
			sx={{ flex: 1, pt: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
		>
			<Typography
				sx={{ fontSize: '16px', fontWeight: 'bold' }}
			>{`403 this ${componentName} forbidden for you`}</Typography>
		</Paper>
	</Box>
);
