import React, { FC } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { mergeSx } from 'merge-sx';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';

export type SaveDraftModalFormProps = CoreModalProps & {
    submitAction(): void;
    closeModal():void;
    closeParentModal():void; 
};

export const SaveDraftModalForm: FC<SaveDraftModalFormProps> = (props) => {
    const {
        submitAction,
        closeModal,
        closeParentModal,
        ...modalProps
    } = props;

    const {t} = useTranslation();
    const theme = useTheme();
    const smallSize = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <CoreModal
            {...modalProps}
            modalWidth={600}
            containerSx={[
                ({ breakpoints }) => ({
                    [breakpoints.down('sm')]: { pt: 3 },
                }),
            ]}
            bodySx={{ px: 4, pt: 0, pb: 4 }}
        >
            <>

               
                    <Typography
                        variant="h3"
                        sx={[
                            {
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                mb: 4,
                            },
                            ({ breakpoints }) => ({
                                [breakpoints.down('sm')]: { mb:  1 },
                            }),
                        ]}
                    >
                        {'There are unsaved changes'}
                    </Typography>
                
                
                    <Typography variant="body1" sx={{ textAlign: 'center', mb:4 }}>
                        {'If you close the form, all unsaved changes will be lost'}
                    </Typography>
               
                    <Box
                        sx={[
                            { display: 'flex', mt: 3 },
                            ({ breakpoints }) => ({
                                [breakpoints.down('sm')]: {
                                    mt: 2,
                                    flexDirection: 'column-reverse',
                                    alignItems: 'center',
                                },
                            }),
                        ]}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CoreButton
                            onClick={closeModal}
                            children={t('engagements.forms.buttons.cancel')}
                            variant='secondary'
                            sx={ {
                                fontSize: '14px',
                                    minWidth: 100,
                                    mr: !smallSize ? 1.5 : 0,
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex',  mb:  smallSize ? 2 :0, ml: 'auto' }}>
                            <CoreButton
                            onClick={()=>{closeModal(); submitAction();}}
                            children={t('engagements.forms.buttons.save-draft')}
                                sx={{
                                    fontSize: '14px',
                                    minWidth: 100,
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex',  mb: smallSize ? 2 : 0, }}>
                            <CoreButton
                            onClick={()=>{closeModal(); closeParentModal()}}
                            children={t('engagements.forms.buttons.confirm')}
                            contraModeStyleButton
                                sx={{
                                    fontSize: '14px',
                                    minWidth: 100,
                                    ml: !smallSize ? 2 : 0,
                                }}
                            />
                        </Box>
                    </Box>
            </>
        </CoreModal>
    );
};
