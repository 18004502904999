import { FC } from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import { Controller, FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { CoreButton, CoreIconButton } from '../../../_core/components/_ui/core-button';
import { ArrowDownIcon, ArrowUpIcon } from '../../../_core/constants/icons.constants';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { CoreSelect } from '../../../_core/components/_ui/core-select';
import { SurveyQuestionTypeArray, SurveyQuestionTypeEnum } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '../../../_core/components/icons';
import { QuestionOptionsArray } from './question-options-array.component';
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { uniqueId } from 'lodash';

export const SurveyTemplateQuestionsArray: FC = () => {
	const [t] = useTranslation();
	const {
		control,
		formState: { errors },
		watch,
	} = useFormContext();
	const { fields, append, remove, move } = useFieldArray({
		control,
		name: `questions`,
	});

	const onDragEnd = (result: DropResult) => {
		const { source, destination } = result;

		if (!destination) return;

		move(source.index, destination.index);
	};

	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="questions">
					{(provided) => (
						<Box {...provided.droppableProps} ref={provided.innerRef} sx={{ paddingTop: '16px', overflowY: 'auto' }}>
							{fields.map((field, index) => (
								<Draggable key={field.id} draggableId={field.id} index={index}>
									{(provided) => (
										<Box
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											key={field.id}
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'space-between',
												marginBottom: '16px',
												paddingBottom: '16px',
												borderBottom: '1px solid lightgrey',
											}}
										>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													alignItems: 'center',
													marginRight: '16px',
												}}
											>
												<CoreIconButton onClick={() => index > 0 && move(index, index - 1)} disabled={index === 0}>
													<ArrowUpIcon />
												</CoreIconButton>
												<CoreIconButton
													onClick={() => index < fields.length - 1 && move(index, index + 1)}
													disabled={index === fields.length - 1}
												>
													<ArrowDownIcon />
												</CoreIconButton>
											</Box>
											<Box sx={{ width: '100%' }}>
												<Box sx={{ display: 'flex', alignItems: 'center' }}>
													<Controller
														name={`questions.${index}.text`}
														control={control}
														render={({ field }) => (
															<CoreTextField
																{...field}
																label={t('survey-templates.forms.labels.question')}
																requiredMark
																fullWidth
																placeholder={t('survey-templates.forms.placeholders.enter-question')}
																error={!!(Array.isArray(errors['questions']) && errors['questions'][index]?.text)}
																helperText={
																	Array.isArray(errors['questions']) && errors['questions'][index]?.text
																		? (errors['questions'][index]?.text as FieldError).message
																		: undefined
																}
																controlSx={{ marginRight: '16px' }}
															/>
														)}
													/>

													<Controller
														name={`questions.${index}.type`}
														control={control}
														render={({ field }) => (
															<CoreSelect
																{...field}
																label={t('survey-templates.forms.labels.question-type')}
																requiredMark
																fullWidth
																placeholder={t('survey-templates.forms.placeholders.select-type')}
																value={watch(`questions.${index}.type`) || ''}
																error={!!(Array.isArray(errors['questions']) && errors['questions'][index]?.type)}
																helperText={
																	Array.isArray(errors['questions']) && errors['questions'][index]?.type
																		? (errors['questions'][index]?.type as FieldError).message
																		: undefined
																}
																controlSx={{ marginRight: '16px' }}
															>
																{SurveyQuestionTypeArray.map((value) => (
																	<MenuItem key={value} value={value}>
																		{value}
																	</MenuItem>
																))}
															</CoreSelect>
														)}
													/>

													<Controller
														name={`questions.${index}.isRequired`}
														control={control}
														render={({ field }) => (
															<CoreSelect
																{...field}
																onChange={(e) => {
																	const value = e.target.value === 'true';
																	field.onChange(value);
																}}
																label={t('survey-templates.forms.labels.type')}
																requiredMark
																error={!!(Array.isArray(errors['questions']) && errors['questions'][index]?.isRequired)}
																helperText={
																	Array.isArray(errors['questions']) && errors['questions'][index]?.isRequired
																		? (errors['questions'][index]?.isRequired as FieldError).message
																		: undefined
																}
																value={watch(`questions.${index}.isRequired`) ? 'true' : 'false'}
																fullWidth
															>
																<MenuItem value="true">{t('custom-fields.isRequired')}</MenuItem>
																<MenuItem value="false">{t('custom-fields.optional')}</MenuItem>
															</CoreSelect>
														)}
													/>
												</Box>
												<Box sx={{ marginTop: '16px' }}>
													{watch(`questions.${index}.type`) === SurveyQuestionTypeEnum.ShortText && (
														<CoreTextField
															label={t('survey-templates.forms.labels.answer-field')}
															fullWidth
															placeholder={t('survey-templates.forms.placeholders.enter-description')}
															disabled
														/>
													)}
													{watch(`questions.${index}.type`) === SurveyQuestionTypeEnum.LongText && (
														<CoreTextField
															label={t('survey-templates.forms.labels.answer-field')}
															fullWidth
															multiline
															placeholder={t('survey-templates.forms.placeholders.enter-description')}
															disabled
														/>
													)}
													{(watch(`questions.${index}.type`) === SurveyQuestionTypeEnum.Options ||
														watch(`questions.${index}.type`) === SurveyQuestionTypeEnum.MultiSelect) && (
														<>
															<Typography
																sx={{
																	fontSize: 14,
																	fontWeight: 300,
																	lineHeight: 1.2,
																	color: (theme) =>
																		errors['questions'] && (errors['questions'] as any)[index]?.options
																			? theme.colors.red
																			: theme.colors.gray,
																}}
															>
																{t('survey-templates.forms.labels.options')}
																<Box component="span" sx={{ color: (theme) => theme.colors.red, ml: 0.5 }}>
																	*
																</Box>
															</Typography>
															<QuestionOptionsArray questionIndex={index} />
														</>
													)}
												</Box>
											</Box>
											<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '16px' }}>
												<CoreIconButton
													onClick={() => {
														remove(index);
													}}
												>
													<DeleteIcon sx={{ fontSize: 35 }} color="info" />
												</CoreIconButton>
											</Box>
										</Box>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</Box>
					)}
				</Droppable>
			</DragDropContext>

			<CoreButton
				onClick={() => {
					append({ text: '', type: SurveyQuestionTypeEnum.ShortText, isRequired: true, id: uniqueId('') });
				}}
				variant="info-light"
				sx={{ fontSize: '16px' }}
			>
				<AddIcon />
				{t('survey-templates.forms.buttons.add-question')}
			</CoreButton>
		</>
	);
};
