import React, { FC } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { AppLogo } from '../../constants/icons.constants';
import { DefaultFieldTypeStatusEnum } from '@families-link/shared';
import { format } from 'date-fns';

interface SurveyHeaderProps {
	title: string;
	engagementType?: string;
	engagementDuration?: { startDate: Date; endDate: Date };
	engagementSchool?: string;
}

export const SurveyHeader: FC<SurveyHeaderProps> = (props) => {
	const { title, engagementType, engagementDuration, engagementSchool } = props;

	const formattedDuration = engagementDuration
		? engagementType === DefaultFieldTypeStatusEnum.Appointment
			? `${format(new Date(engagementDuration.startDate), 'MM.dd.yyyy')}`
			: `${format(new Date(engagementDuration.startDate), 'MM.dd.yyyy')}-${format(
					new Date(engagementDuration.endDate),
					'MM.dd.yyyy'
			  )}`
		: null;

	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'nowrap',
			}}
		>
			<Box sx={{ flex: 1 }}>
				<Paper
					sx={{
						height: 56,
						px: 2,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
					elevation={0}
				>
					<Box>
						<Typography variant="h5">{title}</Typography>
						{engagementDuration && engagementSchool ? (
							<Typography
								sx={{ color: (theme) => theme.colors.gray }}
							>{`Your appointment on ${formattedDuration} at ${engagementSchool}`}</Typography>
						) : null}
					</Box>
					<Box component={AppLogo} sx={{ width: 180, height: 40 }} />
				</Paper>
			</Box>
		</Box>
	);
};
