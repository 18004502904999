import React, { FC } from 'react';
import { FamiliesListPage } from './family-list.page';
import { getLocalStorageDistrictId } from '../../../_core/utils';
import { useSessionStorage } from '../../../../hooks';

export const FamiliesListDistrictLevelPage: FC = () => {
	const [sessionStorageDistrictId] = useSessionStorage('districtId');

	const districtId = sessionStorageDistrictId ?? getLocalStorageDistrictId();

	return <FamiliesListPage permission={{ create: true }} currentDistrictId={districtId} isAdminRoute={false} />;
};
