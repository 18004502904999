import { FC, useEffect } from 'react';

import { Paper, Box, Typography } from '@mui/material';

import { usePaginatedData, useSortingParams } from '../../../_core/hooks';
import { ProvidersDetails } from '../../components/providers-details/providers-details.component';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
	useDeleteEngagementMutation,
	useGenerateEngagementsCustomFieldFileUrlMutation,
	useGetEngagementsByProviderIdQuery,
	useUpdateEngagementMutation,
	useArchiveEngagementMutation,
} from '../../../../store/api/engagements.api';
import { EngagementDto } from '@families-link/shared';
import { EngagementsTable } from '../../../engagement/components/engagements-table';
import { setLocalStorageEngagementId, setLocalStorageProviderId } from '../../../_core/utils';
import { useTranslation } from 'react-i18next';
import { useGetAllFieldsQuery } from '../../../../store/api/customRequirements.api';
import { useGetAllProvidersByDistrictIdQuery, useGetProviderByIdQuery } from '../../../../store/api/providers.api';
import {
	useGetAllFamiliesByDistrictIdQuery,
	useGetChildrenByDistrictIdQuery,
} from '../../../../store/api/families.api';
import { useOpenModalForm } from '../../../engagement/hooks/modal-forms/use-open-modal-form.hook';
import { useOpenConfirmModal } from '../../../engagement/hooks/modal-forms/use-open-confirm-modal-form.hook';
import { DeleteIcon } from '../../../_core/components/icons';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { DistrictRoutes } from '../../../district/constants';
import { useSessionStorage } from '../../../../hooks';
import { skipToken } from '@reduxjs/toolkit/query';
import { NotFoundComponent, ForbiddenComponent } from '../../../_core/components/exceptions';
import { ArchiveRedIcon } from '../../../_core/constants/icons.constants';

export const ProvidersDetailsPage: FC = () => {
	const { providerId } = useParams();
	setLocalStorageProviderId(providerId ?? '');
	const { data: currentProvider, isError, error } = useGetProviderByIdQuery(providerId ?? skipToken);

	const [sessionStorageDistrictId, setSessionStorageDistrictId] = useSessionStorage('districtId');

	useEffect(() => {
		if (currentProvider?.districtId) {
			setSessionStorageDistrictId(currentProvider?.districtId);
		}
	}, [currentProvider?.districtId, setSessionStorageDistrictId]);

	const navigate = useNavigate();

	const [t] = useTranslation();
	const { data: fieldsListFromDistrict } = useGetAllFieldsQuery(
		{ districtId: currentProvider?.districtId },
		{ skip: !currentProvider?.districtId }
	);
	const { data: providersList } = useGetAllProvidersByDistrictIdQuery(
		{ districtId: currentProvider?.districtId },
		{ skip: !currentProvider?.districtId }
	);
	const { data: familiesList } = useGetAllFamiliesByDistrictIdQuery(
		{ district: currentProvider?.districtId },
		{ skip: !currentProvider?.districtId }
	);
	const { data: childrenList } = useGetChildrenByDistrictIdQuery(
		{ districtId: currentProvider?.districtId },
		{ skip: !currentProvider?.districtId }
	);

	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const { requestObject, meta, page, handleResetPage, itemsList, fetchMore } = usePaginatedData<EngagementDto>(
		useGetEngagementsByProviderIdQuery,
		{ providerId, ...sortingParams },
		{ skip: !providerId, refetchOnMountOrArgChange: true }
	);
	const { refetch, isFetching } = requestObject;

	useEffect(() => {
		handleResetPage();
	}, [sortingArray, handleResetPage]);

	const handlePagination = () => {
		handleResetPage();

		if (page === 1) {
			refetch();
		}
	};

	const [generateEngagementsCustomFieldFileUrl] = useGenerateEngagementsCustomFieldFileUrlMutation();

	const [updateEngagement] = useUpdateEngagementMutation();
	const featchActionEdit = async (data: any) => {
		await updateEngagement({ id: data?._id?.toString(), data }).unwrap();
	};
	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		handlePagination,
		isCreate: false,
		districtId: currentProvider?.districtId,
		submitButtonName: t('engagements.forms.buttons.save'),
		toastText: 'Engagement record was successfully updated',
		fieldsListFromDistrict,
		providersList,
		familiesList,
		childrenList,
		generateFileUrlFunction: generateEngagementsCustomFieldFileUrl,
	});

	const handleOnView = (engagement: EngagementDto) => {
		setLocalStorageEngagementId(engagement._id);
		navigate(generatePath(DistrictRoutes.Engagements.Details, { engagementId: engagement._id }));
	};

	const [deleteEngagement] = useDeleteEngagementMutation();
	const featchActionDelete = async (data: EngagementDto) => {
		await deleteEngagement({ id: data._id }).unwrap();
	};
	const handleOpenDeleteConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDelete,
		handlePagination,
		title: t('engagements.forms.delete-title'),
		description: t('engagements.forms.delete-description'),
		icon: <DeleteIcon sx={{ fontSize: 40 }} />,
		danger: true,
		submitButtonName: t('engagements.forms.buttons.delete'),
		toastText: 'Engagement record was successfully deleted',
	});

	const [archiveEngagement] = useArchiveEngagementMutation();

	const fetchActionArchive = async (data: EngagementDto) => {
		await archiveEngagement({ id: data._id }).unwrap();
	};
	const handleArchiveConfirmModal = useOpenConfirmModal({
		featchAction: fetchActionArchive,
		handlePagination,
		title: t('engagements.forms.archive-title'),
		description: t('engagements.forms.archive-description'),
		icon: <ArchiveRedIcon />,
		danger: true,
		submitButtonName: t('engagements.forms.buttons.archive'),
		toastText: 'Engagement record was successfully archived.',
	});

	if (isError) {
		switch ((error as any).data.statusCode) {
			case 404:
			case 400:
				return <NotFoundComponent componentName="provider" />;
			case 403:
				return <ForbiddenComponent componentName="provider" />;
			default:
				break;
		}
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' /*, height: '32%' */ }}>
				<Paper elevation={0} sx={{ width: '75%', mr: 1 }}>
					<ProvidersDetails providerId={providerId} />
				</Paper>
				<Paper elevation={0} sx={{ width: '25%', p: 3 }}>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
						<Box>
							<PeopleAltIcon sx={{ fontSize: '32px' }} color="primary" />
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
							<Typography
								sx={{
									color: (theme) => theme.colors.blue,
									fontStyle: 'normal',
									fontSize: '56px',
									fontWeight: 500,
									lineHeight: '100%',
								}}
							>
								{meta?.itemCount}
							</Typography>
						</Box>
						<Typography sx={{ fontWeight: 500, fontSize: '14px', color: (theme) => theme.colors.grayLightBlue }}>
							Total Engagements
						</Typography>
					</Box>
				</Paper>
			</Box>
			<Paper
				elevation={0}
				sx={{
					flex: 1,
					p: 3,
					display: 'flex',
					height: '70%',
					mt: 1,
					borderBottomRightRadius: 0,
					borderBottomLeftRadius: 0,
				}}
			>
				<EngagementsTable
					data={itemsList}
					loading={isFetching}
					hasMore={meta?.hasNextPage}
					onDelete={handleOpenDeleteConfirmModal}
					onEdit={handleOpenModalEdit}
					onArchive={handleArchiveConfirmModal}
					onView={handleOnView}
					sorting={sortingArray}
					onSortingChange={handleSortingChange}
					fetchMore={fetchMore}
					permission={{ isDetailsPage: true }}
				/>
			</Paper>
		</Box>
	);
};
