import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ServiceTypeStatusEnum, FieldDto } from '@families-link/shared';

import { CustomRequirementsTableHandlers } from '../components/custom-requirements-table';
import { CustomRequirementsTableActions } from '../components/custom-requirements-table/custom-table-actions.component';
import { Typography } from '@mui/material';
import { tableCellTypographyOverflowStyles } from '../../_core/styles';

const helper = createColumnHelper<FieldDto & { createdAt: string; updatedAt: string }>();

export function useFieldsTableColumns(handlers: CustomRequirementsTableHandlers): ColumnDef<FieldDto, string>[] {
	const { onDelete, onEdit } = handlers;
	const [t] = useTranslation();

	const result = useMemo(
		() => [
			helper.accessor('name', {
				header: t('custom-fields.name'),
				meta: {
					flex: 2,
					style: { fontWeight: 500 },
				},
				enableSorting: true,
			}) as ColumnDef<FieldDto, string>,
			helper.accessor('dataType', {
				header: t('custom-fields.data-type'),
				meta: {
					flex: 1,
				},
				enableSorting: true,
			}) as ColumnDef<FieldDto, string>,
			helper.accessor('isRequired', {
				header: t('custom-fields.required'),
				meta: {
					flex: 1,
				},
				enableSorting: true,
				cell: (info) => (info.getValue() ? t('custom-fields.isRequired') : t('custom-fields.optional')),
			}) as ColumnDef<FieldDto, string>,
			helper.accessor('attributeType', {
				header: t('custom-fields.attributeType'),
				meta: {
					flex: 1,
				},
				cell: (info) => {
					const value = info.getValue();
					return value === 'Default' ? 'Default' : 'Custom';
				},
			}) as ColumnDef<FieldDto, string>,
			helper.accessor('engagementType', {
				header: t('custom-fields.engagement-type'),
				meta: {
					flex: 1,
					useCustomOverflowContainer: true,
				},
				enableSorting: true,
				cell: (info) => {
					const value = info.getValue();
					switch (value) {
						case ServiceTypeStatusEnum.Appointment:
							return (
								<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
									{t('custom-fields.appointment')}
								</Typography>
							);
						case ServiceTypeStatusEnum.Project:
							return (
								<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
									{t('custom-fields.project')}
								</Typography>
							);
						case ServiceTypeStatusEnum.Both:
							return (
								<Typography data-overflow="true" sx={tableCellTypographyOverflowStyles}>
									{t('custom-fields.appointment')}, {t('custom-fields.project')}
								</Typography>
							);
						default:
							return null;
					}
				},
			}) as ColumnDef<FieldDto, string>,
			helper.display({
				id: 'actions',
				cell: (props) => (
					<CustomRequirementsTableActions field={props.row.original} onDelete={onDelete} onEdit={onEdit} />
				),
			}) as ColumnDef<FieldDto>,
		],
		[t, onDelete, onEdit]
	);

	return result;
}
