import {
	ValidatorConstraint,
	ValidatorConstraintInterface,
	ValidationArguments,
	registerDecorator,
	ValidationOptions,
} from 'class-validator';
import { SendMethodArray, SendMethodEnum } from '../enums';

@ValidatorConstraint({ name: 'IsValidSendMethod', async: false })
export class IsValidSendMethodConstraint implements ValidatorConstraintInterface {
	validate(value: any, args: ValidationArguments) {
		const obj: any = args.object;

		if (!obj.email) {
			return value !== SendMethodEnum.Email;
		}

		return SendMethodArray.includes(value);
	}

	defaultMessage(args: ValidationArguments) {
		const obj: any = args.object;

		if (!obj.email) {
			return `Send method can't use Email if email is empty.`;
		}

		return 'Send method should be Email or Sms';
	}
}

export function IsValidSendMethod(validationOptions?: ValidationOptions) {
	return (object: any, propertyName: string) => {
		registerDecorator({
			name: 'IsValidSendMethod',
			target: object.constructor,
			propertyName,
			options: validationOptions,
			validator: IsValidSendMethodConstraint,
		});
	};
}
