import {
	CustomFieldDto,
	DefaultFieldTypeStatusEnum,
	FieldDataTypeEnum,
	FieldOptionInterface,
} from '@families-link/shared';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { Box, Checkbox, MenuItem } from '@mui/material';
import { CoreSelect } from '../../../_core/components/_ui/core-select';
import { FC } from 'react';
import { containerFormStyles, inputBoxStyles } from './modal-form.styles';
import { CoreTimeField } from '../../../_core/components/_ui/core-time-field';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { DynamicFileField } from '../dynamic-file-field/dynamic-file-field.compnent';
import { CoreDatePicker } from '../../../_core/components/_ui/core-date-picker';
import { useTranslation } from 'react-i18next';
import CoreCalendar from '../../../_core/components/_ui/core-date-picker/core-calendar/core-calendar.component';
import { mergeSx } from 'merge-sx';

export interface InputComponentByTypeInterface {
	typeInForm: string;
	customField?: CustomFieldDto;
	label?: string;
	statusEnum?: string[];
	disabled?: boolean;
}

const validateField = (value: any, field?: CustomFieldDto) => {
	if (field?.isRequired && !value) {
		return `${field?.name} is required`;
	}

	return true;
};

export const InputComponentByType: FC<InputComponentByTypeInterface> = ({
	typeInForm,
	customField,
	label,
	statusEnum,
	disabled = false,
}) => {
	const [t] = useTranslation();
	const {
		control,
		register,
		formState: { errors },
	} = useFormContext();
	const inputName = `field_${customField?._id}`;

	if (!customField) {
		return null;
	}

	switch (customField.dataType) {
		case FieldDataTypeEnum.SystemStatus:
			return (
				<Controller
					key={inputName}
					name={inputName}
					control={control}
					rules={{
						validate: (value) => validateField(value, customField),
					}}
					render={({ field }) => (
						<CoreSelect
							{...field}
							label={label || customField?.name || ''}
							requiredMark={customField?.isRequired}
							fullWidth
							placeholder={t('engagements.forms.placeholders.options') ?? ''}
							error={!!errors[inputName]}
							helperText={(errors[inputName] as FieldError)?.message}
							controlSx={inputBoxStyles}
						>
							{statusEnum?.map((el: any) => (
								<MenuItem key={el} value={el}>
									{el}
								</MenuItem>
							))}
						</CoreSelect>
					)}
				/>
			);
		case FieldDataTypeEnum.Options:
			return (
				<Controller
					key={inputName}
					name={inputName}
					control={control}
					rules={{
						validate: (value) => disabled || validateField(value, customField),
					}}
					render={({ field }) => (
						<CoreSelect
							{...field}
							{...register(inputName)}
							label={label || customField?.name || ''}
							requiredMark={customField?.isRequired && !disabled}
							fullWidth
							disabled={disabled}
							placeholder={t('engagements.forms.placeholders.options') ?? ''}
							error={!!errors[inputName]}
							helperText={(errors[inputName] as FieldError)?.message}
							controlSx={inputBoxStyles}
						>
							{customField?.options?.map((el: any) => (
								<MenuItem key={el._id} value={el._id}>
									{el.text}
								</MenuItem>
							))}
						</CoreSelect>
					)}
				/>
			);
		case FieldDataTypeEnum.MultiSelect:
			return (
				<Controller
					key={inputName}
					name={inputName}
					control={control}
					rules={{
						validate: (value) => validateField(value, customField),
					}}
					render={({ field }) => (
						<CoreSelect
							{...field}
							label={customField?.name || ''}
							requiredMark={customField?.isRequired}
							placeholder={t('engagements.forms.placeholders.options') ?? ''}
							fullWidth
							SelectProps={{
								multiple: true,
								value: field.value || [],
								renderValue: (selected: any) => {
									if (!selected || selected.length === 0) {
										return <span style={{ color: '#999' }}>{t('engagements.forms.placeholders.options')}</span>;
									}

									return selected
										.map(
											(selectedId: string) =>
												customField?.options?.find((opt: FieldOptionInterface) => opt._id === selectedId)?.text
										)
										.join(', ');
								},
							}}
							error={!!errors[inputName]}
							helperText={(errors[inputName] as any)?.message}
						>
							{customField?.options?.map((option: FieldOptionInterface) => (
								<MenuItem key={option._id} value={option._id}>
									<Checkbox checked={field?.value?.includes(option._id)} />
									{option.text}
								</MenuItem>
							))}
						</CoreSelect>
					)}
				/>
			);
		case FieldDataTypeEnum.DateDuration:
			if (typeInForm === DefaultFieldTypeStatusEnum.Appointment) {
				return (
					<Box sx={containerFormStyles}>
						<Controller
							key={'day'}
							name={`day`}
							control={control}
							rules={{
								validate: (value) => {
									if (!value) return 'Day is required';
									const isValidDate = !isNaN(new Date(value).getTime());

									return isValidDate || 'Please select a valid date';
								},
							}}
							render={({ field }) => (
								<CoreDatePicker
									{...field}
									label={'Day'}
									requiredMark={true}
									fullWidth
									error={!!errors['day']}
									helperText={errors['day']?.message}
									controlSx={inputBoxStyles}
								/>
							)}
						/>
						<Controller
							key={'time'}
							name={`time`}
							control={control}
							rules={{
								validate: (value) => {
									if (!value) return 'Time is required';
									const isValidDate = !isNaN(new Date(value).getTime());

									return isValidDate || 'Please select a valid time';
								},
							}}
							render={({ field }) => (
								<CoreTimeField
									{...field}
									label={'Time'}
									requiredMark={true}
									fullWidth
									error={!!errors['time']}
									helperText={(errors['time'] as FieldError)?.message}
									controlSx={inputBoxStyles}
								/>
							)}
						/>
					</Box>
				);
			} else {
				return (
					<Controller
						key={inputName}
						name={inputName}
						control={control}
						rules={{
							validate: (value) => validateField(value[0]&&value[1], customField),
						}}
						render={({ field }) => (
								<CoreCalendar
									{...field}
									selectsRange
									startDate={field.value[0] ? new Date(field.value[0]) : null}
									endDate={field.value[1] ? new Date(field.value[1]) : null}
									onClear={() => {
										field.onChange([null, null]);
									}}
									onKeyDown={(e) => {
										e.preventDefault();
									}}
									inputProps={{
										label: label || customField?.name || '',
										requiredMark: customField?.isRequired,
										fullWidth: true,
										error: !!errors[inputName],
										helperText: (errors[inputName] as FieldError)?.message,
										controlSx: { ...inputBoxStyles, margin: 0 },
										id: `calendar-input-${inputName}`,
										sx: {
											borderColor: errors[inputName] ? 'red' : undefined,
											margin: 0,
										},
									}}
								/>
						)}
					/>
				);
			}

		case FieldDataTypeEnum.LongText:
			return (
				<CoreTextField
					key={inputName}
					{...register(inputName, {
						required: customField?.isRequired ? `${label || customField?.name || ''} is required` : false,
					})}
					label={label || customField?.name || ''}
					requiredMark={customField?.isRequired}
					fullWidth
					multiline
					placeholder={t('engagements.forms.placeholders.text') ?? ''}
					error={!!errors[inputName]}
					helperText={(errors[inputName] as FieldError)?.message}
					controlSx={inputBoxStyles}
				/>
			);
		case FieldDataTypeEnum.File:
			return (
				<DynamicFileField
					customField={customField}
					controlSx={mergeSx(inputBoxStyles, {
						'& .MuiFormHelperText-root': { bottom: '-16px' },
						marginBottom: '24px',
					})}
				/>
			);

		default:
			return (
				<CoreTextField
					key={inputName}
					{...register(inputName, {
						required: customField?.isRequired ? `${label || customField?.name || ''} is required` : false,
					})}
					label={label || customField?.name || ''}
					requiredMark={customField?.isRequired}
					fullWidth
					placeholder={t('engagements.forms.placeholders.text') ?? ''}
					error={!!errors[inputName]}
					helperText={(errors[inputName] as FieldError)?.message}
					controlSx={inputBoxStyles}
				/>
			);
	}
};
