import { FC, useState } from 'react';
import { getFileType } from '../../../_core/utils';
import {
	useLazyDownloadFileBlobQuery,
} from '../../../../store/api/engagements.api';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Container, IconButton, Typography } from '@mui/material';
import {
	DefaultFieldTypeStatusEnum,
	EmailStatusColorEnum,
	EmailStatusEnum,
	FieldDataTypeEnum,
	EngagementStatusColorEnum,
	EngagementDto,
} from '@families-link/shared';
import { format } from 'date-fns';
import { DownloadIcon } from '../../../_core/constants/icons.constants';
import { FileIcon } from '../../../_core/components/_ui/core-file';
import { FillEditIcon } from '../../../_core/components/icons';
import dayjs from 'dayjs';
import { FadeSpinner } from '../../../_core/components/_ui/fade-spinner';

interface EngagementDetailsProps {
	engagement?: EngagementDto;
	handleEditEngagement(): void;
}

export const EngagementDetails: FC<EngagementDetailsProps> = ({ engagement, handleEditEngagement }) => {
	const [loading, setLoading] = useState<string | null>(null);
	const [triggerDownloadFileBlob] = useLazyDownloadFileBlobQuery();

	const [t] = useTranslation();

	const renderFileLinks = (value: any) => {
		if (value && typeof value === 'object') {
			const fileName = value.name ?? 'download';
			const fileUrl = value.url;
			const Icon = FileIcon[getFileType(value.type)];

			const handleDownload = async () => {
				setLoading(fileUrl);

				const blob = await triggerDownloadFileBlob({ url: fileUrl }).unwrap();
				const link = document.createElement('a');
				const url = URL.createObjectURL(blob);
				link.href = url;
				link.download = fileName;
				link.click();
				URL.revokeObjectURL(url);
				setLoading(null);
			};

			return fileUrl ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
						paddingBottom: 1,
						my: 1,
						px: 2,
						borderBottomStyle: 'solid',
						borderBottomWidth: '1px',
						borderBottomColor: 'rgba(36, 57, 106, 0.16)',
					}}
				>
					<Box sx={{ display: 'flex' }}>
						<Box
							component={Icon}
							sx={{
								color: 'black',
								minWidth: 16,
								mr: 1,
							}}
						/>

						<Typography>{fileName}</Typography>
					</Box>
					<Box sx={{ display: 'flex', position: 'relative' }}>
						{loading === fileUrl && (
							<FadeSpinner
								size={17}
								height={2}
								width={2}
								borderRadius={1}
								color={'black'}
								sx={{ marginRight: '6px' }}
							/>
						)}

						<ButtonBase
							onClick={handleDownload}
							sx={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, opacity: 0 }}
						></ButtonBase>
						<DownloadIcon />
					</Box>
				</Box>
			) : (
				<Typography>{fileName}</Typography>
			);
		}

		return null;
	};

	return (
		<Container
			maxWidth={false}
			disableGutters
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					width: '100%',
				}}
			>
				<Box>
					<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
						{t('engagements.details.details')}
					</Typography>
				</Box>

				<Box>
					<IconButton color="primary" onClick={handleEditEngagement}>
						<FillEditIcon sx={{ fontSize: 32 }} />
					</IconButton>
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-start',
					width: '100%',
					color: (theme) => theme.colors.darkBlack,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						flex: '1 1 45%',
						gap: 2,
					}}
				>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.id')}
						</Typography>
						<Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							{engagement?.customId}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.school')}
						</Typography>
						<Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							{engagement?.customFieldsValues?.find((el) => el.fieldName === 'School')?.valuePlaceholder
								? engagement?.customFieldsValues?.find((el) => el.fieldName === 'School')?.valuePlaceholder
								: 'No information'}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.date')}
						</Typography>
						<Typography sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							{(() => {
								const fieldDurationValue = engagement?.customFieldsValues?.find(
									(el) => el.fieldName === 'Duration'
								)?.value;
								const fieldTypeValue = engagement?.customFieldsValues?.find((el) => el.fieldName === 'Type')?.value;

								if (fieldTypeValue === DefaultFieldTypeStatusEnum.Appointment && fieldDurationValue?.startDate) {
									const appointmentDuration = dayjs(fieldDurationValue.endDate).diff(
										fieldDurationValue.startDate,
										'millisecond'
									);

									return `${format(new Date(fieldDurationValue.startDate), 'MM.dd.yyyy hh:mmaaa')} ${
										appointmentDuration / 1000 / 60 / 60 >= 1 ? `${Math.floor(appointmentDuration / (1000 * 60 * 60))} hr ` : ''
									}${(appointmentDuration / 1000/ 60) % 60} min`;
								} else if (
									fieldTypeValue === DefaultFieldTypeStatusEnum.Project &&
									fieldDurationValue?.startDate &&
									fieldDurationValue?.endDate
								) {
									return `${format(new Date(fieldDurationValue.startDate), 'MM.dd.yyyy')} - ${format(
										new Date(fieldDurationValue.endDate),
										'MM.dd.yyyy'
									)}`;
								} else {
									return 'No information';
								}
							})()}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.email-status')}
						</Typography>
						<Typography sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								{(() => {
									const fieldEmailStatusValue = engagement?.customFieldsValues?.find(
										(el) => el.fieldName === 'Email status'
									)?.value as EmailStatusEnum;

									if (fieldEmailStatusValue) {
										return (
											<>
												<Box
													sx={{
														height: 10,
														width: 10,
														marginRight: 1,
														borderRadius: '50%',
														backgroundColor: EmailStatusColorEnum[fieldEmailStatusValue],
														flexShrink: 0,
													}}
												/>
												<Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.5 }}>
													{fieldEmailStatusValue}
												</Typography>
											</>
										);
									} else {
										return <Typography variant="body2">No information</Typography>;
									}
								})()}
							</Box>
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.service')}
						</Typography>
						<Typography sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							{engagement?.customFieldsValues?.find((el) => el.fieldName === 'Service')?.valuePlaceholder
								? engagement?.customFieldsValues?.find((el) => el.fieldName === 'Service')?.valuePlaceholder
								: 'No information'}
						</Typography>
					</Box>
				</Box>

				<Box
					sx={{
						display: 'flex',
						height: '100%',
						flexDirection: 'column',
						flex: '1 1 45%',
						gap: 2,
					}}
				>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.type')}
						</Typography>
						<Typography sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							{engagement?.customFieldsValues?.find((el) => el.fieldName === 'Type')?.valuePlaceholder
								? engagement?.customFieldsValues?.find((el) => el.fieldName === 'Type')?.valuePlaceholder
								: 'No information'}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.program')}
						</Typography>
						<Typography sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							{engagement?.customFieldsValues?.find((el) => el.fieldName === 'Program')?.valuePlaceholder
								? engagement?.customFieldsValues?.find((el) => el.fieldName === 'Program')?.valuePlaceholder
								: 'No information'}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.status')}
						</Typography>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box
								sx={{
									height: 10,
									width: 10,
									marginRight: 1,
									borderRadius: '12px',
									backgroundColor:
										EngagementStatusColorEnum[engagement?.status as keyof typeof EngagementStatusColorEnum] ||
										'defaultColor',
									flexShrink: 0,
								}}
							></Box>
							<Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.5 }}>
								{engagement?.status}
							</Typography>
						</Box>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.sms-status')}
						</Typography>

						<Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							Not sent
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.language')}
						</Typography>

						<Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							{engagement?.language ? engagement?.language : 'No information'}
						</Typography>
					</Box>
				</Box>
			</Box>

			<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 2 }}>
				<Box
					sx={{
						backgroundColor: '#F2F3F6',
						width: '100%',
						p: 2,
						borderRadius: '12px',
					}}
				>
					<Typography
						variant="body2"
						sx={{
							fontSize: '12px',
							color: (theme) => theme.colors.grayBlue,
						}}
					>
						{t('engagements.details.description')}
					</Typography>
					<Typography>
						{engagement?.customFieldsValues?.find((el) => el.fieldName === 'Description')?.value
							? engagement?.customFieldsValues?.find((el) => el.fieldName === 'Description')?.value
							: ''}
					</Typography>
				</Box>

				{engagement?.customFields
					?.filter((customField) => customField.dataType === FieldDataTypeEnum.File)
					?.map((customField) => {
						const customFieldValue = engagement?.customFieldsValues?.find(
							(el) => el.fieldId.toString() === customField._id.toString()
						);

						if (!customFieldValue?.value?.length) return null;

						return (
							<Box
								key={customField?._id}
								sx={{
									mt: 2,
									width: '100%',
								}}
							>
								<Typography
									variant="body2"
									sx={{
										fontSize: '12px',
										color: (theme) => theme.colors.grayBlue,
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										mb: 1,
									}}
								>
									{customField?.name}
								</Typography>
								<Box
									key={customField._id}
									sx={{
										display: 'flex',
										p: 2,
										width: '100%',
										borderRadius: '12px',
										border: '1px solid',
										borderColor: 'rgba(36, 57, 106, 0.16)',
									}}
								>
									<Box
										key={customField._id}
										sx={{
											width: '100%',
										}}
									>
										{customFieldValue.value.map((file: any) => (
											<Box
												key={file.fileId}
												sx={{
													width: '100%',
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												{renderFileLinks(file)}
											</Box>
										))}
									</Box>
								</Box>
							</Box>
						);
					})}
			</Box>
		</Container>
	);
};
