import {
	Length,
	IsEmail,
	IsEnum,
	IsNotEmpty,
	IsOptional,
	ValidationArguments,
	Matches,
	IsPhoneNumber,
	IsArray,
	ArrayNotEmpty,
	ValidateNested,
	ValidateIf,
} from 'class-validator';
import { LanguageEnum } from '../../enums';
import { Transform, Type } from 'class-transformer';
import { UpdateChildDto } from './update-child.dto';
import { CreateChildFromFamilyDto } from './create-child.dto';

export class ImportFamilyDto {
	@IsNotEmpty()
	districtId: string;

	@Length(1, 100)
	fullName: string;

	@IsOptional()
	@ValidateIf((obj) => !!obj.email)
	@IsEmail()
	email?: string;

	@IsPhoneNumber('US', { message: 'Phone number must be a valid phone number' })
	phoneNumber: string;

	@Length(1)
	location?: string;

	@IsEnum(LanguageEnum, {
		each: true,
		message: 'Preferred language is required or incorrect',
	})
	preferedLanguage: string[];

	@IsOptional()
	imageUrl?: string;

	@IsArray()
	@ArrayNotEmpty()
	@ValidateNested({ each: true })
	@Type(() => CreateChildFromFamilyDto)
	children: Array<CreateChildFromFamilyDto>;
}
