import React, { FC } from 'react';
import { EngagementsListPage } from './engagements-list.page';
import { useGetAllProvidersQuery } from '../../../../store/api/providers.api';
import {
	useGetAllFamiliesByDistrictIdQuery,
	useGetChildrenByDistrictIdQuery,
} from '../../../../store/api/families.api';
import { useGetAllFieldsQuery } from '../../../../store/api/customRequirements.api';

export const EngagementsListAdminLevelPage: FC = () => {
	const { data: providersList } = useGetAllProvidersQuery({});
	const { data: familiesList } = useGetAllFamiliesByDistrictIdQuery({});
	const { data: childrenList } = useGetChildrenByDistrictIdQuery({});
	const { data: fieldsList } = useGetAllFieldsQuery({});

	return (
		<EngagementsListPage
			permission={{ read: true }}
			isAdminRoute={true}
			providersList={providersList}
			familiesList={familiesList}
			childrenList={childrenList}
			fieldsList={fieldsList}
		/>
	);
};
