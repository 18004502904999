export enum ChartColors {
	Peachy = '#F89377',
	Lemon = '#F8F282',
	Turquoise = '#93E9B3',
	LightBlue = '#78BBE2',
	LightPurpule = '#A681C7',
	Blue = '#788FE2',
}

export const ChartColorsArray = [
	ChartColors.Peachy,
	ChartColors.Lemon,
	ChartColors.Turquoise,
	ChartColors.LightBlue,
	ChartColors.LightPurpule,
	ChartColors.Blue,
];
